<template>
    <div>
        <div class="max-w-lg p-3 lg:p-6">
            <h3 class="page-title">Thanks</h3>
            <p class="page-desc">
                Customise the message you want displayed on the last step of the form. If <strong>Redirect</strong> is configured this setting will not apply.
            </p>

            <form @submit.prevent="formData.post($route('form.settings.thanks.update', form))">
                <div class="mt-4">
                    <jet-label for="title" value="Title" />
                    <jet-input id="type" type="text" class="block w-full mt-1" v-model="formData.thanks.title" autofocus />
                    <jet-input-error :message="formData.errors['thanks.title']" class="mt-2" />
                </div>

                <div class="mt-4">
                    <jet-label for="body" value="Message" />
                    <jet-textarea id="body" class="block w-full mt-1" v-model="formData.thanks.body" rows="4" autofocus />
                    <jet-input-error :message="formData.errors['thanks.body']" class="mt-2" />
                </div>

                <div class="mt-4">
                    <jet-button class="mr-3" :class="{ 'opacity-25': formData.processing }" :disabled="formData.processing">Save</jet-button>
                    <jet-secondary-button @click.prevent="formData.reset()" type="button">Cancel</jet-secondary-button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import ShowFormLayout from '@/Layouts/ShowFormLayout.vue'

export default {
    layout: [AppLayout, ShowFormLayout],
}
</script>

<script setup>
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetTextarea from '@/Jetstream/Textarea.vue'
import JetInputError from '@/Jetstream/InputError.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import { useForm } from '@inertiajs/vue3'

const props = defineProps({
    form: Object,
})

const formData = useForm({
    _method: 'PUT',
    thanks: { ...props.form.thanks },
})
</script>

<style scoped></style>
