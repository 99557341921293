<template>
    <div class="h-screen overflow-y-auto no-scrollbar">
        <div class="lg:p-6 mt-[13px] w-full relative">
            <fun-bg />
            <div
                class="sticky left-0 z-10 flex items-center justify-center pt-4 pb-2 mt-4 mb-2 space-x-2 -translate-x-1 top-1"
            >
                <div
                    class="flex items-center px-3 py-2 mx-auto space-x-3 rounded-full"
                >
                    <publish-form-button />
                    <copy-form-link />
                </div>
            </div>
            <div class="form-container top-[180px] pb-[180px]">
                <div class="form-wrapper">
                    <form-builder
                        :form="formData"
                        @update="updateFormData"
                        ref="formBuilder"
                        :toggle-menu="toggleMenu"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import ShowFormLayout from '@/Layouts/ShowFormLayout.vue'

export default {
    name: 'Builder',
    layout: [AppLayout, ShowFormLayout],
}
</script>

<script setup>
import FormBuilder from '@/Components/FormBuilder.vue'
import FunBg from '@/Components/FunBg.vue'
import { reactive, ref } from 'vue'
import PublishFormButton from '@/Components/PublishFormButton.vue'
import CopyFormLink from '@/Components/CopyFormLink.vue'

const props = defineProps({
    form: {
        type: Object,
        required: true,
    },
})

const menu = ref(false)
const formData = reactive(JSON.parse(JSON.stringify(props.form)))

const toggleMenu = () => {
    return new Promise((resolve) => {
        menu.value = !menu.value
        resolve()
    })
}

const updateFormData = (data) => Object.assign(formData, data)
</script>
