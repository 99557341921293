<template>
  <draggable
      item-key="name"
      v-on:change="$emit('input',$event)"
      v-model="items"
      ghost-class="ghost"
      @start="dragging = true"
      @end="onEnd"
  >
    <template #item="{ element, index }">
      <div>
        <slot :element="element" :index="index" />
      </div>
    </template>
  </draggable>
</template>
<script>
import draggable from "vuedraggable";

export default {
  name: 'FormBuilderDraggable',
  emits: ['input', 'change'],
  components: {
    draggable,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      items: this.value,
      dragging: false,
    };
  },
  methods: {
    onEnd() {
      this.dragging = false;
      this.$emit('change', this.items);
    },
  },
  watch: {
    value: {
      handler(newVal) {
        this.items = newVal;
      },
      deep: true,
    },
    items: {
      handler(newVal) {
        this.$emit('input', newVal);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.ghost {
  padding-top:20px;
  margin-top: 20px;
  border-top: 2px solid #3b82f6;
}

</style>