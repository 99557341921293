<template>
    <div class="relative">
        <div class="p-2" ref="menu">
            <div
                v-for="(i, k) in allowedIntegrations"
                :key="k"
                class="flex items-center justify-between rounded hover:bg-gray-50"
                :class="{
                    'hover:bg-red-50': false,
                }"
                style="width: 250px"
            >
                <div class="flex items-center">
                    <img
                        :src="`/images/integrations/icons/${i.name}.svg`"
                        :alt="i.title + ' integration'"
                        class="object-cover w-12 h-12"
                    />
                    <div class="text-xs">{{ i.title }}</div>
                </div>
                <Link
                    :href="
                        $route('form.integrations.toggle', {
                            form: $page.props.meta.form_uuid,
                            integration: i.name,
                        })
                    "
                    as="button"
                    method="POST"
                    preserve-state
                    :data="{ name: i.name, title: i.title }"
                    v-if="!selected.includes(i.name)"
                    class="h-6 px-2 font-semibold text-blue-500 text-tiny"
                    >Enable</Link
                >
                <Link
                    :href="
                        $route('form.integrations.toggle', {
                            form: $page.props.meta.form_uuid,
                            integration: i.name,
                        })
                    "
                    as="button"
                    v-if="selected.includes(i.name)"
                    method="post"
                    type="button"
                    :data="{ name: i.name }"
                    class="h-6 px-2 font-semibold text-red-500 text-tiny"
                    >Disable</Link
                >
            </div>
        </div>
        <div class="relative flex items-center justify-between mt-10">
            <h4
                class="pl-3 tracking-widest uppercase border-l border-gray-500 text-tiny text-body"
            >
                Integrations
            </h4>
            <button
                class="w-6 h-5 bg-white rounded integrations-btn sick-shadow group"
                @click="menu = !menu"
            >
                <div
                    class="flex items-center justify-center w-5 h-4 mx-auto transition rounded group-hover:bg-blue-500"
                >
                    <icon name="plus" class="w-4 h-4 group-hover:text-white" />
                </div>
            </button>
        </div>

        <div class="pl-2 border-l border-gray-100">
            <Link
                v-for="(i, k) in $page.props.meta.integrations"
                :key="k"
                :href="`/forms/${this.$page.props.meta.form_uuid}/integrations/${i.name}`"
                class="flex items-center p-1 transition rounded-lg cursor-pointer text-md hover:text-blue-600"
            >
                <span class="text-sm text-body font-regular">{{
                    i.title
                }}</span>
            </Link>
        </div>
    </div>
</template>

<script>
import JetToggle from '@/Jetstream/Toggle.vue'
import Icon from '@/Components/Icon.vue'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import { Link } from '@inertiajs/vue3'

export default {
    components: {
        JetToggle,
        Icon,
        Link,
    },
    data() {
        return {
            menu: false,
            all: [
                {
                    name: 'slack',
                    title: 'Slack',
                    disabledFor: [],
                },
                {
                    name: 'google-sheets',
                    title: 'Google Sheets',
                    disabledFor: [],
                },
                // {
                //     name: 'google-calendar',
                //     title: 'Google Calendar',
                //     disabledFor: [],
                // },
            ],
        }
    },
    computed: {
        integrations() {
            return this.$page.props?.meta?.integrations
        },
        selected() {
            return this.$page.props?.meta?.integrations.map((i) => i.name)
        },
        allowedIntegrations() {
            if (this.$page.props.meta.form_type === 'ENDPOINT') {
                return this.all.filter(
                    (i) =>
                        i?.disabledFor && !i?.disabledFor.includes('endpoint')
                )
            }

            return this.all
        },
    },
    mounted() {
        tippy('.integrations-btn', {
            theme: 'light',
            interactive: true,
            trigger: 'click',
            content: this.$refs.menu,
            popperOptions: { strategy: 'fixed' },
            allowHTML: true,
            placement: 'right-end',
            maxWidth: 300,
        })
    },
}
</script>

<style></style>
