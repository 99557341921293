<template>
    <form>
        <div class="grid grid-cols-3 gap-4 p-4">
            <div v-for="(el, key) in selects" :key="key" class="flex flex-col">
                <label for="rowFields" class="fe-label">Field #{{ key + 1 }}</label>
                <div class="flex flex-col space-y-2">
                    <jet-select v-model="selectInputs[el]" class="text-xs py-1 !shadow-sm hover:shadow">
                        <template #options>
                            <option value="" disabled selected>Choose row</option>
                            <option v-for="row in fields" :key="row._id" :value="row._id">{{ row.label }}</option>
                        </template>
                    </jet-select>
                </div>
            </div>

            <button
                type="button"
                @click="addField"
                class="self-end px-4 py-1 text-xs font-semibold border border-dashed rounded-md border-blueGray-300 text-blueGray-600"
            >
                Add row
            </button>
        </div>
        <div class="p-4 px-5 text-right bg-white border-t">
            <button type="button" class="btn h-[28px] px-2 mr-2 rounded" @click="$emit('close')">Cancel</button>
            <button
                :disabled="!canSave"
                type="button"
                @click="save"
                class="h-[28px] px-2 rounded bg-blue-600 border-blue-600 text-xs leading-4 focus:outline-none border focus:ring focus:ring-blue-300 text-white disabled:opacity-50 disabled:cursor-not-allowed"
            >
                Save
            </button>
        </div>
    </form>
</template>
<script>
import { computed, reactive } from 'vue'
import JetSelect from '@/Jetstream/Select.vue'

export default {
    components: {
        JetSelect,
    },

    props: {
        currentStep: {
            type: Number,
            required: true,
        },
        fields: {
            type: Array,
            required: true,
        },
        layout: {
            type: Object,
            required: true,
        },
    },

    emits: ['close', 'save-layout', 'current-step'],

    setup(props, { emit }) {
        const selectInputs = reactive({ select1: null })
        const layout = props.layout

        const selects = computed(() => Object.keys(selectInputs))

        const addField = () => {
            let id = Object.keys(selectInputs).length + 1
            selectInputs['select' + id] = null
        }

        const canSave = computed(() => Object.values(selectInputs).filter(Boolean).length > 1)

        const save = async () => {
            let ids = Object.values(selectInputs)
            let row = {
                _id: 'row_' + Math.floor(Math.random() * 100), // to be able to delete it from UI / layout
                label: 'Row',
                name: 'row',
                children: [],
            }

            for (let id of ids) {
                row.children.push(props.fields.find((f) => f._id === id))
            }

            layout.children[props.currentStep].children = layout.children[props.currentStep].children.filter((f) => !ids.includes(f._id))
            row.children = row.children.filter(Boolean)

            if (row.children.length < 2) {
                alert('Please select 2 fields or more.')
                return
            }

            layout.children[props.currentStep].children.push(row)

            emit('saveLayout', layout.children)
            emit('close')
        }

        return {
            selectInputs,
            addField,
            selects,
            save,
            canSave,
        }
    },
}
</script>
