<template>
    <app-layout>
        <template #header>
            <h2 class="text-sm font-semibold leading-tight text-white"></h2>
        </template>

        <div>
            <div class="py-10 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div class="md:grid md:grid-cols-3 md:gap-6">
                    <div>
                        <h3 class="text-lg font-medium text-gray-900">Subscribe to {{ plan.name }}</h3>
                        <p class="mt-1 text-sm text-gray-600">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum, reprehenderit.</p>
                    </div>

                    <div class="flex items-center justify-between px-6 py-4 bg-white rounded shadow md:col-span-2">
                        <div>
                            <h3 class="font-bold leading-tight tracking-tight">{{ plan.name }}</h3>
                            <p class="text-sm">Month-to-month pricing. Simple.</p>
                        </div>

                        <div class="text-5xl leading-normal tracking-tighter text-black font-regular">${{ plan.price }}</div>
                    </div>
                </div>
                <jet-form-section @submitted="submit" class="mt-5">
                    <template #form>
                        <div class="col-span-10">
                            <jet-label>Full name</jet-label>
                            <jet-input type="text" v-model="checkoutForm.name" class="block w-full" />
                        </div>
                        <div class="col-span-10">
                            <jet-label>Card</jet-label>
                            <div
                                id="card-element"
                                class="p-3 bg-white border border-gray-300 rounded-md shadow focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                            ></div>
                        </div>
                        <div id="card-error"></div>
                    </template>
                    <template #actions>
                        <jet-button :class="{ 'opacity-25': checkoutForm.processing }" :disabled="checkoutForm.processing"> Pay ${{ plan.price }} </jet-button>
                        <jet-action-message :on="checkoutForm.recentlySuccessful" class="ml-3"> Done.</jet-action-message>
                    </template>
                </jet-form-section>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import JetSectionBorder from '@/Jetstream/SectionBorder.vue'
import JetSectionTitle from '@/Jetstream/SectionTitle.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetFormSection from '@/Jetstream/FormSection.vue'
import UpdateProfileInformationForm from '../UpdateProfileInformationForm.vue'
import JetActionMessage from '@/Jetstream/ActionMessage.vue'
import { useForm } from '@inertiajs/vue3'

export default {
    props: ['plan', 'stripeKey', 'intent'],

    components: {
        AppLayout,
        JetSectionBorder,
        UpdateProfileInformationForm,
        JetSectionTitle,
        JetFormSection,
        JetActionMessage,
        JetButton,
        JetLabel,
        JetInput,
    },

    data() {
        return {
            stripe: '',
            elements: '',
            card: '',
            payment_method: null,

            checkoutForm: useForm({
                name: '',
                billing_price_id: this.plan.id,
            }),
        }
    },
    mounted() {
        this.includeStripe(
            'js.stripe.com/v3/',
            function () {
                this.configureStripe()
            }.bind(this)
        )
    },
    methods: {
        async submit() {
            await this.createToken()

            await this.checkoutForm
                .transform((data) => ({
                    ...data,
                    payment_method: this.payment_method,
                }))
                .post(route('checkout.process'))
        },
        includeStripe(URL, callback) {
            let documentTag = document,
                tag = 'script',
                object = documentTag.createElement(tag),
                scriptTag = documentTag.getElementsByTagName(tag)[0]
            object.src = '//' + URL
            if (callback) {
                object.addEventListener(
                    'load',
                    function (e) {
                        callback(null, e)
                    },
                    false
                )
            }
            scriptTag.parentNode.insertBefore(object, scriptTag)
        },
        configureStripe() {
            this.stripe = Stripe(this.stripeKey)

            this.elements = this.stripe.elements()
            this.card = this.elements.create('card')

            this.card.mount('#card-element')
        },

        async createToken() {
            try {
                const res = await this.stripe.confirmCardSetup(this.intent.client_secret, {
                    payment_method: {
                        card: this.card,
                        billing_details: { name: this.checkoutForm.name },
                    },
                })
                this.payment_method = res.setupIntent.payment_method
            } catch (err) {
                return
            }
        },
    },
}
</script>
