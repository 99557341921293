<template>
	<div class="p-3 lg:p-6">
		<div class="max-w-xl">
			<h3 class="page-title">Setup</h3>
			<p class="page-desc">
				Instructions on how to successfully and securely interact with
				the API.
			</p>
		</div>

		<div class="space-y-5 mt-10">
			<drawer default-open size="lg" title="Fundamentals">
				<div class="space-y-10">
					<p class="text-md prose">
						Pixelform requires certain parameters in order to
						understand and be able to process your request. Missing
						or incorrect parameters will result in an error
						response.
					</p>

					<div class="mt-5">
						<div class="flex items-start pt-10 gap-20">
							<div class="w-[100px]">
								<div class="em">_token</div>
							</div>
							<div
								class="border-b border-gray-100 pb-20 flex-1 max-w-md"
							>
								<p class="text-sm leading-6 prose">
									A unique string that belongs to your
									endpoint and identifies your requests. This
									must be provided with every request.
								</p>
							</div>
						</div>

						<div class="flex items-start pt-10 gap-20">
							<div class="w-[100px]">
								<div class="em">_honeypot</div>
							</div>
							<div
								class="border-b border-gray-100 pb-20 flex-1 max-w-md"
							>
								<p class="text-sm leading-6 prose">
									This parameter needs to provided as an empty
									string. If this field has a value other than
									an empty string, the entry will be marked as
									spam.
								</p>
							</div>
						</div>

						<div class="flex items-start pt-10 gap-20">
							<div class="w-[100px]">
								<div class="em">field_name</div>
							</div>
							<div
								class="border-b border-gray-100 pb-20 flex-1 max-w-md"
							>
								<p class="text-sm leading-6 prose">
									The
									<Link
										tooltip="View fields"
										:href="
											$route('fields.index', {
												form: formId,
											})
										"
										class="text-blue-500 underline-offset-2"
										>field names</Link
									>
									are case-sensitive. Ensure they are passed
									correctly.
								</p>
							</div>
						</div>
					</div>
				</div>
			</drawer>

			<drawer size="lg" title="Sending submission">
				<p class="text-md prose">
					You will make a
					<code>POST</code>
					request to the endpoint with all the parameters set
					correctly. Pixelform will process your request and return a
					response and status code.
				</p>

				<p class="text-md mt-2 prose">
					If your fields have rules set and validation fails, you will
					receive a status code of
					<code class="text-blue-500">422</code> and a list of errors.
				</p>

				<div class="mt-5">
					<div class="flex items-start pt-10 gap-20">
						<div class="w-[100px]">
							<div class="em">Content-Type</div>
						</div>
						<div class="border-b border-gray-100 pb-20 flex-1">
							<p class="text-sm leading-6 prose mb-2">
								Ensure the header is set to
								<code class="text-blue-600"
									>application/json</code
								>
								for all requests. If you're form works with file
								uploads, set the header to
								<code clas="text-blue-500"
									>multipart/form-data</code
								>.
							</p>
						</div>
					</div>

					<div class="flex items-start pt-10 gap-20">
						<div class="w-[100px]">
							<div class="em">Body</div>
						</div>
						<div class="border-b border-gray-100 pb-20 flex-1">
							<p class="text-sm leading-6 prose">
								Provide the form fields as key-value pairs in
								the request body.
							</p>
						</div>
					</div>
				</div>
			</drawer>

			<drawer size="lg" title="Handling the response">
				<p class="text-md">
					Pixelforprose return a status code upon client's request to
					the server. Status code indicates if the action was
					successful.
				</p>

				<div>201</div>
				<p class="text-md">
					The form entry was processed and stored successfully.
				</p>
				<p class="text-md">
					Pixelform server will return a status code upon client's
					request to the server. Status code indicates if the action
					was successful.
				</p>
				<ul class="-mx-4 mt-3 bg-white divide-y divide-gray-100">
					<li class="p-4">
						<div>
							<div
								class="bg-green-100 rounded py-1 px-2 text-md font-bold tracking-tight mr-1 text-green-500 inline-block mb-1"
							>
								201
							</div>
							<span class="text-md">Status code</span>
						</div>

						<p class="text-md">
							The form entry was processed and stored
							successfully.
						</p>
					</li>

					<li class="mt-2 p-4">
						<div>
							<div
								class="bg-red-100 rounded py-1 px-2 text-md font-bold tracking-tight mr-1 text-red-500 inline-block mb-1"
							>
								422
							</div>
							<span class="text-md">Status code</span>
						</div>

						<p class="text-md">
							Your request is invalid. Here's a list of the
							possible reasons.
						</p>
						<ul class="list-disc pl-8 text-md mt-2">
							<li>you didn't pass the form _token</li>
							<li>
								you passed the token but it's not the right one
							</li>
							<li>
								you didn't pass _honeypot as an empty string
							</li>
							<li>
								at least one form field failed the validation
							</li>
						</ul>
					</li>
				</ul>
			</drawer>
		</div>
	</div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import ShowFormLayout from '@/Layouts/ShowFormLayout.vue'
import Drawer from '@/Components/Drawer.vue'

export default {
	layout: [AppLayout, ShowFormLayout],
}
</script>

<script setup>
import { Link } from '@inertiajs/vue3'
import CodeEditor from '@/Components/SimpleCodeEditor/CodeEditor.vue'
const props = defineProps({
	form: Object,
})
const formId = props.form._id
</script>
