<template>
    <app-layout>
        <template #header>
            <h2 class="font-semibold text-sm text-white leading-tight er">Create Workspace</h2>
        </template>

        <div>
            <div class="max-w-7xl mx-auto py-10 sm:px-6 lg:px-8">
                <create-team-form />
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import CreateTeamForm from './CreateTeamForm.vue'

export default {
    components: {
        AppLayout,
        CreateTeamForm,
    },
}
</script>
