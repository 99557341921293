<template>
    <div v-if="links.length > 3">
        <div class="flex flex-wrap -mb-1">
            <template v-for="(link, key) in links">
                <div
                    v-if="link.url === null"
                    :key="key"
                    class="px-2 py-2 mb-1 mr-1 text-sm leading-4 text-gray-400 bg-white rounded shadow"
                    v-html="link.label"
                />
                <Link
                    v-else
                    :key="link.url"
                    class="p-2 mb-1 mr-1 text-sm leading-4 bg-white rounded shadow hover:bg-white focus:border-indigo-500 focus:text-indigo-500"
                    :class="{ 'bg-gray-100': link.active }"
                    :href="link.url"
                    v-html="link.label"
                />
            </template>
        </div>
    </div>
</template>

<script>
import { Link } from '@inertiajs/vue3'

export default {
    props: {
        links: Array,
    },
    components: {
        Link,
    },
}
</script>
