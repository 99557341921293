<template>
    <dialog-modal :show="show" :max-width="maxWidth">
        <template #title>
            <h3 class="page-title">Create a new {{ formType }}</h3>
            <p class="page-desc">Let's get started by giving our {{ formType }} a name.</p>
        </template>
        <template #content>
            <form method="post" @submit.prevent="handleSubmit">
                <div class="mt-4">
                    <div class="pf-create-form-type">
                        <pf-radio v-model:checked="form.type" :field="formTypeOptions" />
                    </div>
                    <jet-input-error :message="form.errors.type" class="mt-2" />
                </div>

                <div class="mt-4 space-y-4">
                    <div class="flex-1">
                        <jet-label for="name" value="Name" />
                        <jet-input ref="formName" id="type" type="text" class="block w-full" v-model="form.name" autofocus />
                        <jet-input-error :message="form.errors.name" class="mt-2" />
                    </div>
                    <div class="flex-1">
                        <jet-label for="name" value="Timezone" />
                        <Multiselect
                            v-model="form.timezone"
                            mode="single"
                            :searchable="true"
                            :resolve-on-load="true"
                            :delay="0"
                            :min-chars="1"
                            :options="getTimezones"
                            :classes="{
                                container:
                                    'relative mx-auto w-full flex flex-1 items-center justify-end box-border border rounded-md cursor-pointer text-xs outline-none border-neutral-300 h-[30px]',
                                containerDisabled: 'cursor-default bg-gray-100',
                                containerOpenTop: 'rounded-t-lg',
                                singleLabel:
                                    'flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 pr-16 box-border rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
                                singleLabelText: 'overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full',
                                search: 'bg-transparent absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-xs font-sans pl-3.5 rtl:pl-0 rtl:pr-3.5',
                                wrapper: 'flex-1',

                                placeholder:
                                    'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-gray-400 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5 text-center',
                                caret: 'bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none rtl:mr-0 rtl:ml-3.5 hidden',
                                caretOpen: 'rotate-180 pointer-events-auto',
                                clear: 'pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80 rtl:pr-0 rtl:pl-3.5 hidden',
                                clearIcon: 'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
                                spinner:
                                    'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0 rtl:mr-0 rtl:ml-3.5',
                                infinite: 'flex items-center justify-center w-full',
                                infiniteSpinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 animate-spin flex-shrink-0 flex-grow-0 m-3.5',
                                dropdown:
                                    'max-h-60 absolute -left-px -right-px -bottom-1 transform translate-y-full border border-gray-300 -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-t-lg rounded-b-lg',
                                dropdownTop: '-translate-y-full top-px bottom-auto rounded-b-none rounded-t-lg',
                                dropdownHidden: 'hidden',
                                options: 'flex flex-col p-0 m-0 list-none',
                                optionsTop: '',
                                group: 'p-0 m-0',
                                groupLabel:
                                    'flex text-sm box-border items-center justify-start text-left py-1 px-3 font-semibold bg-gray-200 cursor-default leading-normal',
                                groupLabelPointable: 'cursor-pointer',
                                groupLabelPointed: 'bg-gray-300 text-gray-700',
                                groupLabelSelected: 'bg-green-600 text-white',
                                groupLabelDisabled: 'bg-gray-100 text-gray-300 cursor-not-allowed',
                                groupLabelSelectedPointed: 'bg-green-600 text-white opacity-90',
                                groupLabelSelectedDisabled: 'text-green-100 bg-green-600 bg-opacity-50 cursor-not-allowed',
                                groupOptions: 'p-0 m-0',
                                option: 'flex items-center justify-start box-border text-left cursor-pointer text-sm leading-snug py-1 px-3',
                                optionPointed: 'text-gray-800 bg-gray-100',
                                optionSelected: 'text-white bg-green-500',
                                optionDisabled: 'text-gray-300 cursor-not-allowed',
                                optionSelectedPointed: 'text-white bg-green-500 opacity-90',
                                optionSelectedDisabled: 'text-green-100 bg-green-500 bg-opacity-50 cursor-not-allowed',
                                noOptions: 'py-2 px-3 text-gray-600 bg-white text-left',
                                noResults: 'py-2 px-3 text-gray-600 bg-white text-left',
                                fakeInput:
                                    'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
                                spacer: 'h-9 py-px box-content',
                                assist: 'hidden',
                            }"
                        />
                        <jet-input-error :message="form.errors.timezone" class="mt-2" />
                    </div>
                </div>

                <div class="mt-4 space-y-4">
                    <jet-button class="py-3 w-full justify-center">Create</jet-button>

                    <jet-secondary-button
                        class="py-3! w-full justify-center"
                        :class="{ 'opacity-25': form.processing }"
                        :disabled="form.processing"
                        @click.prevent="$emit('close')"
                        type="button"
                        >Cancel
                    </jet-secondary-button>
                </div>
            </form>
        </template>
    </dialog-modal>
</template>

<script>
import Modal from '@/Jetstream/Modal.vue'
import DialogModal from '@/Jetstream/DialogModal.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetInputError from '@/Jetstream/InputError.vue'
import JetButton from '@/Jetstream/Button.vue'
import pfRadio from '@/Pixelform/Radio.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import JetSelect from '@/Jetstream/Select.vue'
import { useForm } from '@inertiajs/vue3'
import Multiselect from '@vueform/multiselect'
import axios from 'axios'

export default {
    name: 'CreateFormModal',
    components: { JetLabel, JetInput, pfRadio, JetInputError, JetButton, JetSelect, JetSecondaryButton, DialogModal, Modal, Multiselect },
    props: ['show', 'maxWidth', 'closeable', 'formType'],
    data() {
        return {
            form: useForm({
                name: '',
                ...(this.formType === 'form' && { multistep: false }),
                type: this.formType.toUpperCase(),
                timezone: 'Europe/London',
            }),
        }
    },
    mounted() {
        this.$nextTick(() => {
            this?.$refs?.formName?.$el?.focus()
            this.$refs.formName.$el.value = ''
        })
    },
    computed: {
        multistepField() {
            return {
                options: [
                    {
                        title: 'Single step',
                        helpBlock: 'This will be perfect for short & sweet forms.',
                        value: false,
                    },
                    {
                        title: 'Multiple step',
                        helpBlock: 'This will allow you to break longer forms in multiple steps.',
                        value: true,
                    },
                ],
            }
        },

        formTypeOptions() {
            return {
                options: [
                    {
                        title: 'Ready to use form',
                        value: 'FORM',
                    },
                    {
                        title: 'API Endpoint',
                        value: 'ENDPOINT',
                    },
                ],
            }
        },
    },
    methods: {
        handleSubmit() {
            this.form.post(route('forms.store'), {
                preserveState: true,
                errorBag: 'createForm',
            })
        },
        async getTimezones(query) {
            const res = await axios.get(route('timezones.index', { query }))

            return res.data
        },
    },
}
</script>
