<template>
    <jet-button @click="connect" class="mt-3" :class="{ 'opacity-25 cursor-not-allowed': isLoading }" :disabled="isLoading"> Connect account </jet-button>
</template>
<script setup>
import { ref } from 'vue'
import JetButton from '@/Jetstream/Button.vue'
import { usePage } from '@inertiajs/vue3'

const isLoading = ref(false)
const teamId = usePage().props?.team?.id

const connect = async () => {
    isLoading.value = true
    try {
        const res = await axios.post(
            route('connect-accounts.create', {
                team: teamId,
            })
        )
        window.location = res.data.url
    } catch (err) {
        console.log(err)
    }
}
</script>
