<template>
    <app-layout>
        <template #header>
            <h2 class="text-sm font-semibold leading-tight text-white">Payment method</h2>
        </template>
        <div class="py-10 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <jet-form-section @submitted="createPaymentMethod">
                <template #title> Add payment method </template>

                <template #description> Pixelform does not store your credit card. Stripe is safely handling that for us. </template>

                <template #form>
                    <div class="col-span-6 sm:col-span-4">
                        <div class="col-span-10">
                            <jet-label for="name" value="Full name" />
                            <jet-input type="text" id="name" v-model="paymentMethodForm.name" class="block w-full" />
                            <jet-input-error :message="paymentMethodForm.errors.name" class="mt-2" />
                        </div>
                        <div class="col-span-10 mt-4">
                            <jet-label for="card-element" value="Card number" />
                            <div
                                id="card-element"
                                class="p-3 bg-white border border-gray-300 rounded-md shadow focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                            ></div>
                        </div>
                        <div id="card-error"></div>
                    </div>
                    <div class="col-span-10">
                        <label class="flex items-center">
                            <jet-checkbox name="default" v-model:checked="paymentMethodForm.default" />
                            <span class="ml-2 text-sm text-gray-600">Mark it as my default payment method</span>
                        </label>
                    </div>
                </template>

                <template #actions>
                    <jet-button :class="{ 'opacity-25': paymentMethodForm.processing }" :disabled="paymentMethodForm.processing"> Add </jet-button>
                    <jet-action-message :on="paymentMethodForm.recentlySuccessful" class="ml-3"> Done.</jet-action-message>
                </template>
            </jet-form-section>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import JetSectionBorder from '@/Jetstream/SectionBorder.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetCheckbox from '@/Jetstream/Checkbox.vue'
import JetFormSection from '@/Jetstream/FormSection.vue'
import JetActionMessage from '@/Jetstream/ActionMessage.vue'
import { useForm } from '@inertiajs/vue3'

export default {
    props: ['intent', 'stripeKey'],

    components: {
        AppLayout,
        JetSectionBorder,
        JetFormSection,
        JetActionMessage,
        JetButton,
        JetLabel,
        JetInput,
        JetCheckbox,
    },

    data() {
        return {
            stripe: '',
            elements: '',
            card: '',
            payment_method: null,

            paymentMethodForm: useForm({
                name: '',
                default: true,
            }),
        }
    },
    mounted() {
        this.includeStripe(
            'js.stripe.com/v3/',
            function () {
                this.configureStripe()
            }.bind(this)
        )
    },
    methods: {
        async createPaymentMethod() {
            await this.createToken()

            await this.paymentMethodForm
                .transform((data) => ({
                    ...data,
                    payment_method: this.payment_method,
                }))
                .post(route('payment-method.store'))
        },
        includeStripe(URL, callback) {
            let documentTag = document,
                tag = 'script',
                object = documentTag.createElement(tag),
                scriptTag = documentTag.getElementsByTagName(tag)[0]
            object.src = '//' + URL
            if (callback) {
                object.addEventListener(
                    'load',
                    function (e) {
                        callback(null, e)
                    },
                    false
                )
            }
            scriptTag.parentNode.insertBefore(object, scriptTag)
        },
        configureStripe() {
            this.stripe = Stripe(this.stripeKey)

            this.elements = this.stripe.elements()
            this.card = this.elements.create('card')

            this.card.mount('#card-element')
        },

        async createToken() {
            try {
                const res = await this.stripe.confirmCardSetup(this.intent.client_secret, {
                    payment_method: {
                        card: this.card,
                        billing_details: { name: this.paymentMethodForm.name },
                    },
                })
                this.payment_method = res.setupIntent.payment_method
            } catch (err) {
                return
            }
        },
    },
}
</script>
