<template>
    <textarea
        class="border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md text-xs"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        ref="input"
    ></textarea>
</template>

<script>
export default {
    props: ['modelValue'],

    emits: ['update:modelValue'],
}
</script>
