<template>
    <p class="text-xs text-gray-600 mt-1 mb-2">
        <slot />
    </p>
</template>

<script>
    export default {
        name: 'FieldInfo',
    }
</script>