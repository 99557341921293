<template>
    <div class="p-6">
        <div class="max-w-lg mb-6">
            <h3 class="text-3xl font-black leading-tight tracking-tight">Create new layout</h3>
            <p class="text-gray-700">Exciting times. Let's make this form layout amazing.</p>
        </div>

        <jet-form-section :detailed="false" @submitted="layoutForm.post(route('layouts.store', form._id))" class="pb-32">
            <template #form>
                <!-- Field name -->
                <div class="col-span-6 sm:col-span-4">
                    <jet-label for="name" value="Layout Name" />
                    <jet-input id="name" type="text" class="block w-full" placeholder="The best layout" v-model="layoutForm.name" autofocus />
                    <jet-input-error :message="layoutForm.errors.name" class="mt-1" />
                </div>
                <!-- Field type-->
                <div class="col-span-6 sm:col-span-4">
                    <jet-label for="type" value="Layout Type" />
                    <jet-select id="type" class="block w-full mt-1" v-model="layoutForm.type">
                        <template #options>
                            <option value="" disabled selected>Pick layout type</option>
                            <option :disabled="type.disabled" v-for="(type, k) in layoutTypes" :value="type.value" :key="k">
                                {{ type.label }}
                            </option>
                        </template>
                    </jet-select>
                    <jet-input-error :message="layoutForm.errors.type" class="mt-1" />
                </div>
            </template>

            <template #actions>
                <jet-button :class="{ 'opacity-25': layoutForm.processing }" :disabled="layoutForm.processing"> Create </jet-button>
            </template>
        </jet-form-section>
    </div>
</template>

<script>
import ShowFormLayout from '@/Layouts/ShowFormLayout.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetSelect from '@/Jetstream/Select.vue'
import DangerButton from '@/Jetstream/DangerButton.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetButtonSecondary from '@/Jetstream/SecondaryButton.vue'
import JetFormSection from '@/Jetstream/FormSection.vue'
import JetInputError from '@/Jetstream/InputError.vue'
import JetRemoveButton from '@/Jetstream/RemoveButton.vue'
import JetFieldInfo from '@/Jetstream/FieldInfo.vue'
import AppLayout from '@/Layouts/AppLayout.vue'
import { useForm } from '@inertiajs/vue3'

export default {
    layout: [AppLayout, ShowFormLayout],
    components: {
        JetFieldInfo,
        JetRemoveButton,
        JetFormSection,
        JetButtonSecondary,
        JetButton,
        DangerButton,
        JetSelect,
        JetInput,
        JetLabel,
        ShowFormLayout,
        JetInputError,
    },
    props: ['form'],
    data() {
        return {
            unsaved: false,
            layoutForm: useForm({
                name: '',
                type: 'SINGLE_STEP',
                selected: true,
            }),
            layoutTypes: [
                {
                    label: "Single step - It's short and sweet",
                    value: 'SINGLE_STEP',
                    disabled: false,
                },
                {
                    label: "Multistep - It's the only way to get through this",
                    value: 'MULTI_STEP',
                    disabled: true,
                },
            ],
        }
    },
}
</script>
