<template>
    <div class="flex flex-col">
        <table-meta :selected="selected" v-if="entries.data.length" />
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 no-scrollbar">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div class="overflow-hidden border border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-white">
                            <tr>
                                <th scope="col" class="w-10 py-2" v-if="entries.data.length">
                                    <jet-checkbox v-model="selectAll" @click="select" />
                                </th>
                                <th scope="col">&nbsp;</th>

                                <th
                                    scope="col"
                                    v-for="column in $page.props.form.table_columns"
                                    class="p-2 text-xs font-medium text-left text-gray-600 min-w-[120px]"
                                    :class="{
                                        'min-w-[120px]': column !== 'payment',
                                        'min-w-[70px]': column === 'payment',
                                    }"
                                    :key="column"
                                >
                                    <span class="px-2 py-1 bg-gray-100 rounded">{{
                                        $page.props.form?.columns.filter((f) => f.name === column)[0]?.label
                                    }}</span>
                                </th>

                                <th scope="col" class="p-2 text-xs font-medium text-left text-gray-600" v-if="entries.data.length">
                                    <span class="px-2 py-1 bg-gray-100 rounded">Created</span>
                                </th>

                                <th scope="col" class="relative px-6 py-2">
                                    <span class="sr-only"></span>
                                </th>
                            </tr>
                        </thead>
                        <tbody v-if="entries.data.length !== 0" class="bg-white divide-y divide-gray-100">
                            <tr v-for="(entry, key) in entries.data" :key="key" class="transition hover:bg-gray-50 group">
                                <td class="py-2 pl-3">
                                    <jet-checkbox v-model:checked="selected" :ref="'checkbox' + key" :value="entry['_id']" class="group-hover:shadow-sm" />
                                </td>
                                <td class="py-2 text-sm font-medium text-left whitespace-nowrap">
                                    <span @click="view(entry)" class="text-blue-600 cursor-pointer hover:text-blue-400">@json</span>
                                </td>
                                <td v-for="(column, k) in $page.props.form.table_columns" :key="k" class="px-2 py-2 text-sm whitespace-nowrap">
                                    <div class="flex items-center">
                                        <div
                                            v-if="entry.data[column] === 'redacted'"
                                            class="text-sm text-gray-500 line-through cursor-not-allowed"
                                            tooltip="Data retention was disabled for this entry"
                                        >
                                            Redacted
                                        </div>
                                        <div v-else-if="getFieldType(column) === 'payment'" class="flex items-center" :tooltip="entry?.pxlform_pis">
                                            <span
                                                class="py-0.5 px-1 rounded-md text-xs ml-1"
                                                :class="{
                                                    'text-green-600 bg-green-100': entry.pxlform_pis === 'succeeded',
                                                    'text-body bg-gray-100 ': entry.pxlform_pis !== 'succeeded',
                                                }"
                                                >{{ entry?.pxlform_currency_amount }}</span
                                            >
                                        </div>

                                        <date-column
                                            :timezone="$page.props.form.timezone"
                                            :value="entry.data[column]"
                                            :type="getFieldType(column)"
                                            v-else-if="isDateColumn(column)"
                                        />

                                        <div v-else-if="['text', 'textarea'].includes(getFieldType(column))">
                                            {{ truncateText(entry.data[column]) }}
                                        </div>
                                        <div v-else-if="getFieldType(column) === 'file' || getFieldType(column) === 'signature'">
                                            <a
                                                :href="
                                                    $route('entry-file.download', {
                                                        form: this.$page.props.form._id,
                                                        file: file.uuid,
                                                    })
                                                "
                                                class="text-blue-500 underline underline-offset-2"
                                                target="_blank"
                                                v-if="entry.data[column]"
                                                v-for="file in entry.data[column]"
                                                :key="file.uuid"
                                            >
                                                {{ file.name }}
                                            </a>
                                        </div>
                                        <div v-else-if="getFieldType(column) === 'checkbox'">
                                            <icon name="check-circle" class="w-5 h-5 text-green-500" v-if="entry.data[column]" />
                                            <icon name="x-circle" class="w-5 h-5 text-red-500" v-else />
                                        </div>
                                        <div v-else class="text-sm text-gray-500">
                                            {{ entry.data[column] }}
                                        </div>
                                    </div>
                                </td>
                                <td class="px-2 py-2 whitespace-nowrap">
                                    <div class="text-sm text-gray-500">
                                        {{ prettyDate(entry.created_at) }}
                                    </div>
                                </td>

                                <td class="px-2 py-2 text-sm font-medium text-right whitespace-nowrap">
                                    <a href="#" @click.prevent="confirmDelete(entry)" class="inline-flex p-1 text-red-600 rounded-lg hover:text-red-400">
                                        <icon name="trash" class="w-4 h-4" />
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="flex items-center justify-between mt-2" v-if="entries.data.length">
            <a
                target="_blank"
                :href="
                    $route('inbox.export', {
                        form: this.$page.props.form,
                    })
                "
                class="flex items-center mr-2 text-white bg-white rounded-lg shadow group focus:ring-green-300 hover:text-gray-500"
            >
                <div class="p-1 ml-1 bg-green-100 rounded-lg group-hover:bg-green-500 group-hover:shadow">
                    <icon name="cloud-download" class="w-4 h-4 text-green-500 transition group-hover:text-white" />
                </div>
                <span class="py-1 pl-1 pr-2 text-sm text-gray-500">Download CSV</span>
            </a>

            <pagination :links="entries.links" class="mt-2" />
        </div>
        <!-- Delete form entry -->
        <jet-confirmation-modal :show="confirmingEntryDeletion" @close="confirmingEntryDeletion = false">
            <template #title> Delete form entry</template>

            <template #content> Are you sure you want to delete this entry? Once a form entry is deleted, it's pretty gone... </template>

            <template #footer>
                <jet-secondary-button @click="confirmingEntryDeletion = false"> Cancel</jet-secondary-button>

                <jet-danger-button
                    class="ml-2"
                    @click="deleteEntry"
                    :class="{ 'opacity-25': deleteEntryForm.processing }"
                    :disabled="deleteEntryForm.processing"
                >
                    Delete form entry
                </jet-danger-button>
            </template>
        </jet-confirmation-modal>
        <!-- View form entry data in JSON format -->
        <jet-dialog-modal :show="viewEntryModal" @close="viewEntryModal = false">
            <template #title>
                <h3 class="font-semibold">JSON</h3>
            </template>

            <template #content>
                <div class="p-2 my-2 -mx-3 bg-gray-100 rounded-lg">
                    <vue-json-pretty :data="entry.data" />
                </div>
            </template>

            <template #footer>
                <jet-secondary-button @click="viewEntryModal = false"> Close</jet-secondary-button>
            </template>
        </jet-dialog-modal>
    </div>
</template>

<script>
import Icon from '@/Components/Icon.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue'
import JetDialogModal from '@/Jetstream/DialogModal.vue'
import JetDangerButton from '@/Jetstream/DangerButton.vue'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
import Pagination from '@/Components/Pagination.vue'
import JetCheckbox from '@/Jetstream/Checkbox.vue'
import TableMeta from '@/Components/TableMeta.vue'
import { format, parseISO } from 'date-fns'
import { router, useForm } from '@inertiajs/vue3'
import { formatInTimeZone } from 'date-fns-tz'
import DateColumn from '@/Components/InboxTable/DateColumn.vue'

export default {
    components: {
        TableMeta,
        Pagination,
        Icon,
        JetSecondaryButton,
        JetConfirmationModal,
        JetDialogModal,
        JetDangerButton,
        VueJsonPretty,
        JetCheckbox,
        DateColumn,
    },
    props: {
        entries: Object,
        columns: Object,
        timezone: {
            type: String,
            default: 'Europe/London',
        },
    },
    data() {
        return {
            confirmingEntryDeletion: false,
            deleteEntryForm: useForm({
                _method: 'delete',
            }),
            entry: null,
            viewEntryModal: false,
            selectColumns: false,
            visibleColumns: this.getColumns(),
            selected: [],
            selectAll: false,
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.redirectIfPageEmpty()
        })
    },
    watch: {
        entries: {
            handler: () => {
                // this.redirectIfPageEmpty()
            },
            deep: true,
        },
    },
    methods: {
        truncateText(text, length = 30) {
            if (text?.length > length) {
                return text.substring(0, length) + '...'
            }

            return text
        },
        saveColumns() {
            this.selectColumns = false
            localStorage.setItem(
                this.$page.props.form._id,
                JSON.stringify({
                    visibleColumns: this.visibleColumns,
                })
            )
        },
        getColumns() {
            const form = JSON.parse(localStorage.getItem(this.$page.props.form._id))

            if (!form) {
                return []
            }

            return form?.visibleColumns || []
        },
        isDateColumn(column) {
            return this.columns.filter((c) => c.name === column && c.field_type.slice(0, 4) === 'date').length > 0
        },
        formatDateColumn(value, f = 'dd/mm/yyyy', pretty = false) {
            if (!value) return false

            if (!pretty) {
                return this.formatDate(value)
            }

            if (format(parseISO(value), 'yyyy') === new Date().getFullYear().toString()) {
                return formatInTimeZone(value, this.timezone, 'dd MMM')
            }

            return formatInTimeZone(value, this.timezone, 'dd MMM yyyy zzz')
        },
        formatDate(apiDate) {
            if (!apiDate) return '-'
            // Create a new Date object using the API date string
            const dateObj = new Date(apiDate)

            // Use the getMonth() method to get the month as a number (0-11), then add 1 to get the actual month number
            const month = dateObj.getMonth() + 1

            // Use the getDate() method to get the day of the month
            const day = dateObj.getDate()

            // Use the getFullYear() method to get the year
            const year = dateObj.getFullYear()

            // Use string interpolation to format the date as "day month year"
            // Return the formatted date string
            return `${day}/${month}/${year}`
        },
        getFieldType(field) {
            let col = this.columns.filter((c) => c.name === field)
            if (col.length > 0) {
                return col[0].field_type
            }
            return null
        },
        confirmDelete(entry) {
            this.confirmingEntryDeletion = true
            this.entry = entry
        },
        view(entry) {
            this.entry = entry
            this.viewEntryModal = true
        },
        async deleteEntry() {
            try {
                router.delete(route('inbox.destroy', { form: this.$page.props.form._id, formEntry: this.entry._id }), {
                    preserveState: false,
                    errorBag: 'deleteField',
                })
            } catch (err) {
                console.log(err)
            }
        },
        redirectIfPageEmpty() {
            if (this.entries?.data.length === 0 && this.entries?.current_page > 1) {
                router.replace(this.entries.prev_page_url, {
                    preserveState: false,
                })
            }
        },
        select() {
            this.selected = []
            if (!this.selectAll) {
                for (let i in this.entries.data) {
                    this.selected.push(this.entries.data[i]['_id'].toString())
                }
            }
        },
        prettyDate(val) {
            return formatInTimeZone(val, this.timezone, 'dd/MM/yyyy HH:mm:s')
        },
    },
}
</script>
