<template>
    <div class="text-[10px]">
        <div class="flex items-center space-x-1">
            <button
                type="button"
                :disabled="currentStep === 0"
                :class="{
                    'opacity-50 cursor-not-allowed': currentStep === 0,
                }"
                @click="prev"
                class="p-1 bg-gray-100 rounded-full"
            >
                <icon name="arrow-right" class="w-3 h-3 text-gray-500 -scale-x-100" />
            </button>
            <div>{{ currentStep + 1 }} of {{ steps }}</div>
            <button
                type="button"
                :disabled="currentStep === steps - 1"
                :class="{
                    'opacity-50 cursor-not-allowed': currentStep === steps - 1,
                }"
                @click="next"
                class="p-1 bg-gray-100 rounded-full"
            >
                <icon name="arrow-right" class="w-3 h-3 text-gray-500" />
            </button>
        </div>
    </div>
</template>
<script>
import Icon from '@/Components/Icon.vue'

export default {
    emits: ['next', 'prev'],

    components: { Icon },

    props: {
        currentStep: {
            type: Number,
            required: true,
        },
        steps: {
            type: Number,
            required: true,
        },
    },
    methods: {
        next() {
            this.$emit('next', this.currentStep + 1)
        },
        prev() {
            this.$emit('prev', this.currentStep - 1)
        },
    },
}
</script>
