<template>
    <Popover v-slot="{ open }" class="relative flex items-center">
        <PopoverButton v-if="slots.handler" class="!border-0 outline-0 focus:border-0 ring-0 focus:ring-0">
            <slot name="handler"></slot>
        </PopoverButton>
        <PopoverButton v-else :class="open ? '' : 'opacity-30'" class="inline-flex transition-opacity group hover:opacity-100">
            <icon name="info" class="w-4 h-4" />
        </PopoverButton>

        <transition
            enter-active-class="transition duration-200 ease-out"
            enter-from-class="translate-y-1 opacity-0"
            enter-to-class="translate-y-0 opacity-100"
            leave-active-class="transition duration-150 ease-in"
            leave-from-class="translate-y-0 opacity-100"
            leave-to-class="translate-y-1 opacity-0"
        >
            <PopoverPanel
                class="absolute z-10 mt-3 min-w-[300px] pos max-w-[370px] transform px-4 sm:px-0 lg:max-w-3xl"
                :class="{
                    '-left-1/2 -translate-x-1/2': alignCenter,
                    '-ml-3': !alignCenter,
                }"
            >
                <div class="overflow-hidden rounded-lg shadow-xl ring-1 ring-black ring-opacity-5">
                    <div
                        class="px-4 py-3 bg-white"
                        :class="{
                            'text-center': alignCenter,
                        }"
                    >
                        <slot></slot>
                    </div>
                    <div class="arrow"></div>
                </div>
            </PopoverPanel>
        </transition>
    </Popover>
</template>

<script setup>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { defineProps, useSlots } from 'vue'
import Icon from '@/Components/Icon.vue'

const props = defineProps({
    alignCenter: {
        type: Boolean,
        default: false,
    },
})

const slots = useSlots()
</script>
<style>
.arrow:before,
.arrow:after {
    content: '';
    position: absolute;
    left: 15px;
    bottom: -13px;
    border-top: 6px solid #d5d5d5;
    border-right: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent;
}
.arrow:after {
    border-top: 6px solid white;
    bottom: -12px;
}
.pos {
    bottom: calc(100% + 5px);
}
</style>
