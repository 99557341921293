<template>
    <div class="md:grid md:grid-cols-3 md:gap-6" :class="{ 'md:col-span-2': detailed }">
        <jet-section-title v-if="detailed" :class="{ 'order-last': reversed }">
            <template #title><slot name="title"></slot></template>
            <template #description><slot name="description"></slot></template>
        </jet-section-title>
        <div class="mt-5 md:mt-0" :class="[detailed ? 'md:col-span-2' : 'md:col-span-3']">
            <form @submit.prevent="$emit('submitted')">
                <div class="py-5 sm:py-6">
                    <div class="grid grid-cols-6 gap-6">
                        <slot name="form"></slot>
                    </div>
                </div>

                <div class="flex items-center" v-if="hasActions">
                    <slot name="actions"></slot>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import JetSectionTitle from './SectionTitle.vue'

export default {
    emits: ['submitted'],

    props: {
        detailed: {
            type: Boolean,
            default: true,
        },
        reversed: {
            default: false,
        },
    },

    components: {
        JetSectionTitle,
    },

    computed: {
        hasActions() {
            return !!this.$slots.actions
        },
    },
}
</script>
