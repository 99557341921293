<template>
    <div class="absolute top-0 left-0 z-50 w-full h-full overflow-y-auto bg-white page-create-field">
        <div class="">
            <div class="flex border-b border-gray-200">
                <Link :href="$route('fields.index', { form: $route().params.form })" as="button" class="self-center ml-4 btn"><XIcon class="w-4 h-4" /></Link>
                <div class="py-6 pl-4 ml-4 border-l border-gray-200">
                    <h3 class="text-sm font-semibold leading-tight tracking-tight">Create a field</h3>
                </div>
            </div>
            <div class="w-[90%] mx-auto">
                <div class="max-w-md mt-5">
                    <div class="space-y-5">
                        <!-- Field label -->
                        <div class="col-span-6 sm:col-span-4">
                            <div class="flex items-center mb-2">
                                <jet-label for="label" value="Field label" class="mb-0" />
                                <info class="ml-1">
                                    <p v-if="$page.props.meta.form_type === 'FORM'" class="text-xs text-body">This is what users of your form will see.</p>
                                    <p v-else class="text-xs text-body">This label will help you identify the field throughout the dashboard.</p>
                                </info>
                            </div>

                            <jet-input id="label" type="text" class="block w-full" v-model="formField.label" ref="label" autofocus />
                            <jet-input-error :message="formField.errors.label" class="mt-1" />
                        </div>

                        <!--Field name-->
                        <div class="col-span-6 sm:col-span-4">
                            <div class="flex items-center mb-2">
                                <jet-label for="name" value="Field name" class="mb-0" />
                                <info class="ml-1">
                                    <p v-if="$page.props.meta.form_type === 'FORM'" class="text-xs text-body">
                                        This is what the HTML element name will be. Users will not be able to see it. You can use this as internal reference to
                                        your field.
                                    </p>
                                    <p v-else-if="$page.props.meta.form_type === 'ENDPOINT'" class="text-xs text-body">
                                        This is what the HTML element name will be.
                                    </p>
                                </info>
                            </div>
                            <jet-input id="name" type="text" class="block w-full" v-model="formField.name" />
                            <jet-input-error :message="formField.errors.name" class="mt-1" />
                        </div>

                        <!-- Field type-->
                        <div class="col-span-6 sm:col-span-4">
                            <jet-label for="type" value="Field type" />
                            <jet-select id="type" class="block w-full mt-1" v-model="formField.type">
                                <template #options>
                                    <option value="" disabled selected>Pick field type</option>
                                    <option v-for="(type, k) in inputTypes" :value="type" :key="k">
                                        {{ type }}
                                    </option>
                                </template>
                            </jet-select>
                            <jet-input-error :message="formField.errors.type" class="mt-1" />
                        </div>

                        <!-- Field options -->
                        <div v-if="formField.type === 'select' || formField.type === 'radio'" class="col-span-6 sm:col-span-4">
                            <div class="flex items-center mb-2">
                                <jet-label value="Field options" class="mb-0" />
                                <info class="ml-1"><p class="text-xs text-body">Please add options for users to pick.</p></info>
                            </div>

                            <div v-if="formField.options.length > 0">
                                <div v-for="(option, index) in formField.options" :key="index" class="flex items-center pl-3 mb-3">
                                    <div>
                                        <div class="flex items-center my-3">
                                            <div class="flex-1">
                                                <div class="flex items-center mb-2">
                                                    <jet-label for="optionName" value="Option title" class="mb-0 mr-1" />
                                                    <info><p class="text-xs text-body">This is what users of your form will see.</p></info>
                                                </div>
                                                <jet-input
                                                    :id="'fieldOptions' + index"
                                                    type="text"
                                                    class="block w-full"
                                                    v-model="formField.options[index].title"
                                                />
                                                <jet-input-error
                                                    v-if="formField.errors['options.' + index + '.title']"
                                                    :message="formField.errors['options.' + index + '.title']"
                                                    class="mt-1"
                                                />
                                            </div>
                                            <div class="flex-1 ml-2">
                                                <div class="flex items-center mb-2">
                                                    <jet-label for="optionValue" value="Option value" class="mb-0 mr-1" />
                                                    <info
                                                        ><p class="text-xs text-body">
                                                            This is the internal value of the option which you can use it for reference.
                                                        </p></info
                                                    >
                                                </div>
                                                <jet-input
                                                    :id="'fieldValue' + index"
                                                    type="text"
                                                    class="block w-full"
                                                    v-model="formField.options[index].value"
                                                />
                                                <jet-input-error
                                                    v-if="formField.errors['options.' + index + '.value']"
                                                    :message="formField.errors['options.' + index + '.value']"
                                                    class="mt-1"
                                                />
                                            </div>
                                        </div>
                                        <div class="my-3">
                                            <div class="flex items-center mb-2">
                                                <jet-label :for="'optionHelpBlock' + index" value="Option description" class="mb-0 mr-1" />
                                                <info
                                                    ><p class="text-xs text-body">
                                                        This is an optional description field. It allows you to show more information about the option to your
                                                        user.
                                                    </p>
                                                </info>
                                            </div>
                                            <jet-textarea
                                                :id="'fieldHelpBlock' + index"
                                                class="box-border w-full mt-1"
                                                v-model="formField.options[index].helpBlock"
                                            />
                                            <jet-input-error
                                                v-if="formField.errors?.options?.length"
                                                :message="formField.errors.options[index].helpBlock"
                                                class="mt-1"
                                            />
                                        </div>
                                        <button class="text-xs btn" @click="removeOption(index)">Remove</button>
                                    </div>
                                </div>
                            </div>
                            <jet-button-secondary type="button" class="p-0 mt-2 btn" @click="addOption">
                                <PlusSmIcon class="w-4 h-4" /> <span class="ml-1">Add option</span>
                            </jet-button-secondary>
                        </div>
                    </div>
                </div>

                <jet-form-section @submitted="handleCreate" :reversed="true" class="mt-5">
                    <template #form>
                        <div class="col-span-12">
                            <field-validation-rules :handle-selection="handleSelection" :spotlight="spotlight" :form-field="formField" />
                        </div>
                    </template>
                    <template #actions>
                        <div class="flex flex-col">
                            <jet-button-secondary class="self-start mb-10" :disabled="formField.processing" @click.prevent="spotlight = true">
                                <PlusSmIcon class="w-4 h-4" /> <span class="ml-1">Add rule</span>
                            </jet-button-secondary>
                            <jet-button :class="{ 'opacity-25': formField.processing }" :disabled="formField.processing"> Create field </jet-button>
                        </div>
                        <jet-action-message :on="formField.recentlySuccessful" class="ml-3"> Saved.</jet-action-message>
                    </template>
                </jet-form-section>
            </div>
        </div>
    </div>
</template>

<script>
import { Link } from '@inertiajs/vue3'
import ShowFormLayout from '@/Layouts/ShowFormLayout.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetSelect from '@/Jetstream/Select.vue'
import DangerButton from '@/Jetstream/DangerButton.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetButtonSecondary from '@/Jetstream/SecondaryButton.vue'
import JetFormSection from '@/Jetstream/FormSection.vue'
import JetInputError from '@/Jetstream/InputError.vue'
import JetRemoveButton from '@/Jetstream/RemoveButton.vue'
import JetTextarea from '@/Jetstream/Textarea.vue'
import JetFieldInfo from '@/Jetstream/FieldInfo.vue'
import JetCheckbox from '@/Jetstream/Checkbox.vue'
import Multiselect from '@vueform/multiselect'
import mimetypes from '@/mimetypes.json'
import AppLayout from '@/Layouts/AppLayout.vue'
import FieldValidationRules from '@/Components/FieldValidationRules.vue'
import JetActionMessage from '@/Jetstream/ActionMessage.vue'
import rules from '@/rules'
import fieldRules from '@/field-rules.json'
import Info from '@/Components/Info.vue'
import { PlusSmIcon, XIcon } from '@heroicons/vue/solid'
import { useForm } from '@inertiajs/vue3'

export default {
    layout: [AppLayout, ShowFormLayout],
    components: {
        Link,
        Info,
        PlusSmIcon,
        XIcon,
        Multiselect,
        JetCheckbox,
        JetFieldInfo,
        JetTextarea,
        JetRemoveButton,
        JetFormSection,
        JetButtonSecondary,
        JetActionMessage,
        FieldValidationRules,
        JetButton,
        DangerButton,
        JetSelect,
        JetInput,
        JetLabel,
        ShowFormLayout,
        JetInputError,
    },
    props: ['form'],
    data() {
        return {
            mimetypes: mimetypes,
            unsaved: false,
            formField: useForm({
                classNames: null,
                helpBlock: null,
                inputValue: null,
                label: '',
                name: '',
                options: [],
                rules: [],
                type: 'text',
            }),
            inputTypes: ['text', 'email', 'textarea', 'select', 'checkbox', 'radio', 'date', 'date_time', 'date_range', 'file'],
            rules,
            fieldRules,
            spotlight: false,
        }
    },
    mounted() {
        document.addEventListener('keydown', this.toggleSpotlight)
        this.$refs.label.focus()
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.toggleSpotlight)
    },
    methods: {
        handleCreate() {
            this.formField.post(route('fields.store', this.$page.props.form._id))
        },
        toggleSpotlight(e) {
            if (e.keyCode === 69 && e.metaKey) {
                this.spotlight = !this.spotlight
            } else if (this.spotlight && e.keyCode === 27) {
                this.spotlight = false
            }
        },
        /**
         * Add rule to field rules array if it doesn't exist already
         */
        handleSelection(rule) {
            // if rule exists, remove it from array
            if (this.formField.rules.some((r) => r.name === rule.name)) {
                this.formField.rules = this.formField.rules.filter((r) => r.name === rule.name)
            } else {
                this.spotlight = false
                this.formField.rules.push({ ...rule })
            }
        },
        addOption() {
            this.formField.options.push({
                title: '',
                value: '',
                helpBlock: '',
            })
        },

        removeOption(index) {
            this.formField.options.splice(index, 1)
        },
    },
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
::v-deep .multiselect-input {
    background: #fff;
    border-radius: 4px;
    height: 36px !important;
}

::v-deep .multiselect-clear {
    height: 33px !important;
}
</style>
