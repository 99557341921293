<template>
    <confirmation-modal :icon-color="'blue'" :icon-name="'pencil'" :max-width="'md'" :show="show" @close="emit('close')">
        <template #title>
            <h3 class="text-lg">Change the step</h3>
        </template>
        <template #content>
            <p class="mb-4 text-base text-md">Field will be moved to the form step you select</p>
            <jet-label for="formStep" value="Move to" />
            <jet-select class="text-xs py-1 !shadow-sm hover:shadow" v-model="form.step">
                <template #options>
                    <option value="" disabled selected>Choose step</option>
                    <option v-for="(step, key) in steps" :key="key" :value="key + 1">No. {{ key + 1 }} - {{ step }}</option>
                </template>
            </jet-select>
        </template>
        <template #footer>
            <div>
                <jet-secondary-button class="mr-2 btn" @click="onCancel">Cancel</jet-secondary-button>
                <jet-button :class="{ 'opacity-25': form.processing }" :disabled="form.processing" @click="onSubmit"> Move field </jet-button>
            </div>
        </template>
    </confirmation-modal>
</template>
<script setup>
import ConfirmationModal from '@/Jetstream/ConfirmationModal.vue'
import JetSelect from '@/Jetstream/Select.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import { defineEmits, onMounted, ref } from 'vue'
import { useForm, usePage } from '@inertiajs/vue3'
import { onKeyUp } from '@vueuse/core'

const emit = defineEmits(['close', 'changeStepForField'])

const props = defineProps({
    currentStepField: [Object, null],
    show: Boolean,
})

const currentStep = ref(JSON.parse(JSON.stringify(props.currentStepField?.item)))
const steps = usePage().props.form.layout.children.map((s) => s.title)

const form = useForm({
    step: 0,
})

const onCancel = () => {
    form.reset()
    emit('close')
}

const onSubmit = () => {
    emit('changeStepForField', { field: currentStep.value, step: parseInt(form.data().step) })
}

onMounted(() => {
    onKeyUp('Enter', onSubmit)
})
</script>
