<template>
    <select
        :disabled="disabled"
        class="py-1 text-sm border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-body disabled:opacity-50 disabled:cursor-not-allowed"
        :value="modelValue"
        @change="$emit('update:modelValue', $event.target.value)"
    >
        <slot name="options"></slot>
    </select>
</template>

<script>
export default {
    props: ['modelValue', 'disabled'],

    emits: ['update:modelValue'],
}
</script>

<style scoped></style>
