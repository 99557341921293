<template>
    <div>
        <!--        <div class="px-8 py-4 bg-blue-600" />-->

        <div class="max-w-screen-lg px-4 py-10 mx-auto mb-4">
            <div class="flex items-center justify-between mt-10">
                <div>
                    <h3 class="text-3xl font-black leading-tight tracking-tight">Endpoints</h3>
                    <p class="text-lg text-gray-700 max-w-2lg">All your endpoints from this workspace will be shown below.</p>
                </div>
                <button @click="createFormModal = true" class="flex items-center px-3 py-2 -my-2 bg-blue-600 rounded font-regular group">
                    <div class="p-1 mr-2 transition bg-blue-700 rounded group-hover:bg-white">
                        <svg
                            class="w-4 h-4 text-white group-hover:hidden"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                        </svg>
                        <span class="hidden w-4 h-4 text-sm leading-tight text-white group-hover:block group-hover:text-blue-600">C</span>
                    </div>
                    <span class="text-sm font-semibold text-white">Create new endpoint</span>
                </button>
            </div>

            <div class="mt-10 -mx-4 ring-1 ring-gray-300 sm:-mx-6 md:mx-0 md:rounded-lg" v-if="$page.props.forms.data.length">
                <table class="min-w-full divide-y divide-gray-300">
                    <thead>
                        <tr>
                            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Name</th>
                            <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Status</th>
                            <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Entries</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Created</th>
                            <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                <span class="sr-only">Edit</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(form, formIdx) in $page.props.forms.data" :key="form.id">
                            <td :class="[formIdx === 0 ? '' : 'border-t border-transparent', 'relative py-4 pl-4 sm:pl-6 pr-3 text-sm']">
                                <Link :href="$route('forms.inbox', form)" class="font-medium text-gray-900 transition hover:text-blue-600">
                                    {{ form.name }}
                                </Link>
                                <div v-if="formIdx !== 0" class="absolute right-0 h-px bg-gray-200 left-6 -top-px" />
                            </td>
                            <td :class="[formIdx === 0 ? '' : 'border-t border-gray-200', 'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell']">
                                <div class="flex items-center">
                                    <!--                                    <icon v-if="form.published" name="eye" class="w-5 h-5 text-blue-500" />-->
                                    <!--                                    <icon v-else name="eye-off" class="w-5 h-5 text-blue-500" />-->
                                    <span class="ml-1">{{ form.published ? 'Published' : 'Unpublished' }}</span>
                                </div>
                            </td>
                            <td :class="[formIdx === 0 ? '' : 'border-t border-gray-200', 'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell']">
                                {{ form.entries_count.toLocaleString() }} {{ form.entries_count === 1 ? 'entry' : 'entries' }}
                            </td>
                            <td :class="[formIdx === 0 ? '' : 'border-t border-gray-200', 'px-3 py-3.5 text-sm text-gray-500']">
                                {{ format(new Date(form.created_at), 'dd MMMM yyyy') }}
                            </td>
                            <td
                                :class="[
                                    formIdx === 0 ? '' : 'border-t border-transparent',
                                    'relative py-3.5 pl-3 pr-4 sm:pr-6 text-right text-sm font-medium',
                                ]"
                            >
                                <Menu as="div" class="relative inline-block text-left">
                                    <MenuButton class="p-0.5 hover:shadow-md w-8 flex items-center justify-center rounded-md mr-3 relative top-1">
                                        <DotsHorizontalIcon class="w-4 h-4 text-gray-500 hover:text-gray-800" aria-hidden="true" />
                                    </MenuButton>
                                    <transition
                                        enter-active-class="transition duration-100 ease-out"
                                        enter-from-class="transform scale-95 opacity-0"
                                        enter-to-class="transform scale-100 opacity-100"
                                        leave-active-class="transition duration-75 ease-in"
                                        leave-from-class="transform scale-100 opacity-100"
                                        leave-to-class="transform scale-95 opacity-0"
                                    >
                                        <MenuItems
                                            class="absolute right-0 z-50 w-32 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                        >
                                            <div class="px-1 py-1">
                                                <MenuItem v-slot="{ active }">
                                                    <Link
                                                        :href="$route('forms.inbox', form)"
                                                        :class="[
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                                                            'group flex w-full items-center rounded-md px-2 py-1 text-xs',
                                                        ]"
                                                    >
                                                        <icon name="pencil" class="w-4 h-4 mr-2 text-body" /> <span>View</span>
                                                    </Link>
                                                </MenuItem>
                                                <MenuItem v-slot="{ active }">
                                                    <button
                                                        @click.prevent="exportEntries(form)"
                                                        :class="[
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                                                            'group flex w-full items-center rounded-md px-2 py-1 text-xs',
                                                        ]"
                                                    >
                                                        <icon name="cloud-download" class="w-4 h-4 mr-2 text-body" />Export
                                                    </button>
                                                </MenuItem>
                                                <MenuItem v-slot="{ active }">
                                                    <button
                                                        @click.prevent="confirmRemoveForm(form)"
                                                        :class="[
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                                                            'group flex w-full items-center rounded-md px-2 py-1 text-xs',
                                                        ]"
                                                    >
                                                        <icon name="trash" class="w-4 h-4 mr-2 text-body" /><span>Remove</span>
                                                    </button>
                                                </MenuItem>
                                            </div>
                                        </MenuItems>
                                    </transition>
                                </Menu>
                                <div v-if="formIdx !== 0" class="absolute left-0 h-px bg-gray-200 right-6 -top-px" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="mt-5 bg-white rounded shadow" v-else>
                <img src="/images/no-data.svg" class="max-w-sm mx-auto" alt="No data illustration" />
            </div>

            <pagination :links="forms.links" class="mt-2" />
        </div>
        <create-form-modal v-if="createFormModal" form-type="endpoint" :show="createFormModal" max-width="xl" @close="createFormModal = false" />
        <confirmation-modal :show="confirmRemoveFormModal" @close="confirmRemoveFormModal = false">
            <template #title>Are you sure?</template>
            <template #content
                >Remove the form <strong>{{ confirmRemoveFormModal.name }}</strong
                >. Once removed, it's pretty gone.
            </template>
            <template #footer>
                <jet-secondary-button @click="confirmRemoveFormModal = false"> Cancel</jet-secondary-button>

                <jet-danger-button class="ml-2" @click="handleRemoveForm" :class="{ 'opacity-25': removeForm.processing }" :disabled="removeForm.processing">
                    Remove form
                </jet-danger-button>
            </template>
        </confirmation-modal>
    </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import Pagination from '@/Components/Pagination.vue'
import CreateFormModal from '@/Pages/Forms/CreateFormModal.vue'
import Icon from '@/Components/Icon.vue'
import ConfirmationModal from '@/Jetstream/ConfirmationModal.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import JetDangerButton from '@/Jetstream/DangerButton.vue'
import { AtSymbolIcon, CalendarIcon, CheckCircleIcon, CloudUploadIcon, DocumentTextIcon, SelectorIcon } from '@heroicons/vue/outline'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon, DotsHorizontalIcon } from '@heroicons/vue/solid'
import { format } from 'date-fns'
import { useForm, Link } from '@inertiajs/vue3'

export default {
    layout: AppLayout,
    components: {
        ConfirmationModal,
        Pagination,
        AppLayout,
        CreateFormModal,
        Icon,
        JetSecondaryButton,
        JetDangerButton,
        AtSymbolIcon,
        DocumentTextIcon,
        SelectorIcon,
        CheckCircleIcon,
        CalendarIcon,
        CloudUploadIcon,
        Menu,
        MenuButton,
        MenuItems,
        MenuItem,
        ChevronDownIcon,
        DotsHorizontalIcon,
        Link,
    },
    props: {
        forms: Object,
    },
    data() {
        return {
            createFormModal: false,
            confirmRemoveFormModal: false,
            removeForm: useForm({}),
            format,
        }
    },

    mounted() {
        document.addEventListener('keydown', this.handleModal)
    },

    methods: {
        confirmRemoveForm(form) {
            this.confirmRemoveFormModal = {
                name: form.name,
                uuid: form._id,
            }
        },
        handleRemoveForm() {
            this.removeForm.delete(route('forms.destroy', this.confirmRemoveFormModal.uuid), {
                onSuccess: () => {
                    this.confirmRemoveFormModal = false
                },
                preserveState: false,
            })
        },
        handleModal(e) {
            if (e.key === 'c' || e.key === 'C') {
                if (!this.createFormModal) {
                    this.createFormModal = true
                }
            }
        },
        exportEntries(form) {
            window.open(
                route('inbox.export', {
                    form,
                })
            )
        },
    },

    beforeUnmount() {
        document.removeEventListener('keydown', this.handleModal)
    },
}
</script>

<style scoped lang="scss">
.pf-card:hover {
    .card-btn {
        opacity: 1;
    }
}
</style>
