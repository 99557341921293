<template>
    <div class="flex sm:justify-center mt-10 mb-4">
        <fieldset class="grid grid-cols-2 p-1 text-xs font-semibold leading-5 text-center bg-white rounded-full gap-x-1 ring-1 ring-inset ring-gray-200">
            <legend class="sr-only">Payment frequency</legend>
            <label class="cursor-pointer rounded-full px-2.5 py-1" :class="modelValue === 'monthly' ? 'bg-blue-600 text-white' : 'text-gray-500'">
                <input type="radio" @click="$emit('update:modelValue', $event.target.value)" value="monthly" class="sr-only" />
                <span>Monthly</span>
            </label>
            <label class="cursor-pointer rounded-full px-2.5 py-1" :class="modelValue === 'yearly' ? 'bg-blue-600 text-white' : 'text-gray-500'">
                <input type="radio" @click="$emit('update:modelValue', $event.target.value)" value="yearly" class="sr-only" />
                <span>Annually</span>
            </label>
        </fieldset>
    </div>
</template>
<script>
import JetSelect from '@/Jetstream/Select.vue'

export default {
    emits: ['update:modelValue'],

    components: {
        JetSelect,
    },

    props: {
        modelValue: {
            type: String,
            required: true,
        },
    },
}
</script>
