<template>
    <teleport to="body">
        <div
            v-show="show"
            class="fixed flex items-center inset-0 overflow-ys-auto px-4 py-6 sm:px-0 z-50"
        >
            <transition
                enter-active-class="ease-out duration-300"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="ease-in duration-200"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <div
                    v-show="show"
                    class="fixed inset-0 transform transition-all"
                    @click="close"
                >
                    <div
                        class="absolute inset-0 bg-gray-600/30 filter backdrop-blur-sm"
                    ></div>
                </div>
            </transition>
        </div>
        <div
            class="fixed flex items-center justify-center inset-0 overflow-ys-auto px-4 py-6 sm:px-0 z-50"
        >
            <div
                v-show="show"
                class="flex flex-col mb-6 rounded-lg overflow-hidden transform transition-all spotlight-c sm:w-full sm:mx-auto bg-white shadow-xl sm:max-w-2xl"
            >
                <div class="border-b relative">
                    <div
                        class="w-8 h-8 bg-blue-500 rounded absolute top-1/2 transform -translate-y-1/2 ml-3 absolute p-1"
                    >
                        <icon name="search" class="w-6 h-6 text-white" />
                    </div>
                    <input
                        ref="textbox"
                        @keyup.up="select"
                        @keyup.down="select"
                        @keyup.enter="makeSelection(this.selectedIndex)"
                        v-model="search"
                        type="text"
                        class="w-full border-0 inset-0 pl-14 pr-4 py-4 text-xl font-semibold focus:ring-0"
                        autofocus
                    />
                </div>
                <div class="flex">
                    <div
                        class="spotlight-sidebar border-r py-4 px-2 space-y-2 no-scrollbar overflow-y-scroll overflow-x-hidden"
                        ref="sidebar"
                    >
                        <div
                            v-for="(option, index) in filtered"
                            :key="option.name"
                            @click="handleClick(index)"
                            :ref="`opt${index}`"
                        >
                            <slot
                                name="option"
                                :filtered="filtered"
                                :option="option"
                                :index="index"
                                :selected-index="selectedIndex"
                                :ref="'option' + index"
                            >
                                <div
                                    class="p-2 rounded hover:bg-gray-100 text-sm text-gray-500 hover:text-blue-500 cursor-pointer"
                                    :class="{
                                        'bg-gray-100 text-gray-700 font-semibold':
                                            selectedIndex === index,
                                    }"
                                >
                                    {{ option.label }}
                                </div>
                            </slot>
                        </div>
                    </div>
                    <div class="py-4 px-4 bg-blue-10 flex-1">
                        <slot name="meta" :selected="selectedOption">
                            <div class="spotlight-meta overflow-y-auto">
                                <h3
                                    class="font-bold tracking-tight leading-4 text-md mb-2"
                                >
                                    {{ selectedOption.label }}
                                </h3>
                                <div
                                    class="rule-content"
                                    v-if="
                                        selectedOption.hasOwnProperty(
                                            'description'
                                        )
                                    "
                                    v-html="selectedOption.description"
                                />
                                <div class="rule-content">
                                    <div
                                        v-if="selectedOption?.examples?.length"
                                        class="flex flex-col space-y-2 items-start pb-5"
                                    >
                                        <h3
                                            class="font-bold tracking-tight leading-4 text-tiny uppercase tracking-wide mb-2"
                                        >
                                            Examples
                                        </h3>
                                        <div
                                            class="flex shadow items-center bg-white rounded"
                                            v-for="item in selectedOption.examples"
                                        >
                                            <div
                                                class="py-2 px-2 font-semibold text-xs"
                                            >
                                                <span
                                                    class="bg-blue-500 text-white p-1 rounded"
                                                    >{{
                                                        selectedOption.name
                                                    }}</span
                                                >
                                            </div>
                                            <div
                                                class="py-2 px-2 bg-white text-xs rounded"
                                            >
                                                {{ item.code }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>
<script>
import { onMounted, onUnmounted, ref } from 'vue'
import Icon from '@/Components/Icon.vue'
import JetLabel from '@/Components/Label.vue'
import Info from '@/Components/Info.vue'

export default {
    emits: ['close', 'selection'],

    components: {
        Icon,
        JetLabel,
        Info,
    },

    props: {
        show: {
            default: false,
        },
        closeable: {
            default: true,
        },
        options: {
            default: [],
        },
    },
    watch: {
        show: {
            immediate: true,
            handler: (show) => {
                if (show) {
                    document.body.style.overflow = 'hidden'
                } else {
                    document.body.style.overflow = null
                }
            },
        },
    },

    computed: {
        filtered() {
            this.selectedIndex = 0

            // check if spotlight options is an object like: { components, fields}
            // this is for <layout-builder />
            if (Object.keys(this.options)[0] === 'components') {
                return [
                    ...this.options.components,
                    ...this.options.fields,
                ].filter((option) => {
                    return option.label
                        .toString()
                        .toLowerCase()
                        .includes(this.search.toLowerCase())
                })
            } else {
                // otherwise just filter like an array
                return this.options.filter((option) => {
                    return option.label
                        .toString()
                        .toLowerCase()
                        .includes(this.search.toLowerCase())
                })
            }
        },
        selectedOption() {
            return this.filtered[this.selectedIndex]
        },
    },
    methods: {
        select(e) {
            if (e.keyCode === 40) {
                if (this.selectedIndex < this.filtered.length - 1) {
                    let el = this.$refs['opt' + this.selectedIndex]
                    if (el) {
                        el.scrollIntoView()
                    }
                    this.selectedIndex++
                }
            } else if (e.keyCode === 38) {
                if (this.selectedIndex > 0) {
                    this.selectedIndex--
                }
            }
        },
        handleClick(idx) {
            this.selectedIndex = idx
        },

        makeSelection(index) {
            this.$emit('selection', { ...this.filtered[index] })
        },
    },
    setup(props, { emit }) {
        const search = ref('')
        const textbox = ref(null)
        const selectedIndex = ref(0)

        const close = () => {
            if (props.closeable) {
                emit('close')
            }
        }

        const closeOnEscape = (e) => {
            if (e.key === 'Escape' && props.show) {
                close()
            }
        }

        onMounted(() => {
            document.addEventListener('keydown', closeOnEscape)

            textbox.value.focus()
        })

        onUnmounted(() =>
            document.removeEventListener('keydown', closeOnEscape)
        )

        return {
            close,
            search,
            textbox,
            selectedIndex,
        }
    },
}
</script>
<style lang="scss">
.rule-content {
    padding-bottom: 20px;

    pre {
        padding: 10px 4px;
        background: white;
        margin-bottom: 20px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
            0 1px 2px 0 rgba(0, 0, 0, 0.06);
        border-radius: 8px;

        code {
            box-shadow: none;
        }
    }

    p {
        font-size: 13px;
        margin-bottom: 20px;
    }

    a {
        color: rgba(59, 130, 246, 1);
        text-decoration: underline;
    }
}

.spotlight-c {
    height: 400px;
}

.spotlight-sidebar {
    width: 200px;
    height: 330px;
}

.spotlight-meta {
    height: 310px;
}
</style>
