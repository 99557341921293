<template>
    <button
        :type="type"
        class="inline-flex items-center justify-center px-3 py-2 text-sm leading-4 bg-red-600 border-0 rounded-md font-semibold text-xs text-white hover:bg-red-500 focus:outline-none focus:border-red-700 focus:ring focus:ring-red-200 active:bg-red-600 disabled:opacity-25 transition"
    >
        <slot></slot>
    </button>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'button',
        },
    },
}
</script>
