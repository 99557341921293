<template>
    <div class="h-screen overflow-y-auto no-scrollbar">
        <div class="p-6 mt-[13px] w-full relative">
            <fun-bg />

            <div
                class="relative bg-white max-w-[545px] pb-3 card-shadow md:pt-[65px] pt-8 px-5 md:px-[65px] rounded-lg mx-auto mt-[120px]"
            >
                <wordmark class="mb-3" />
                <h3
                    class="mb-4 leading-relaxed font-bold tracking-tight text-2xl text-[#2e161e]"
                >
                    Forgot your password
                </h3>
                <div class="mb-4 text-sm leading-6 text-gray-600">
                    Forgot your password? No problem. Just let us know your
                    email address and we will email you a password reset link
                    that will allow you to choose a new one.
                </div>

                <div
                    v-if="status"
                    class="mb-4 text-xs font-medium text-green-600"
                >
                    {{ status }}
                </div>

                <jet-validation-errors class="mb-4" />

                <form @submit.prevent="submit" class="pb-4 sm:pb-10">
                    <jet-input
                        id="email"
                        placeholder="Email address"
                        type="email"
                        class="!h-[40px] block w-full"
                        v-model="form.email"
                        required
                        autofocus
                    />

                    <pf-button
                        class="mt-4 w-full !py-4 !btn-bg justify-center"
                        :class="{ 'opacity-25': form.processing }"
                        :disabled="form.processing"
                    >
                        Email password reset link
                    </pf-button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import JetButton from '@/Jetstream/Button.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetValidationErrors from '@/Jetstream/ValidationErrors.vue'
import { useForm } from '@inertiajs/vue3'
import FunBg from '@/Components/FunBg.vue'
import PfButton from '@/Pixelform/Button.vue'
import Wordmark from '@/Components/Wordmark.vue'

export default {
    components: {
        JetButton,
        JetInput,
        JetLabel,
        JetValidationErrors,
        PfButton,
        FunBg,
        Wordmark,
    },

    props: {
        status: String,
    },

    data() {
        return {
            form: useForm({
                email: '',
            }),
        }
    },

    methods: {
        submit() {
            this.form.post(route('password.email'), {
                preserveScroll: true,
                onSuccess: () => this.form.reset(),
            })
        },
    },
}
</script>
