<template>
    <div class="max-w-lg">
        <jet-label for="name" value="Hero type" />
        <div class="mt-3 border-gray-300 relative rounded-lg grid gap-2 grid-cols-3">
            <button
                v-for="type in types"
                type="button"
                @click="onSelect($event, type.value)"
                :class="{ 'bg-white border-green-500 text-green-500': hero_type === type.value }"
                :key="type.value + '-button'"
                class="relative border border-gray-300 rounded-lg px-3 py-1"
            >
                <div :class="{ 'opacity-50': hero_type !== type.value }" class="flex items-center justify-between flex-1">
                    <div class="text-sm font-semibold text-gray-600" :class="{ 'text-green-500': hero_type === type.value }">
                        {{ type.label }}
                    </div>

                    <icon name="check-circle" class="w-5 h-5 text-green-500 ml-2" v-if="hero_type === type.value" />
                </div>
            </button>
        </div>
    </div>
</template>

<script setup>
import JetLabel from '@/Jetstream/Label.vue'
import Icon from '@/Components/Icon.vue'
import { usePage, router } from '@inertiajs/vue3'
import { ref } from 'vue'

const hero_type = ref(usePage().props.form.hero_type)
const formId = usePage().props.form._id

const types = [
    {
        label: 'Default',
        value: 'NONE',
    },
    {
        label: 'Divided',
        value: 'SPLIT_LEFT',
    },
    // {
    //     label: 'Full',
    //     value: 'FULL',
    // },
]
const onSelect = async ($event, type) => {
    hero_type.value = type

    await router.post(
        route('form.settings.hero.update', { form: formId }),
        {
            action: 'hero_type',
            hero_type: type,
        },
        {
            preserveState: false,
            onStart() {
                $event.target.classList.add('opacity-50', 'cursor-not-allowed')
            },
            onFinish() {
                $event.target.classList.remove('opacity-50', 'cursor-not-allowed')
            },
        }
    )
}
</script>
