<template>
    <dialog-modal :show="show" :max-width="maxWidth">
        <template #title>
            <h3 class="text-2xl font-extrabold leading-tight text-gray-800">Validation rules</h3>
            <p class="text-sm leading-relaxed text-gray-700">Let's pick rules that ensure correct form filling.</p>
        </template>

        <template #content>
            <div class="my-2">
                <jet-input type="text" placeholder="Search for a field rule" v-model="search" class="w-full" autofocus />
            </div>
            <div class="grid grid-cols-3 gap-2 overflow-y-auto max-h-72">
                <div
                    v-for="(rule, index) in filteredList"
                    :key="index"
                    class="col-span-1 p-3 mt-2 transition border border-gray-200 rounded cursor-pointer hover:border-teal-500"
                >
                    <jet-label :for="'rule_' + rule.name">
                        <div class="flex items-center justify-between mb-2">
                            <span class="font-bold">{{ rule.label }}</span>
                            <jet-checkbox :for="'rule_' + rule.name" :id="'rule_' + rule.name" :value="rule" v-model:checked="field.rules" class="hidden" />
                        </div>
                        <jet-field-info>
                            {{ rule.description }}
                        </jet-field-info>
                    </jet-label>
                </div>
            </div>
        </template>

        <template #footer>
            <jet-button class="mr-3" @click.prevent="$emit('close')" type="button">Save</jet-button>
        </template>
    </dialog-modal>
</template>

<script>
import Modal from '@/Jetstream/Modal.vue'
import DialogModal from '@/Jetstream/DialogModal.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetInputError from '@/Jetstream/InputError.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import JetSelect from '@/Jetstream/Select.vue'
import JetCheckbox from '@/Jetstream/Checkbox.vue'
import rules from '@/rules'

export default {
    components: {
        JetLabel,
        JetInput,
        JetInputError,
        JetButton,
        JetSelect,
        JetSecondaryButton,
        DialogModal,
        Modal,
        JetCheckbox,
    },
    props: ['show', 'maxWidth', 'closeable', 'field'],
    data() {
        return {
            rules,
            search: '',
        }
    },
    computed: {
        /**
         * Get field rule names for the currently editing field
         * @return {array} of rule names
         */
        fieldRules() {
            return this.field.rules.concat(this.leftoverRules)
        },

        leftoverRules() {
            const s = this.field.rules.map((r) => r.name)
            const r = this.rules.map((r) => r.name)

            return r
                .filter((x) => !s.includes(x))
                .map((name) => {
                    return this.rules.filter((r) => r.name === name)
                })
                .reduce((acc, a) => acc.concat(a[0]), [])
        },
        filteredList() {
            return this.leftoverRules.filter((rule) => {
                return rule.label.toString().toLowerCase().includes(this.search.toLowerCase())
            })
        },
    },
}
</script>

<style scoped></style>
