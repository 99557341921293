<template>
    <div class="z-50">
        <smooth
            v-show="currentIndex === 0"
            class="absolute top-[110px] sm:top-16 right-1/2 -mr-[150px]"
        >
            <div
                class="inline-flex items-center justify-center p-3 bg-gray-900 rounded-full"
            >
                <div
                    class="px-2 mr-2 text-xs text-gray-800 bg-white rounded-xl"
                >
                    Step 1
                </div>
                <div class="text-xs font-semibold text-white">
                    Add some fields to your form
                </div>
            </div>
        </smooth>
        <smooth
            v-show="currentIndex === 1"
            class="absolute top-[110px] sm:top-16 right-1/2 -mr-[150px]"
        >
            <div
                class="inline-flex items-center justify-center p-3 bg-gray-900 rounded-full"
            >
                <div
                    class="px-2 mr-2 text-xs text-gray-800 bg-white rounded-xl"
                >
                    Step 2
                </div>
                <div class="text-xs font-semibold text-white">
                    Add some validation rules to your fields
                </div>
            </div>
        </smooth>
        <smooth
            v-show="currentIndex === 2"
            class="absolute top-[110px] sm:top-16 right-1/2 -mr-[150px]"
        >
            <div class="flex flex-col items-center">
                <div
                    class="inline-flex items-center justify-center px-5 py-3 bg-gray-900 rounded-full"
                >
                    <!--            <div class="px-2 mr-2 text-xs text-gray-800 bg-white rounded-xl">Step 3</div>-->
                    <div class="text-xs font-semibold text-white">
                        <span class="text-xl leading-none">👏</span> Well done!
                        Let's publish your form.
                    </div>
                </div>
                <button
                    @click="onContinue"
                    class="bg-gray-700 text-gray-300 rounded-b px-2 text-xs pb-0.5 flex items-center"
                >
                    Continue
                    <icon
                        name="arrow-right"
                        class="w-4 h-4 ml-2 text-gray-300"
                    />
                </button>
            </div>
        </smooth>
        <smooth
            v-show="currentIndex === 3"
            class="absolute top-[110px] sm:top-16 right-1/2 -mr-[150px]"
        >
            <div
                class="inline-flex items-center justify-center p-3 bg-gray-900 rounded-full"
            >
                <input
                    type="text"
                    ref="nameInput"
                    class="bg-gray-800 text-white px-3 text-xs outline-none ring-0 border-0 py-1 w-[200px] placeholder-white rounded-full"
                    v-model="fullName"
                    placeholder="Enter your full name"
                    @keyup.enter="handleFullNameEntered"
                />
                <button
                    :disabled="fullName.length < 2"
                    @click="currentIndex = 4"
                    class="flex items-center px-3 py-1 ml-2 mr-2 text-xs text-gray-800 bg-white rounded-full"
                    :class="
                        fullName.length < 2
                            ? 'opacity-50 cursor-not-allowed'
                            : ''
                    "
                >
                    <icon name="enter" class="w-4 h-4" />
                </button>
            </div>
        </smooth>
        <smooth
            v-show="currentIndex === 4"
            class="absolute top-[110px] sm:top-16 right-1/2 -mr-[150px]"
        >
            <div class="flex flex-col items-center">
                <div
                    class="z-50 flex flex-col items-center justify-center p-3 bg-gray-900 rounded-full"
                >
                    <div class="inline-flex items-center">
                        <input
                            type="text"
                            ref="emailInput"
                            class="text-white rounded-full px-3 text-xs outline-none ring-0 border-0 py-1 w-[200px] bg-gray-800 placeholder-white"
                            v-model="email"
                            placeholder="Enter your email address"
                            @keyup.enter="
                                () =>
                                    this.emailValid
                                        ? verifyEmailHandler()
                                        : null
                            "
                        />
                        <button
                            :disabled="!emailValid"
                            @click="verifyEmailHandler"
                            class="flex items-center px-3 py-1 ml-2 mr-2 text-xs text-gray-800 bg-white rounded-full"
                            :class="
                                !emailValid
                                    ? 'opacity-50 cursor-not-allowed'
                                    : ''
                            "
                        >
                            <icon
                                name="loading2"
                                v-if="verifyingEmail"
                                class="w-4 h-4 text-gray-200 animate-spin fill-blue-600"
                            />
                            <icon
                                v-else-if="verified"
                                name="check"
                                class="w-4 h-4 text-green-500"
                            />
                            <icon
                                v-else
                                name="enter"
                                class="w-4 h-4 text-gray-800"
                            />
                        </button>
                    </div>
                </div>

                <transition
                    mode="out-in"
                    v-show="emailError"
                    class="mt-2"
                    enter-active-class="animated fadeInDown"
                    leave-active-class="animated fadeInUp"
                >
                    <div
                        v-if="emailError"
                        class="text-[12px] text-white bg-red-800 rounded-full px-3 py-1 z-10"
                        v-text="emailError"
                    />
                </transition>
            </div>
        </smooth>
        <smooth
            v-show="currentIndex === 5"
            class="absolute top-[110px] sm:top-16 right-1/2 -mr-[150px]"
        >
            <div class="flex flex-col items-center">
                <div
                    class="inline-flex items-center justify-center p-3 bg-gray-900 rounded-full"
                >
                    <input
                        type="password"
                        ref="passwordInput"
                        class="text-white px-3 text-xs outline-none ring-0 border-0 py-1 w-[200px] bg-gray-800 placeholder-white rounded-full"
                        v-model="password"
                        placeholder="Enter a password"
                        @keyup.enter="handleEnterPassword"
                    />
                    <button
                        :disabled="password.length < 8"
                        @click="verifyPassword"
                        class="flex items-center px-3 py-1 ml-2 mr-2 text-xs text-gray-800 bg-white rounded-full"
                        :class="{
                            'opacity-50 cursor-not-allowed':
                                password.length < 8,
                        }"
                    >
                        <icon
                            name="loading2"
                            v-if="verifyingPassword"
                            class="w-4 h-4 text-gray-200 animate-spin fill-blue-600"
                        />
                        <icon
                            v-else-if="passwordValid"
                            name="check"
                            class="w-4 h-4 text-green-500"
                        />
                        <icon
                            v-else
                            @click="createAccount"
                            name="enter"
                            class="w-4 h-4"
                        />
                    </button>
                </div>
                <transition
                    mode="out-in"
                    v-show="passwordError"
                    class="fixed mt-2"
                    enter-active-class="animated fadeInDown"
                    leave-active-class="animated fadeInUp"
                >
                    <div
                        v-if="passwordError"
                        class="text-[12px] text-white bg-red-800 rounded-full px-3 py-1 z-10"
                        v-text="passwordError"
                    />
                </transition>
            </div>
        </smooth>
        <smooth
            v-show="currentIndex === 6"
            class="absolute top-[110px] sm:top-16 right-1/2 -mr-[89px]"
        >
            <div
                class="inline-flex items-center justify-center p-3 bg-gray-900 rounded-full"
            >
                <div
                    class="px-2 mr-2 text-xs text-gray-800 bg-white rounded-xl"
                >
                    Yay!
                </div>
                <div class="text-xs font-semibold text-white">
                    You're all set up.
                </div>
            </div>
        </smooth>
        <smooth
            v-show="currentIndex === 7"
            class="absolute top-[110px] sm:top-16 right-1/2 -mr-[80px] w-[165px]"
        >
            <a
                :href="$route('client-form', { form: $page.props.form })"
                target="_blank"
                class="inline-flex items-center justify-center p-3 font-semibold text-white transition bg-gray-900 rounded-full cursor-pointer hover:bg-gray-800"
            >
                Preview form
            </a>
        </smooth>
    </div>
</template>
<script>
import Icon from '@/Components/Icon.vue'
import Smooth from '@/Components/Smooth.vue'
import { router } from '@inertiajs/vue3'

export default {
    components: { Icon, Smooth },

    props: {
        fields: {
            fields: Array,
            required: true,
            default: [],
        },
    },

    mounted() {
        this.figureOutStepToShow(this.fields)
    },

    data() {
        return {
            currentIndex: 0,
            email: '',
            fullName: '',

            emailValid: false,
            verifyingEmail: false,
            verified: false,
            emailError: null,

            password: '',
            verifyingPassword: false,
            passwordValid: false,
            verifiedPassword: false,
            passwordError: null,
        }
    },
    methods: {
        async createAccount() {
            try {
                const res = await axios.post(route('builder.create-account'), {
                    email: this.email,
                    password: this.password,
                    name: this.fullName,
                    form_id: this.$page.props.form.uuid,
                })
                this.form = res.data.form
                router.reload()
                this.currentIndex = 7
            } catch (err) {
                alert('Something went wrong')
                console.log(err)
            }
        },
        handleEnterPassword($event) {
            if ($event.target.value.length > 7) {
                this.verifyPassword()
            }
        },
        handleFullNameEntered() {
            if (this.fullName.length > 1) {
                this.currentIndex = 4
                this.$nextTick(() => {
                    this.$refs.emailInput.focus()
                })
            }
        },
        onContinue() {
            this.currentIndex = 3
            this.$nextTick(() => {
                this.$refs.nameInput.focus()
            })
        },
        figureOutStepToShow(fields) {
            if (!fields) return
            this.$nextTick(() => {
                if (fields.length === 0) {
                    this.currentIndex = 0
                } else if (fields.length > 0 && fields[0].rules.length === 0) {
                    this.currentIndex = 1
                } else if (fields.length > 0 && fields[0].rules.length > 0) {
                    this.currentIndex = 2
                } else {
                    this.currentIndex = 3
                }
            })
        },
        async verifyEmailHandler() {
            try {
                this.verifyingEmail = true
                await axios.post(
                    route('builder.verify-email', {
                        form: this.$page.props.form?.subdomain,
                        email: this.email,
                    })
                )
                await this.wait(1000)
                this.verifyingEmail = false
                this.currentIndex = 4
                this.verified = true
                this.emailError = null
                this.$nextTick(() => {
                    this.$refs.passwordInput.focus()
                })
            } catch (error) {
                await this.wait(1000)
                this.verifyingEmail = false
                this.verified = false
                this.emailError = 'Looks like this email is already in use'
                await this.wait(3000)
                this.emailError = null
            }
        },

        async wait(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms))
        },

        async verifyPassword() {
            try {
                this.verifyingPassword = true
                await axios.post(
                    route('builder.verify-password', {
                        form: this.$page.props.form?.subdomain,
                        password: this.password,
                    })
                )
                await this.wait(500)
                this.verifyingPassword = false
                this.verifiedPassword = true
                this.passwordError = null
                this.currentIndex = 6
                setTimeout(() => {
                    this.createAccount()
                }, 1000)
            } catch (error) {
                await this.wait(1000)
                this.verifiedPassword = false
                this.verifyingPassword = false
                if (error?.response.status === 422) {
                    console.log(
                        'password error',
                        error.response.data.errors.password
                    )
                    this.passwordError =
                        error?.response?.data?.errors?.password[0]
                } else {
                    this.passwordError =
                        'Oops! Please try again after refreshing the page.'
                }
                await this.wait(3000)
                this.passwordError = null
            }
        },
    },
    watch: {
        verified: function (verified) {
            const self = this
            if (verified) {
                setTimeout(() => {
                    self.currentIndex = 5
                    self.$nextTick(() => {
                        self.$refs.passwordInput.focus()
                    })
                }, 1500)
            }
        },
        email: function (email) {
            const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            this.emailValid = re.test(String(email).toLowerCase())
        },
        fields: function (fields) {
            console.log('watching', fields)
            this.figureOutStepToShow(fields)
        },
    },
}
</script>
<style lang="scss" scoped>
@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 1;
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }
}

.fadeInDown {
    animation-name: fadeInDown;
    animation-duration: 450ms;
}

.fadeInUp {
    animation-name: fadeInUp;
    animation-duration: 450ms;
}

.animated {
    //animation-duration: 350ms;
    animation-fill-mode: both;
}
</style>
