<template>
    <div>
        <div class="p-6 max-w-xl">
            <h3 class="font-bold text-3xl leading-tight">Facebook Pixel</h3>
            <p class="page-desc">
                The Facebook pixel is an analytics tool that allows you to measure the effectiveness of your advertising by understanding the actions people
                take on your website.
            </p>

            <ul class="pl-8 mt-4 list-disc">
                <li class="text-gray-700 text-sm">
                    Go to
                    <a href="http://facebook.com/events_manager" target="_blank" class="text-blue-500">Events Manager</a>.
                </li>
                <li class="text-gray-700 text-sm">Select the pixel you want to set up.</li>
                <li class="text-gray-700 text-sm">Click Continue pixel setup.</li>
                <li class="text-gray-700 text-sm">Select Install code manually.</li>
                <li class="text-gray-700 text-sm">Copy & paste the Pixel ID in the box below.</li>
            </ul>

            <div v-if="integration.on" class="my-4 bg-white max-w-[520px] shadow rounded-lg p-2 font-rounded flex items-center justify-between">
                <div class="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="fill-current text-green-600 mr-2" viewBox="0 0 24 24" width="24" height="24">
                        <path
                            d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-2.3-8.7l1.3 1.29 3.3-3.3a1 1 0 0 1 1.4 1.42l-4 4a1 1 0 0 1-1.4 0l-2-2a1 1 0 0 1 1.4-1.42z"
                        />
                    </svg>
                    <span class="text-sm">Yay! {{ integration.title }} is enabled.</span>
                </div>
                <jet-button @click="update = !update" type="button" class="!p-2"><icon name="pencil" class="w-4 w-4 text-white" /></jet-button>
            </div>
            <form
                v-if="!integration.on || update"
                @submit.prevent="
                    formData.post(
                        route('form.integrations.update', {
                            form,
                            integration: 'facebook-pixel',
                        })
                    )
                "
            >
                <div class="mt-4">
                    <jet-label class="mb-2" for="token" value="Facebook Pixel ID" />
                    <jet-input type="text" v-model="formData.app_key" id="key" class="w-[320px]" placeholder="" />
                    <jet-input-error class="mt-1" :message="formData.errors.app_key" />
                </div>

                <div class="mt-4 flex items-center">
                    <jet-button class="mr-3" :class="{ 'opacity-25': formData.processing }" :disabled="formData.processing">Save </jet-button>
                    <jet-secondary-button type="button" @click="confirmIntegrationDeletion = true">Remove</jet-secondary-button>
                </div>

                <jet-confirmation-modal :show="confirmIntegrationDeletion" @close="confirmIntegrationDeletion = false">
                    <template #title> Delete integration</template>

                    <template #content>
                        Are you sure you want to remove <strong>{{ integration.title }}</strong> integration?</template
                    >

                    <template #footer>
                        <jet-danger-button
                            class="mr-2"
                            @click="removeIntegration"
                            :class="{ 'opacity-25': removeIntegrationForm.processing }"
                            :disabled="removeIntegrationForm.processing"
                        >
                            Yes, remove it
                        </jet-danger-button>
                        <jet-secondary-button @click="confirmIntegrationDeletion = false"> No, back out </jet-secondary-button>
                    </template>
                </jet-confirmation-modal>
            </form>
        </div>
    </div>
</template>
<script>
import ShowFormLayout from '@/Layouts/ShowFormLayout.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetDangerButton from '@/Jetstream/DangerButton.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue'
import JetInputError from '@/Jetstream/InputError.vue'
import AppLayout from '@/Layouts/AppLayout.vue'
import Icon from '@/Components/Icon.vue'
import { useForm } from '@inertiajs/vue3'

export default {
    components: {
        Icon,
        ShowFormLayout,
        JetLabel,
        JetInput,
        JetInputError,
        JetDangerButton,
        JetSecondaryButton,
        JetConfirmationModal,
        JetButton,
    },
    props: ['form', 'integration'],
    layout: [AppLayout, ShowFormLayout],
    data() {
        return {
            formData: useForm({
                _method: 'POST',
                app_key: this.integration.app_key,
            }),
            confirmIntegrationDeletion: false,
            removeIntegrationForm: useForm({}),
            update: false,
        }
    },
    methods: {
        removeIntegration() {
            this.removeIntegrationForm.delete(
                route('form.integrations.destroy', {
                    form: this.$page.props.form._id,
                    integration: this.integration.name,
                }),
                {
                    errorBag: 'deleteIntegration',
                    preserveState: false,
                }
            )
        },
    },
}
</script>
