<template>
    <div class="absolute top-0 left-0 bg-white w-full h-screen overflow-y-scroll items-center flex justify-center z-50">
        <div class="max-w-2xl px-6 flex-1">
            <Link :href="$route('form.settings.mail', { form: formId })"><icon name="arrow-right" class="btn mb-4 h-6 !py-0 px-1 mr-2 -scale-x-100" /></Link>
            <div class="flex flex-col">
                <h3 class="text-3xl font-bold leading-relaxed">Payment confirmation</h3>
                <p>This is the email template that will be sent to the customer once a successful purchase takes place.</p>
            </div>

            <form class="mt-8" @submit.prevent="onSubmit">
                <div class="grid grid-cols-2 gap-5">
                    <div>
                        <div class="flex items-center mb-2">
                            <jet-label for="subject" value="Reply to" class="mb-0" />
                            <info class="ml-2">
                                <p class="text-xs text-body">The email address below will be used as the reply-to address in the payment confirmation email.</p>
                            </info>
                        </div>
                        <jet-input id="replyTo" type="email" class="block w-full mt-1" v-model="mailForm.reply_to" autofocus placeholder="Email address" />
                        <jet-input-error :message="mailForm.errors['reply_to']" class="mt-2" />
                    </div>

                    <div>
                        <jet-label for="contactName" value="Contact name" />
                        <jet-input id="text" type="text" class="block w-full mt-1" v-model="mailForm.contact_name" />
                        <jet-input-error :message="mailForm.errors['contact_name']" class="mt-2" />
                    </div>
                </div>

                <div class="mt-4">
                    <toolbar element="body" />
                    <jet-textarea id="body" rows="12" class="block w-full mt-1" v-model="mailForm.body" />
                    <jet-input-error :message="mailForm.errors['body']" class="mt-2" />
                </div>

                <div class="mt-4">
                    <jet-button class="mr-3" :class="{ 'opacity-25': mailForm.processing }" :disabled="mailForm.processing">Save</jet-button>
                    <jet-secondary-button @click.prevent="mailForm.reset()" type="button">Reset</jet-secondary-button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import ShowFormLayout from '@/Layouts/ShowFormLayout.vue'
export default {
    layout: [AppLayout, ShowFormLayout],
}
</script>
<script setup>
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetTextarea from '@/Jetstream/Textarea.vue'
import JetInputError from '@/Jetstream/InputError.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import Icon from '@/Components/Icon.vue'
import Info from '@/Components/Info.vue'
import '@github/markdown-toolbar-element'
import Toolbar from '@/Components/Toolbar.vue'
import { useForm, usePage, Link } from '@inertiajs/vue3'

const props = defineProps({
    template: Object,
})
const mailForm = useForm({
    _method: 'POST',
    ...Object.assign({}, props.template),
})

const formId = usePage().props.meta.form_uuid

const onSubmit = () => {
    mailForm.post(route('form.settings.mail.template', { form: formId, mailTemplate: props.template.id }))
}
</script>

<style scoped></style>
