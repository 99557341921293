<template>
	<div
		class="text-2xl font-bold leading-tight bg-gradient-to-r from-blue-700 to-blue-500 inline-block text-transparent bg-clip-text tracking-tight"
		v-bind="$attrs"
	>
		pixelform<span
			class="text-sm bg-gradient-to-r from-blue-600 to-blue-400 inline-block text-transparent bg-clip-text"
			>.io</span
		>
	</div>
</template>
