<template>
    <div class="flex items-start h-screen overflow-y-auto">
        <Link
            :href="$route('forms.inbox', { form: form._id })"
            as="button"
            class="fixed z-50 flex items-center btn top-10 right-8"
            v-if="$page.props.auth.user"
        >
            <icon name="arrow-right" class="w-4 h-4" />
            <span class="ml-2">Go to dashboard</span>
        </Link>

        <div class="items-center w-full">
            <fun-bg />
            <div class="flex flex-1">
                <onboarding
                    :fields="formData.fields"
                    v-if="!$page.props.auth.user"
                    class="hidden sm:block"
                />

                <div
                    class="flex-1 flex relative top-[250px] sm:top-[250px] items-center justify-center mx-auto"
                >
                    <div class="relative form-container">
                        <div
                            class="form-wrapper"
                            :class="{ formWrapperShadow }"
                        >
                            <div class="space-y-4">
                                <form-builder
                                    :form="form"
                                    @update="updateFormData"
                                    ref="formBuilder"
                                />
                                <pf-button
                                    type="submit"
                                    class="py-4 block w-full justify-center opacity-50 cursor-not-allowed"
                                    disabled
                                >
                                    Submit
                                </pf-button>
                            </div>
                        </div>
                        <FormPoweredBy />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import pfButton from '@/Pixelform/Button.vue'
import FunBg from '@/Components/FunBg.vue'
import FormBuilder from '@/Components/FormBuilder.vue'
import Onboarding from '@/Components/Onboarding.vue'
import Icon from '@/Components/Icon.vue'
import { Link } from '@inertiajs/vue3'
import FormPoweredBy from '@/Pixelform/Client/FormPoweredBy.vue'
import { computed, reactive } from 'vue'

const props = defineProps({
    form: {
        type: Object,
        required: true,
    },
})

const formData = reactive(JSON.parse(JSON.stringify(props.form)))
const updateFormData = (data) => Object.assign(formData, data)

const formWrapperShadow = computed(() => {
    return {
        '!shadow-none': ['SPLIT_LEFT', 'SPLIT_RIGHT'].includes(
            props.form.hero_type
        ),
    }
})
</script>
