<template>
    <div>
        <div class="max-w-4xl p-3 lg:p-6">
            <h3 class="page-title">Code Injection</h3>
            <p class="page-desc">Code will be placed into the 
                <span class="btn-shadow text-xs py-0.5 px-1 rounded-md">
                    {{ '<head>' }}
                </span> of the page that hosts this form.</p>


            <ul class="pl-2 my-5 space-y-3 max-w-xl">
                <li class="flex items-center group space-x-4">
                    <div class="btn-shadow rounded-lg p-0.5">
                        <div class="p-1 bg-blue-100 rounded-lg group-hover:bg-blue-500 group-hover:shadow transition">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor"
                                class="w-5 h-5 text-blue-500 flex-shrink-0 group-hover:text-white transition">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v2.25A2.25 2.25 0 0 0 6 10.5Zm0 9.75h2.25A2.25 2.25 0 0 0 10.5 18v-2.25a2.25 2.25 0 0 0-2.25-2.25H6a2.25 2.25 0 0 0-2.25 2.25V18A2.25 2.25 0 0 0 6 20.25Zm9.75-9.75H18a2.25 2.25 0 0 0 2.25-2.25V6A2.25 2.25 0 0 0 18 3.75h-2.25A2.25 2.25 0 0 0 13.5 6v2.25a2.25 2.25 0 0 0 2.25 2.25Z" />
                            </svg>
                        </div>
                    </div>

                    <div>
                        <p class="text-sm text-gray-800 font-semibold">Widgets</p>
                        <p class="text-xs text-gray-700">Connect your form with services like Drift, Intercom, Buy me a
                            Coffee</p>
                    </div>
                </li>
                <li class="flex items-center group space-x-4">
                    <div class="btn-shadow rounded-lg p-0.5">
                        <div
                            class="p-1 bg-orange-100 rounded-lg group-hover:bg-orange-500 group-hover:shadow transition">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor"
                                class="w-5 h-5 text-orange-500 flex-shrink-0 group-hover:text-white transition">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M10.5 6a7.5 7.5 0 1 0 7.5 7.5h-7.5V6Z" />
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M13.5 10.5H21A7.5 7.5 0 0 0 13.5 3v7.5Z" />
                            </svg>
                        </div>
                    </div>
                    <div>
                        <p class="text-sm text-gray-800 font-semibold">Analytics</p>
                        <p class="text-xs text-gray-700">Add Google Analytics, Facebook Pixel, or any other analytics
                            code to track your form's performance</p>
                    </div>
                </li>
                <li class="flex items-center group space-x-4">
                    <div class="btn-shadow rounded-lg p-0.5">
                        <div class="p-1 bg-green-100 rounded-lg group-hover:bg-green-500 group-hover:shadow transition">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor"
                                class="w-5 h-5 text-green-500 flex-shrink-0 group-hover:text-white transition">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M9.53 16.122a3 3 0 0 0-5.78 1.128 2.25 2.25 0 0 1-2.4 2.245 4.5 4.5 0 0 0 8.4-2.245c0-.399-.078-.78-.22-1.128Zm0 0a15.998 15.998 0 0 0 3.388-1.62m-5.043-.025a15.994 15.994 0 0 1 1.622-3.395m3.42 3.42a15.995 15.995 0 0 0 4.764-4.648l3.876-5.814a1.151 1.151 0 0 0-1.597-1.597L14.146 6.32a15.996 15.996 0 0 0-4.649 4.763m3.42 3.42a6.776 6.776 0 0 0-3.42-3.42" />
                            </svg>
                        </div>
                    </div>

                    <div>
                        <p class="text-sm text-gray-800 font-semibold">Styling</p>
                        <p class="text-xs text-gray-700">
                            Further style your form with custom CSS. You could use your own font for example. Coding
                            skills required.
                        </p>
                    </div>
                </li>
            </ul>

            <form @submit.prevent="submit">
                <div class="mt-4">
                    <code-editor :wrap="false" font-size="14px" width="100%" theme="github" :header="true"
                        :line-nums="true" :languages="[['HTML']]" v-model="formData.custom_code" />
                    <jet-input-error :message="formData.errors['custom_code']" class="mt-2" />
                </div>

                <div class="mt-4">
                    <jet-button class="mr-3" :class="{ 'opacity-25': formData.processing }"
                        :disabled="formData.processing">Save</jet-button>
                    <jet-secondary-button @click.prevent="formData.reset()" type="button">Cancel</jet-secondary-button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import ShowFormLayout from '@/Layouts/ShowFormLayout.vue'
import { defineAsyncComponent } from 'vue'

export default {
    layout: [AppLayout, ShowFormLayout],
}
</script>

<script setup>
import { defineAsyncComponent } from 'vue'
import JetInputError from '@/Jetstream/InputError.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import { useForm, usePage } from '@inertiajs/vue3'

const CodeEditor = defineAsyncComponent(() => import('@/Components/SimpleCodeEditor/CodeEditor.vue'))

const formId = usePage().props.meta.form_uuid
const props = defineProps({
    custom_code: [String, null],
})

const formData = useForm({
    _method: "PUT",
    custom_code: props.custom_code,
})

const submit = () => {
    formData.put(route('form.settings.code-injection.update', { form: formId }), {
        preserveState: false,
    })
}
</script>

<style scoped></style>
