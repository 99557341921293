<template>
    <div class="h-screen pb-32 overflow-y-auto no-scrollbar">
        <div class="flex items-center justify-between px-3 sm:px-6 mt-5">
            <div class="max-w-lg">
                <h3 class="page-title">Inbox</h3>
                <p class="page-desc max-w-2lg">Overview and management of all form entries.</p>
            </div>
        </div>

        <!-- <div class="p-6">
            <inbox-chart :data="chart" class="mb-5" />
            <inbox-stats :stats="stats" />
        </div> -->
        <div v-if="noEntries" class="px-6 mx-6 mt-5 border rounded-lg">
            <div class="p-3 text-center">
                <img src="/images/no-data.svg" class="max-w-sm mx-auto" alt="No data illustration" />
            </div>
        </div>
        <inbox-table v-else class="p-3 sm:p-6 mt-2" :entries="form.entries" :columns="form.columns" :timezone="form.timezone" />
    </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import ShowFormLayout from '@/Layouts/ShowFormLayout.vue'
export default {
    layout: [AppLayout, ShowFormLayout],
}
</script>

<script setup>
import InboxStats from '@/Components/InboxStats.vue'
import InboxTable from '@/Components/InboxTable.vue'
import InboxChart from '@/Components/InboxChart.vue'

const props = defineProps({
    form: Object,
    stats: Object,
    chart: Object,
})

const noEntries = props.form.entries.data.length === 0
</script>
