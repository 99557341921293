<template>
    <div>
        <div class="max-w-lg p-3 lg:p-6">
            <h3 class="page-title">API Token</h3>
            <p class="page-desc">
                All submissions to this form need to contain the
                <strong>token</strong> field attached in the headers of the HTTP request.
            </p>

            <form @submit.prevent="onSubmit">
                <div class="mt-4">
                    <jet-label class="mb-2" for="token" value="Token value" />
                    <span class="px-2 py-1 bg-gray-200 rounded font-xs">{{ form.token }}</span>
                </div>

                <div class="mt-4">
                    <jet-button class="mr-3" :class="{ 'opacity-25': formData.processing }" :disabled="formData.processing">Regenerate</jet-button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import ShowFormLayout from '@/Layouts/ShowFormLayout.vue'

export default {
    layout: [AppLayout, ShowFormLayout],
}
</script>

<script setup>
import JetLabel from '@/Jetstream/Label.vue'
import JetButton from '@/Jetstream/Button.vue'
import { useForm } from '@inertiajs/vue3'

const props = defineProps({
    form: Object,
})

const formData = useForm({
    _method: 'PUT',
})

const onSubmit = () => {
    const confirmed = confirm('Are you sure you want to regenerate the token?')

    if (!confirmed) {
        return false
    }

    formData.put(route('form.settings.api-token.update', { form: props.form._id }))
}
</script>
