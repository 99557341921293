<template>
    <div class="p-3 border border-gray-200 rounded-lg bg-slate-50">
        <p class="text-xs text-gray-600">Receive payments directly on your bank account with the help of Stripe.</p>

        <p class="mt-2 text-xs text-gray-600">Non premium users collect payments with the following costs:</p>
        <ul class="pl-4 mt-1 space-y-1 text-xs text-gray-600 list-disc">
            <li>a 5% Pixelform commission</li>
            <li>Stripe transaction fees</li>
        </ul>
        <p v-if="!$page.props?.current_plan_name" class="mt-2 text-xs text-gray-600">
            To remove the 5% Pixelform commission on payments,
            <button @click="router.visit($route('billing.index'))" class="text-blue-500 underline underline-offset-2">upgrade Pixelform</button>.
        </p>
    </div>
    <div v-if="loadingAvailableAccounts" class="flex justify-center">
        <icon name="loading" class="w-4 h-4" />
    </div>
    <div v-else>
        <div v-if="!noConnectedAccounts" class="grid grid-cols-2 gap-3 mt-2">
            <div class="flex flex-col">
                <div class="flex items-center mb-1">
                    <jet-label :small="true" for="label" class="mb-0">Price</jet-label>
                    <info class="ml-1">
                        <p class="text-xs text-body">The price must be expressed in {{ currentField.currency === 'GBP' ? 'pennies' : 'cents' }}.</p>
                    </info>
                </div>
                <input
                    type="number"
                    id="price"
                    v-model="currentField.price"
                    class="border rounded fe-input border-stackborder focus:placeholder-gray-300 focus:ring-4 focus:border-blue-400 focus:ring-blue-200 focus:ring-opacity-50 text-input"
                />
                <jet-input-error v-if="hasError('price')" :message="getError('price')" class="mt-1" />
            </div>
            <div class="flex flex-col">
                <jet-label :small="true" for="label">Currency</jet-label>
                <jet-select
                    id="currency"
                    v-model="currentField.currency"
                    class="border rounded fe-input border-stackborder focus:placeholder-gray-300 focus:ring-4 focus:border-blue-400 focus:ring-blue-200 focus:ring-opacity-50 text-input"
                >
                    <template #options>
                        <option v-for="currency in currencies" :key="currency.value" :value="currency.value">{{ currency.label }}</option>
                    </template>
                </jet-select>
                <jet-input-error v-if="hasError('currency')" :message="getError('currency')" class="mt-1" />
            </div>

            <div class="flex flex-col">
                <jet-label :disabled="loadingAvailableAccounts" :small="true" for="label">Stripe Account</jet-label>
                <jet-select
                    v-if="(changeAccount || (!accountId && !selectedAccountName)) && !accountIdUpdateMessage"
                    id="currency"
                    :disabled="loadingAvailableAccounts"
                    v-model="accountId"
                    @change="changeAccountId"
                    class="border rounded fe-input border-stackborder focus:placeholder-gray-300 focus:ring-4 focus:border-blue-400 focus:ring-blue-200 focus:ring-opacity-50 text-input"
                >
                    <template #options>
                        <option value="">None</option>
                        <option
                            :disabled="!account.details_submitted || !account.charges_enabled || !account.payouts_enabled"
                            v-for="account in availableAccounts"
                            :key="account.id"
                            :value="account.stripe_account_id"
                        >
                            {{ account.business_name }}
                        </option>
                    </template>
                </jet-select>
                <div v-else class="flex items-center text-xs h-[28px]">
                    <icon
                        :name="accountIdUpdateMessage ? 'check' : 'briefcase'"
                        :class="{
                            'text-green-700': accountIdUpdateMessage,
                            'text-slate-500': !accountIdUpdateMessage,
                        }"
                        class="w-4 h-4 mr-1"
                    />
                    <button @click="onChangeAccount" class="cursor-pointer text-slate-500 hover:underline">
                        {{ selectedAccountName }}
                    </button>
                </div>
            </div>

            <div class="flex flex-col">
                <jet-label :small="true" for="label">Payment Confirmation Email</jet-label>
                <icon name="loading" class="w-4 h-4" v-if="loadingMailTemplateStatus" />
                <div v-else>
                    <p v-if="mailTemplateStatusError" class="text-xs text-red-500">{{ mailTemplateStatusError }}</p>
                    <div v-else-if="mailTemplateEnabled" class="flex items-center text-xs h-[28px]">
                        <icon name="check" class="w-4 h-4 mr-1 text-green-700" />
                        <span class="text-slate-500">Setup completed</span>
                    </div>
                    <Link
                        v-else
                        class="flex items-center text-xs text-blue-500 underline underline-offset-4 h-[28px]"
                        :href="$route('form.settings.mail', { form: formId })"
                    >
                        <icon name="error" class="w-4 h-4 mr-1 text-red-500" />
                        <span>Complete setup</span>
                    </Link>
                </div>
            </div>
        </div>
        <Link v-if="noConnectedAccounts" as="button" :href="$route('teams.show', { team: teamId })" class="!py-3 text-center btn !w-full">
            Connect Stripe Account
        </Link>
    </div>
</template>
<script setup>
import JetLabel from '@/Jetstream/Label.vue'
import JetSelect from '@/Jetstream/Select.vue'
import JetInputError from '@/Components/InputError.vue'
import Info from '@/Components/Info.vue'
import Icon from '@/Components/Icon.vue'
import Input from '@/Components/Input.vue'
import axios from 'axios'
import { ref, computed, defineProps, onMounted } from 'vue'
import { usePage, Link, router } from '@inertiajs/vue3'

const props = defineProps(['currentField'])

const teamId = usePage().props.auth.user?.current_team?.id
const { stripe_account_id, _id: formId } = usePage().props.form

const currencies = [
    {
        value: 'AED',
        label: 'AED — UAE Dirham',
    },
    {
        value: 'AUD',
        label: 'AUD — Australian Dollar',
    },
    {
        value: 'BGN',
        label: 'BGN — Bulgarian lev',
    },
    {
        value: 'BRL',
        label: 'BRL — Brazilian real',
    },
    {
        value: 'CAD',
        label: 'CAD — Canadian dollar',
    },
    {
        value: 'CHF',
        label: 'CHF — Swiss franc',
    },
    {
        value: 'CNY',
        label: 'CNY — Yuan Renminbi',
    },
    {
        value: 'CZK',
        label: 'CZK — Czech Koruna',
    },
    {
        value: 'DKK',
        label: 'DKK — Danish Krone',
    },
    {
        value: 'EUR',
        label: 'EUR — Euro',
    },
    {
        value: 'GBP',
        label: 'GBP — Pound sterling',
    },
    {
        value: 'HKD',
        label: 'HKD — Hong Kong dollar',
    },
    {
        value: 'HRK',
        label: 'HRK — Croatian kuna',
    },
    {
        value: 'HUF',
        label: 'HUF — Hungarian forint',
    },
    {
        value: 'IDR',
        label: 'IDR — Indonesian Rupiah',
    },
    {
        value: 'ILS',
        label: 'ILS — Israeli Shekel',
    },
    {
        value: 'INR',
        label: 'INR — Indian Rupee',
    },
    {
        value: 'ISK',
        label: 'ISK — Icelandic króna',
    },
    {
        value: 'JPY',
        label: 'JPY — Japanese yen',
    },
    {
        value: 'KRW',
        label: 'KRW — South Korean won',
    },
    {
        value: 'MAD',
        label: 'MAD — Moroccan Dirham',
    },
    {
        value: 'MXN',
        label: 'MXN — Mexican peso',
    },
    {
        value: 'MYR',
        label: 'MYR — Malaysian ringgit',
    },
    {
        value: 'NOK',
        label: 'NOK — Norwegian krone',
    },
    {
        value: 'NZD',
        label: 'NZD — New Zealand dollar',
    },
    {
        value: 'PHP',
        label: 'PHP — Philippine peso',
    },
    {
        value: 'PLN',
        label: 'PLN — Polish złoty',
    },
    {
        value: 'RON',
        label: 'RON — Romanian leu',
    },
    {
        value: 'RSD',
        label: 'RSD — Serbian dinar',
    },
    {
        value: 'RUB',
        label: 'RUB — Russian Rouble',
    },
    {
        value: 'SAR',
        label: 'SAR — Saudi riyal',
    },
    {
        value: 'SEK',
        label: 'SEK — Swedish krona',
    },
    {
        value: 'SGD',
        label: 'SGD — Singapore dollar',
    },
    {
        value: 'THB',
        label: 'THB — Thai baht',
    },
    {
        value: 'TWD',
        label: 'TWD — New Taiwan dollar',
    },
    {
        value: 'UAH',
        label: 'UAH — Ukrainian hryvnia',
    },
    {
        value: 'USD',
        label: 'USD — United States Dollar',
    },
    {
        value: 'VND',
        label: 'VND — Vietnamese dong',
    },
    {
        value: 'ZAR',
        label: 'ZAR — South African rand',
    },
]

const accountId = ref(null)
const changeAccount = ref(false)
const accountIdUpdateMessage = ref(null)
const availableAccounts = ref([])
const loadingAvailableAccounts = ref(true)

// Mail template
const mailTemplateEnabled = ref(false)
const mailTemplateStatusError = ref(null)
const loadingMailTemplateStatus = ref(true)

const onChangeAccount = () => {
    changeAccount.value = true
    accountIdUpdateMessage.value = null
}

onMounted(() => {
    accountId.value = stripe_account_id

    fetchPaymentConfirmationTemplateStatus()
})

const fetchConnectAccounts = async () => {
    const { data } = await axios.get(route('connect-accounts.index', { team: teamId }))

    availableAccounts.value = data.accounts
    loadingAvailableAccounts.value = false
}

const fetchPaymentConfirmationTemplateStatus = async () => {
    try {
        const { data } = await axios.get(route('form.settings.mail.payment-confirmation-status', { form: formId }))
        mailTemplateStatusError.value = null
        mailTemplateEnabled.value = data?.enabled
    } catch (err) {
        console.error(err)
        mailTemplateStatusError.value = 'Error occurred while fetching data.'
    } finally {
        loadingMailTemplateStatus.value = false
    }
}

const changeAccountId = async () => {
    try {
        const res = await axios.patch(route('forms.update-stripe-account-id', { form: formId }), {
            stripe_account_id: accountId.value,
        })

        if (accountId.value === '') {
            usePage().props.form.stripe_account_id = null
            accountIdUpdateMessage.value = null
        } else {
            usePage().props.form.stripe_account_id = accountId.value
            accountIdUpdateMessage.value = res.data.message
        }
    } catch (error) {
        console.error(error)
    }
}

fetchConnectAccounts()

const hasError = (field) => {
    return props?.currentField?.errors?.hasOwnProperty(field)
}

const getError = (field) => {
    if (!props?.currentField?.errors) {
        return false
    }

    return props?.currentField?.errors[field][0]
}

const noConnectedAccounts = computed(() => {
    return (
        availableAccounts.value.filter((account) => {
            return account.details_submitted && account.charges_enabled && account.payouts_enabled
        }).length === 0
    )
})

const selectedAccountName = computed(() => {
    return availableAccounts.value.find((account) => account.stripe_account_id === stripe_account_id || accountId.value)?.business_name
})
</script>
