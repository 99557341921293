<template>
    <div class="w-full max-w-sm p-4 border rounded-md shadow">
        <div class="flex space-x-4 animate-pulse">
            <div class="flex-1 py-1 space-y-6">
                <div class="h-2 rounded bg-slate-200"></div>
                <div class="space-y-3">
                    <div class="grid grid-cols-3 gap-4">
                        <div class="h-2 col-span-2 rounded bg-slate-200"></div>
                        <div class="h-2 col-span-1 rounded bg-slate-200"></div>
                    </div>
                    <div class="h-2 rounded bg-slate-200"></div>
                </div>
            </div>
        </div>
    </div>
</template>