<template>
    <a :href="$route('home')" class="flex items-center flex-col space-y-2">
        <img src="/images/logo.svg" class="w-5" />
    </a>
</template>
<script>
import Icon from '@/Components/Icon.vue'
import { Link } from '@inertiajs/vue3'

export default {
    components: {
        Icon,
        Link,
    },
}
</script>
