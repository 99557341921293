<template>
    <div style="height: 290px; width: 400px">
        <div class="tooltip-header flex items-center justify-between rounded-t-sm">
            <span class="fe-label !mb-0">Edit row</span>
            <button
                type="button"
                @click="onDone"
                class="btn ml-2 py-1 px-2 rounded !text-white bg-blue-600 border-blue-700 focus:outline-none focus:ring focus:ring-blue-300 !shadow-none disabled:opacity-30"
                :disabled="!canSave"
            >
                Done
            </button>
        </div>

        <div class="p-2 space-y-2 overflow-scroll h-[250px]">
            <div v-for="(el, key) of Object.keys(selectInputs)" :key="key" class="flex items-center">
                <button class="btn !px-1 mr-1" @click="removeField(el)" type="button"><icon name="trash" class="w-5 h-5" /></button>
                <button class="btn !px-1 mr-1" @click="$emit('changeCurrentField', this.currentField.children[key])" type="button">
                    <icon name="pencil" class="w-5 h-5" />
                </button>
                <jet-select v-model="selectInputs[el]" class="text-xs py-1 !shadow-sm hover:shadow flex-1">
                    <template #options>
                        <option value="" disabled selected>Choose field</option>
                        <option v-for="field in $page.props.form.fields" :key="field._id" :value="field._id">{{ field.label }}</option>
                    </template>
                </jet-select>
            </div>
            <button type="button" @click="addField" class="btn !px-1" :class="{ 'opacity-25': cantAddFields }" :disabled="cantAddFields">
                <icon name="plus" class="w-5 h-5" />
            </button>
        </div>
    </div>
</template>
<script>
import { reactive, toRefs, computed } from '@vue/reactivity'
import JetSelect from '@/Jetstream/Select.vue'
import { usePage } from '@inertiajs/vue3'
import Icon from '@/Components/Icon.vue'

export default {
    props: ['currentField', 'changeCurrentField'],

    components: {
        JetSelect,
        Icon,
    },

    emits: ['done', 'changeCurrentField'],

    setup(props, { emit }) {
        let selectInputs = reactive({})
        const fields = usePage().props.form?.fields
        let totalFields = fields?.length

        const { currentField } = toRefs(props)

        currentField.value.children.forEach((f, index) => {
            selectInputs['select' + index] = f._id
        })

        const canSave = computed(() => {
            const minFields = Object.keys(selectInputs).length > 1
            if (minFields && minFields <= totalFields) {
                return true
            }

            return false
        })

        const addField = () => {
            let id = Object.keys(selectInputs).length
            selectInputs['select' + id] = null
        }

        const removeField = (el) => {
            delete selectInputs[el]
        }

        const cantAddFields = computed(() => Object.keys(selectInputs).length >= totalFields)

        function onDone() {
            const ids = Object.values(this.selectInputs)

            // this.currentField.children = fields.
            emit('done')
        }

        return {
            selectInputs,
            addField,
            cantAddFields,
            removeField,
            canSave,
            onDone,
        }
    },
}
</script>
