<template>
    <smooth>
        <div class="flex flex-col">
            <div class="flex items-center space-x-1">
                <jet-label class="mb-0" for="backgroundColor" value="Background colour" />
                <info>
                    <p class="text-xs text-body">
                        Setting a background colour will override the background image. If you want to remove the background colour,
                    </p>
                </info>
            </div>
            <div class="flex space-x-2 items-center mt-3 ml-[22px]">
                <div
                    v-for="color in colors"
                    :key="color"
                    @click="selectColor($event, color === currentColor ? null : color)"
                    class="cursor-pointer w-6 h-6 rounded-lg flex items-center justify-center"
                    :style="{ backgroundColor: color }"
                >
                    <icon name="check" class="w-4 h-4 text-white" v-if="currentColor === color" />
                </div>
                <!-- <div class="w-6 h-6 rounded-lg bg-red-600"></div>
                            <div class="w-6 h-6 rounded-lg bg-indigo-600"></div>
                            <div class="w-6 h-6 rounded-lg bg-teal-600"></div>
                            <div class="w-6 h-6 rounded-lg bg-emerald-600"></div>
                            <div class="w-6 h-6 rounded-lg bg-lime-600"></div>
                            <div class="w-6 h-6 rounded-lg bg-slate-600"></div>
                            <div class="w-6 h-6 rounded-lg bg-emeral-600"></div> -->
                <div class="flex items-center space-x-2">
                    <jet-label for="color" value="Custom" class="mb-0" />
                    <jet-input
                        @keyup.enter="selectColor($event, $event.target.value)"
                        type="text"
                        placeholder="#ff0000"
                        class="tracking-widest max-w-[90px] px-2"
                        v-model="currentColor"
                    />
                    <button type="button" @click.prevent="selectColor($event, null)" tooltip="Remove background colour?">
                        <icon name="trash" class="w-4 h-4" />
                    </button>
                </div>
            </div>
        </div>
    </smooth>
</template>

<script setup>
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'
import Info from '@/Components/Info.vue'
import Smooth from '@/Components/Smooth.vue'
import Icon from '@/Components/Icon.vue'
import { ref } from 'vue'
import { usePage, router } from '@inertiajs/vue3'

const colors = ['#16a34a', '#2563eb', '#9333ea', '#283540']
const formId = usePage().props.form._id
const currentColor = ref(usePage().props.form.hero_bg_solid_color)

const selectColor = async ($event, color) => {
    let selection = color

    await router.post(
        route('form.settings.hero.update', { form: formId }),
        {
            action: 'hero_bg_color',
            color: selection,
        },
        {
            preserveState: false,
            onStart() {
                $event.target.classList.add('opacity-50', 'cursor-not-allowed')
            },
            onFinish() {
                $event.target.classList.remove('opacity-50', 'cursor-not-allowed')
            },
        }
    )
}
</script>
