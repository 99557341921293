<template>
    <div>
        <button :class="classes" class="w-full text-left" v-if="as == 'button'">
            <slot></slot>
        </button>

        <Link :href="href" :class="classes" v-else>
            <slot></slot>
        </Link>
    </div>
</template>

<script>
import { Link } from '@inertiajs/vue3'

export default {
    props: ['active', 'href', 'as'],
    components: {
        Link,
    },
    computed: {
        classes() {
            return this.active
                ? 'block p-3 text-sm font-medium text-indigo-700 bg-white focus:outline-none focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700 transition'
                : 'block p-3 border-transparent text-sm font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition'
        },
    },
}
</script>
