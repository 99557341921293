<template>
    <modal :show="show" :max-width="maxWidth" :closeable="closeable" @close="close">
        <div class="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
                <div
                    :class="{
                        'bg-red-100': iconColor === 'red',
                        'bg-green-100': iconColor === 'green',
                        'bg-blue-100': iconColor === 'blue',
                        'bg-yellow-100': iconColor === 'yellow',
                        'bg-indigo-100': iconColor === 'indigo',
                        'bg-purple-100': iconColor === 'purple',
                        'bg-pink-100': iconColor === 'pink',
                    }"
                    class="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto rounded-full sm:mx-0 sm:h-10 sm:w-10"
                >
                    <svg v-if="!iconName" class="w-6 h-6 text-red-600" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        />
                    </svg>
                    <icon
                        v-else-if="iconName"
                        :name="iconName"
                        class="w-6 h-6"
                        :class="{
                            'text-red-600': iconColor === 'red',
                            'text-green-600': iconColor === 'green',
                            'text-blue-600': iconColor === 'blue',
                            'text-yellow-600': iconColor === 'yellow',
                            'text-indigo-600': iconColor === 'indigo',
                            'text-purple-600': iconColor === 'purple',
                            'text-pink-600': iconColor === 'pink',
                        }"
                    />
                </div>

                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 class="text-lg">
                        <slot name="title"></slot>
                    </h3>

                    <div class="mt-2">
                        <slot name="content"></slot>
                    </div>
                </div>
            </div>
        </div>

        <div class="px-6 py-4 text-right bg-gray-100">
            <slot name="footer"> </slot>
        </div>
    </modal>
</template>

<script>
import Modal from './Modal.vue'
import Icon from '@/Components/Icon.vue'

export default {
    emits: ['close'],

    components: {
        Modal,
        Icon,
    },

    props: {
        show: {
            default: false,
        },
        maxWidth: {
            default: '2xl',
        },
        closeable: {
            default: true,
        },
        iconColor: {
            type: String,
            default: 'red',
            required: false,
        },
        iconName: {
            type: String,
            required: false,
        },
    },

    methods: {
        close() {
            this.$emit('close')
        },
    },
}
</script>
