<template>
    <div class="bg-white border rounded field-settings-popup-wrapper">
        <div v-if="currentField.name !== 'row'">
            <!-- Settings tab bg-[#eef2f5] -->
            <div
                class="px-3 w-full lg:px-10 pt-6 lg:pt-14 pb-5 lg:pb-10 overflow-y-scroll no-scrollbar space-y-3 lg:space-y-7 h-[80vh]"
            >
                <div class="flex flex-col">
                    <div class="flex items-center">
                        <jet-label :small="true" for="label">{{
                            isPaymentField ? 'Product name' : 'Field label'
                        }}</jet-label>
                        <info class="ml-1">
                            <p
                                v-if="$page.props.meta.form_type === 'FORM'"
                                class="text-xs text-body"
                            >
                                This is what users of your form will see.
                            </p>
                        </info>
                    </div>
                    <input
                        type="text"
                        id="label"
                        v-model="currentField.label"
                        class="border rounded fe-input border-stackborder focus:placeholder-gray-300 focus:ring-4 focus:border-blue-400 focus:ring-blue-200 focus:ring-opacity-50 text-input"
                    />
                    <jet-input-error
                        v-if="hasError('label')"
                        :message="getError('label')"
                        class="mt-1"
                    />
                </div>

                <div
                    v-if="currentField.type !== 'payment'"
                    class="flex flex-col"
                >
                    <jet-label :small="true" for="name">Field name</jet-label>
                    <input
                        type="text"
                        id="name"
                        v-model="currentField.name"
                        class="border rounded fe-input border-stackborder focus:placeholder-gray-300 focus:ring-4 focus:border-blue-400 focus:ring-blue-200 focus:ring-opacity-50 text-input"
                    />
                    <jet-input-error
                        v-if="hasError('name')"
                        :message="getError('name')"
                        class="mt-1"
                    />
                </div>
                <div
                    v-if="$route().current() === 'fields.index'"
                    class="flex flex-col col-span-full"
                >
                    <jet-label for="type" value="Field type" />
                    <jet-select
                        id="type"
                        class="block w-full"
                        v-model="currentField.type"
                    >
                        <template #options>
                            <option value="" disabled selected>
                                Pick field type
                            </option>
                            <option
                                v-for="(type, k) in inputTypes"
                                :value="type"
                                :key="k"
                            >
                                {{ type }}
                            </option>
                        </template>
                    </jet-select>
                    <jet-input-error
                        v-if="hasError('type')"
                        :message="getError('type')"
                        class="mt-1"
                    />
                </div>

                <div v-if="false" class="flex flex-col">
                    <jet-label :small="true" for="cssClasses"
                        >CSS Classes</jet-label
                    >
                    <input
                        type="text"
                        id="cssClasses"
                        v-model="currentField.classNames"
                        class="border rounded fe-input border-stackborder focus:placeholder-gray-300 focus:ring-4 focus:border-blue-400 focus:ring-blue-200 focus:ring-opacity-50 text-input"
                    />
                </div>

                <payments-required-field
                    v-if="currentField.type === 'payment'"
                    :current-field="currentField"
                />

                <div v-if="currentField.type === 'payment'">
                    <payment-field-image
                        @done="onProductImageChange"
                        :field="currentField"
                    />
                </div>

                <div class="flex flex-col col-span-full relative z-0">
                    <jet-label :small="true" for="fieldDescription">{{
                        isPaymentField ? 'Description' : 'Field description'
                    }}</jet-label>
                    <textarea
                        id="fieldDescription"
                        v-model="currentField.helpBlock"
                        rows="4"
                        class="fieldDescriptionInput px-2 text-xs !h-auto border border-stackborder focus:placeholder-gray-300 focus:ring-4 focus:border-blue-400 focus:ring-blue-200 focus:ring-opacity-50 text-input bg-white z-50 rounded"
                    ></textarea>
                    <jet-input-error
                        v-if="currentField.errors?.helpBlock?.length"
                        :message="currentField.errors.helpBlock[0]"
                        class="mt-1"
                    />
                </div>

                <div
                    v-if="
                        currentField.type === 'select' ||
                        currentField.type === 'radio'
                    "
                    class="flex flex-col mt-2 col-span-full"
                >
                    <div class="flex items-center mb-2 space-x-5">
                        <jet-label
                            :small="true"
                            value="Field options"
                            class="mb-0"
                        />
                        <button
                            type="button"
                            class="flex btn"
                            @click="addOption"
                        >
                            <PlusSmIcon class="w-4 h-4" />
                        </button>
                    </div>

                    <div class="flex flex-col mt-2">
                        <Disclosure
                            :default-open="true"
                            v-slot="{ open }"
                            v-for="(option, index) in fieldOptions"
                            :key="index"
                            as="div"
                        >
                            <DisclosureButton
                                class="flex items-center w-full mb-4 text-sm font-medium text-left focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
                            >
                                <ChevronUpIcon
                                    :class="open ? 'rotate-180' : 'rotate-90'"
                                    class="w-4 h-4 transform text-slate-500"
                                />
                                <h3
                                    class="ml-2 text-xs font-semibold text-gray-600"
                                >
                                    Option {{ index + 1 }}
                                </h3>
                            </DisclosureButton>
                            <DisclosurePanel
                                class="px-2 space-y-6 text-sm text-gray-500"
                                as="div"
                            >
                                <div class="flex gap-6">
                                    <div class="flex-1">
                                        <div class="flex items-center mb-2">
                                            <jet-label
                                                :small="true"
                                                :for="'optionName' + index"
                                                value="Option title"
                                                class="mb-0 mr-1"
                                            />
                                            <info>
                                                <p class="text-xs text-body">
                                                    This is what users of your
                                                    form will see.
                                                </p>
                                            </info>
                                        </div>
                                        <jet-input
                                            :id="'optionName' + index"
                                            type="text"
                                            @blur="onOptionFieldBlur"
                                            class="block w-full"
                                            v-model="fieldOptions[index].title"
                                        />
                                        <jet-input-error
                                            v-if="
                                                hasError(
                                                    'options.' +
                                                        index +
                                                        '.title'
                                                )
                                            "
                                            :message="
                                                getError(
                                                    'options.' +
                                                        index +
                                                        '.title'
                                                )
                                            "
                                            class="mt-1"
                                        />
                                    </div>
                                    <div class="flex-2">
                                        <div class="flex items-center mb-2">
                                            <jet-label
                                                :small="true"
                                                :for="'optionValue' + index"
                                                value="Option value"
                                                class="mb-0 mr-1"
                                            />
                                            <info>
                                                <p class="text-xs text-body">
                                                    This is the internal value
                                                    of the option which you can
                                                    use it for reference.
                                                </p>
                                            </info>
                                        </div>
                                        <jet-input
                                            :id="'optionValue' + index"
                                            type="text"
                                            @blur="onOptionFieldBlur"
                                            class="block w-full"
                                            v-model="fieldOptions[index].value"
                                        />
                                        <jet-input-error
                                            v-if="
                                                hasError(
                                                    'options.' +
                                                        index +
                                                        '.value'
                                                )
                                            "
                                            :message="
                                                getError(
                                                    'options.' +
                                                        index +
                                                        '.value'
                                                )
                                            "
                                            class="mt-1"
                                        />
                                    </div>
                                </div>
                                <div class="">
                                    <div class="flex items-center mb-2">
                                        <jet-label
                                            :small="true"
                                            :for="'optionHelpBlock' + index"
                                            value="Option description"
                                            class="mb-0 mr-1"
                                        />
                                        <info>
                                            <p class="text-xs text-body">
                                                This is an optional description
                                                field. It allows you to show
                                                more information about the
                                                option to your user.
                                            </p>
                                        </info>
                                    </div>
                                    <jet-textarea
                                        :id="'optionHelpBlock' + index"
                                        class="box-border w-full mt-1"
                                        @blur="onOptionFieldBlur"
                                        v-model="fieldOptions[index].helpBlock"
                                    />
                                    <jet-input-error
                                        v-if="
                                            hasError(
                                                'options.' +
                                                    index +
                                                    '.helpBlock'
                                            )
                                        "
                                        :message="
                                            getError(
                                                'options.' +
                                                    index +
                                                    '.helpBlock'
                                            )
                                        "
                                        class="mt-1"
                                    />
                                </div>
                                <div class="pb-6 col-span-full">
                                    <button
                                        class="text-xs btn"
                                        @click="onOptionRemove(index)"
                                    >
                                        Remove
                                    </button>
                                </div>
                            </DisclosurePanel>
                        </Disclosure>
                    </div>
                </div>

                <div
                    v-if="currentField.type !== 'payment'"
                    class="flex flex-col h-full px-10 py-5 -mx-10 border-t border-gray-200 col-span-full bg-gray-50"
                >
                    <div
                        class="flex flex-col sm:flex-row sm:items-center justify-between"
                    >
                        <jet-label
                            class="mb-0"
                            :small="true"
                            for="fieldRulesSearch"
                            >Field rules</jet-label
                        >
                        <field-rule-search
                            :disabled="currentField.type === 'payment'"
                            @update:modelValue="onRuleSearchChange"
                        />
                    </div>

                    <div class="mt-2">
                        <Disclosure
                            as="div"
                            :default-open="true"
                            v-slot="{ open }"
                            v-for="(rule, index) in currentField.rules"
                            :key="index"
                            class="text-xs"
                        >
                            <div class="p-2">
                                <div class="flex items-center justify-between">
                                    <DisclosureButton
                                        class="flex items-center flex-1 w-full h-full font-semibold"
                                    >
                                        <ChevronRightIcon
                                            class="w-4 h-4 mr-1"
                                            :class="
                                                open
                                                    ? 'rotate-90 transform'
                                                    : ''
                                            "
                                        />
                                        <span>{{ rule.label }}</span>
                                    </DisclosureButton>

                                    <button
                                        @click="removeFieldRule(rule.name)"
                                        class="group cursor-pointer btn-shadow rounded-lg p-0.5 disabled:opacity-50 disabled:cursor-not-allowed"
                                        :disabled="
                                            currentField.type === 'payment'
                                        "
                                    >
                                        <div
                                            class="p-0.5 bg-red-100 rounded-lg group-hover:bg-red-500 group-hover:shadow"
                                        >
                                            <icon
                                                name="trash"
                                                class="w-4 h-4 text-red-500 transition group-hover:text-white"
                                            />
                                        </div>
                                    </button>
                                </div>

                                <DisclosurePanel>
                                    <!-- Rule description  -->
                                    <div
                                        class="mt-2"
                                        v-html="rule.description"
                                    />
                                    <!-- Error message -->
                                    <div
                                        v-if="rule.hasOwnProperty('error')"
                                        class="flex flex-col mt-2"
                                    >
                                        <label
                                            :for="`rule_error__${rule.name}`"
                                            class="fe-label"
                                            >Error message</label
                                        >
                                        <input
                                            v-model="rule.error"
                                            :placeholder="errorBag[rule.name]"
                                            :id="`rule_error__${rule.name}`"
                                            type="text"
                                            class="border rounded fe-input border-stackborder focus:placeholder-gray-300 focus:ring-4 focus:border-blue-400 focus:ring-blue-200 focus:ring-opacity-50 text-input"
                                        />
                                        <p class="mt-1 text-xs">
                                            Clear and friendly message for user
                                            to read
                                        </p>
                                    </div>

                                    <!-- Value -->
                                    <div
                                        v-if="rule.hasOwnProperty('value')"
                                        class="flex flex-col mt-2"
                                    >
                                        <label
                                            :for="`rule_value__${rule.name}`"
                                            class="fe-label"
                                            >Rule value</label
                                        >
                                        <input
                                            v-model="rule.value"
                                            :id="`rule_value__${rule.name}`"
                                            type="text"
                                            class="border rounded fe-input border-stackborder focus:placeholder-gray-300 focus:ring-4 focus:border-blue-400 focus:ring-blue-200 focus:ring-opacity-50 text-input"
                                        />
                                        <p class="mt-1 text">
                                            Based on the value the field is
                                            valid or not.
                                        </p>
                                    </div>

                                    <div
                                        class="flex items-center flex-1 mt-2 space-x-4"
                                    >
                                        <div
                                            v-if="
                                                rule.hasOwnProperty('min_value')
                                            "
                                            class="flex flex-col flex-1"
                                        >
                                            <label
                                                :for="`rule_min_value__${rule.name}`"
                                                class="fe-label"
                                                >Minimum value</label
                                            >
                                            <input
                                                v-model="rule.min_value"
                                                :id="`rule_value__${rule.name}`"
                                                type="text"
                                                class="border rounded fe-input border-stackborder focus:placeholder-gray-300 focus:ring-4 focus:border-blue-400 focus:ring-blue-200 focus:ring-opacity-50 text-input"
                                            />
                                        </div>

                                        <!-- Maximum value -->
                                        <div
                                            v-if="
                                                rule.hasOwnProperty('max_value')
                                            "
                                            class="flex flex-col flex-1"
                                        >
                                            <label
                                                :for="`rule_max_value__${rule.name}`"
                                                class="fe-label"
                                                >Maximum value</label
                                            >
                                            <input
                                                v-model="rule.max_value"
                                                :id="`rule_value__${rule.name}`"
                                                type="text"
                                                class="border rounded fe-input border-stackborder focus:placeholder-gray-300 focus:ring-4 focus:border-blue-400 focus:ring-blue-200 focus:ring-opacity-50 text-input"
                                            />
                                        </div>
                                    </div>

                                    <!-- Examples -->
                                    <div
                                        v-if="rule.hasOwnProperty('examples')"
                                        class="flex flex-wrap items-start gap-2 mt-1 mb-8"
                                    >
                                        <div
                                            class="flex justify-self-start box cursor-pointer !py-0"
                                            :key="k"
                                            v-for="(item, k) in rule.examples"
                                            @click="rule.value = item.code"
                                        >
                                            <div
                                                class="py-1 pr-1 text-xs font-semibold border-r border-gray-200"
                                            >
                                                <span class="text-body">{{
                                                    rule.name
                                                }}</span>
                                            </div>
                                            <div class="py-1 pl-1 text-xs">
                                                {{ item.code }}
                                            </div>
                                        </div>
                                    </div>
                                </DisclosurePanel>
                            </div>
                        </Disclosure>
                    </div>
                </div>
            </div>

            <div
                class="flex items-center gap-5 px-10 pt-3 pb-5 border-t border-gray-200"
            >
                <button @click="$emit('cancel')" class="btn flex-1 !py-3">
                    Cancel
                </button>
                <jet-button
                    @click="$emit('done')"
                    class="justify-center flex-1 py-3"
                    >Save</jet-button
                >
            </div>
        </div>
        <popup-edit-row
            :current-field="currentField"
            v-else-if="currentField.name === 'row'"
            @done="() => this.$emit('done')"
            @changeCurrentField="changeCurrentField"
        />
    </div>
</template>
<script setup>
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'
import PopupEditRow from '@/Components/PopupEditRow.vue'
import Icon from '@/Components/Icon.vue'
import Info from '@/Components/Info.vue'
import { computed, ref } from 'vue'
import JetInputError from '@/Components/InputError.vue'
import JetTextarea from '@/Jetstream/Textarea.vue'
import {
    PlusSmIcon,
    ChevronRightIcon,
    ChevronUpIcon,
} from '@heroicons/vue/solid'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { onKeyStroke } from '@vueuse/core'
import FieldRuleSearch from '@/Components/FieldRuleSearch.vue'
import PaymentsRequiredField from '@/Components/PaymentsRequiredField.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetSelect from '@/Jetstream/Select.vue'
import PaymentFieldImage from '@/Components/PaymentFieldImage.vue'

const props = defineProps({
    currentField: Object,
    changeCurrentField: Function,
})

const errorBag = ref({})
const emit = defineEmits(['done', 'cancel'])
const fieldOptions = ref([...(props.currentField?.options || [])])
const inputTypes = [
    'text',
    'email',
    'textarea',
    'select',
    'checkbox',
    'radio',
    'date',
    'date_time',
    'date_range',
    'file',
]

const removeFieldRule = (ruleName) => {
    props.currentField.rules = props.currentField.rules.filter(
        (rule) => rule.name !== ruleName
    )
}

onKeyStroke('Enter', (e) => {
    e.preventDefault()
    emit('done')
})

const onRuleSearchChange = (rule) => props.currentField.rules.push(rule)

const addOption = () => {
    fieldOptions.value.push({
        title: '',
        value: '',
        helpBlock: '',
    })
    props.changeCurrentField({
        ...props.currentField,
        options: [...fieldOptions.value],
    })
}

const onOptionFieldBlur = () => {
    props.changeCurrentField({
        ...props.currentField,
        options: [...fieldOptions.value],
    })
}

const onOptionRemove = (index) => {
    fieldOptions.value.splice(index, 1)

    props.changeCurrentField({
        ...props.currentField,
        options: [...fieldOptions.value],
    })
}

const onProductImageChange = ({ img, img_url }) => {
    props.changeCurrentField({
        ...props.currentField,
        img,
        img_url,
    })
    emit('done', { closeModal: false })
}

const hasError = (field) => {
    return props?.currentField?.errors?.hasOwnProperty(field)
}

const getError = (field) => {
    if (!props?.currentField?.errors) {
        return false
    }

    return props?.currentField?.errors[field][0]
}

const isPaymentField = computed(() => props.currentField.type === 'payment')
</script>
<style lang="scss" scoped>
.fe-input {
    height: 28px !important;
}
</style>
