<template>
    <div>
        <div
            class="flex flex-col items-start justify-between p-3 lg:flex-row lg:items-center lg:p-6"
        >
            <div>
                <h3 class="page-title">Fields</h3>
                <p class="max-w-lg page-desc">
                    We made it easy to validate the data coming in with custom
                    fields.
                </p>
            </div>
            <button
                @click="createField"
                class="flex items-center flex-shrink-0 px-4 py-2 mt-4 bg-blue-600 rounded lg:mt-0 lg:-my-2 font-regular group"
            >
                <div
                    class="p-1 mr-2 transition bg-blue-700 rounded group-hover:bg-white"
                >
                    <svg
                        class="w-4 h-4 text-white group-hover:hidden"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        ></path>
                    </svg>
                    <span
                        class="hidden w-4 h-4 text-sm leading-tight text-white group-hover:block group-hover:text-blue-600"
                        >C</span
                    >
                </div>
                <span class="text-sm font-semibold text-white"
                    >Create a field</span
                >
            </button>
        </div>

        <div class="w-full p-6" v-if="formData.fields.length">
            <table class="min-w-full divide-y divide-gray-300">
                <thead>
                    <tr>
                        <th
                            scope="col"
                            class="uppercase whitespace-nowrap py-2 pr-3 pl-4 text-left text-[11px] font-regular text-gray-900"
                        >
                            Field label
                        </th>
                        <th
                            scope="col"
                            class="uppercase whitespace-nowrap px-2 py-2 text-left text-[11px] font-regular text-gray-900"
                        >
                            Field name
                        </th>
                        <th
                            scope="col"
                            class="uppercase whitespace-nowrap px-2 py-2 text-left text-[11px] font-regular text-gray-900"
                        >
                            Field type
                        </th>
                        <th
                            scope="col"
                            class="uppercase whitespace-nowrap px-2 py-2 text-left text-[11px] font-regular text-gray-900"
                        >
                            Validation rules
                        </th>
                        <th
                            scope="col"
                            class="relative py-2 pl-3 pr-4 uppercase whitespace-nowrap sm:pr-6"
                        >
                            <span class="sr-only">Edit</span>
                        </th>
                    </tr>
                </thead>
                <tbody class="divide-y divide-gray-200">
                    <tr
                        v-for="(field, index) in formData.fields"
                        :key="index"
                        class="cursor-pointer hover:bg-slate-50 group"
                        @click="currentField = field"
                    >
                        <!-- <Link
                            :href="$route('fields.edit', { form: $page.props.form, field: field._id })"
                            as="td"
                            class="flex items-center py-2 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6 md:pl-0 group hover:cursor-pointer"
                        >
                            <div class="mr-2 box group-hover:text-blue-600 group-hover:transition">
                                <field-type-icon :type="field.type" />
                            </div>
                            <span class="group-hover:text-blue-600">{{ field.label }}</span>
                        </Link> -->
                        <td>
                            <button
                                @click="currentField = field"
                                class="flex items-center py-2 pl-4 pr-3 ml-4 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6 md:pl-0 group hover:cursor-pointer"
                            >
                                <div
                                    class="mr-2 box group-hover:text-blue-600 group-hover:transition"
                                >
                                    <field-type-icon :type="field.type" />
                                </div>
                                <span class="group-hover:text-blue-600">{{
                                    field.label
                                }}</span>
                            </button>
                        </td>
                        <td
                            class="px-2 py-2 text-sm text-gray-500 whitespace-nowrap"
                        >
                            {{ field.name }}
                        </td>
                        <td
                            class="px-2 py-2 text-sm text-gray-500 whitespace-nowrap"
                        >
                            {{ field.type }}
                        </td>
                        <td
                            class="px-2 py-2 text-sm text-gray-500 whitespace-nowrap"
                        >
                            <div
                                v-if="field.rules.length"
                                class="flex flex-wrap items-center gap-1"
                            >
                                <div
                                    v-for="(rule, key) in field.rules"
                                    :key="key"
                                    class="box"
                                    :tooltip="rule.label"
                                >
                                    {{ rule.name }}
                                    <span v-if="rule.value" class="rule-value">
                                        {{
                                            typeof rule.value === 'object'
                                                ? rule.value.length
                                                : rule.value
                                                      .toString()
                                                      .substr(0, 7)
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div v-else class="text-sm text-gray-300">
                                No rules
                            </div>
                        </td>
                        <td
                            class="relative py-2 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6 md:pr-0"
                        >
                            <div
                                class="flex items-center justify-end opacity-0 group-hover:opacity-100"
                            >
                                <!-- <Link
                                    :href="$route('fields.edit', { form: form_id, field: field._id })"
                                    :class="['text-gray-500', 'group flex  items-center rounded-md px-2 py-1 text-xs hover:text-blue-500']"
                                >
                                    <icon name="pencil" class="w-4 h-4" />
                                </Link> -->

                                <button
                                    @click.stop="confirmFieldDeletion(field)"
                                    :class="[
                                        'text-gray-500',
                                        'group flex  items-center rounded-md px-2 py-1 text-xs hover:text-red-500',
                                    ]"
                                >
                                    <icon name="trash" class="w-5 h-5" />
                                </button>
                            </div>
                            <!-- <Menu as="div" class="relative inline-block text-left">
                                <menu-button class="p-0.5 hover:shadow-md w-8 flex items-center justify-center rounded-md mr-3 relative top-1">
                                    <DotsHorizontalIcon class="w-4 h-4 text-gray-500 hover:text-gray-800" aria-hidden="true" />
                                </menu-button>
                                <transition
                                    enter-active-class="transition duration-100 ease-out"
                                    enter-from-class="transform scale-95 opacity-0"
                                    enter-to-class="transform scale-100 opacity-100"
                                    leave-active-class="transition duration-75 ease-in"
                                    leave-from-class="transform scale-100 opacity-100"
                                    leave-to-class="transform scale-95 opacity-0"
                                >
                                    <menu-items
                                        class="absolute right-0 z-50 w-32 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    >
                                        <div class="px-1 py-1">
                                            <menu-item v-slot="{ active }">
                                                <Link
                                                    :href="$route('fields.edit', { form: form_id, field: field._id })"
                                                    :class="[
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                                                        'group flex w-full items-center rounded-md px-2 py-1 text-xs',
                                                    ]"
                                                >
                                                    Edit
                                                </Link>
                                            </menu-item>
                                            <menu-item v-slot="{ active }">
                                                <button
                                                    @click.prevent="confirmFieldDeletion(field)"
                                                    :class="[
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                                                        'group flex w-full items-center rounded-md px-2 py-1 text-xs',
                                                    ]"
                                                >
                                                    Remove
                                                </button>
                                            </menu-item>
                                        </div>
                                    </menu-items>
                                </transition>
                            </Menu> -->
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="w-full p-6" v-else>
            <div class="bg-white border rounded">
                <img
                    src="/images/no-data.svg"
                    class="max-w-sm mx-auto"
                    alt="No data illustration"
                />
            </div>
        </div>

        <jet-confirmation-modal
            :show="confirmingFieldDeletion"
            @close="confirmingFieldDeletion = false"
        >
            <template #title> Delete Field</template>

            <template #content>
                Are you sure you want to delete this field?</template
            >

            <template #footer>
                <jet-secondary-button @click="confirmingFieldDeletion = false">
                    Cancel</jet-secondary-button
                >

                <jet-danger-button
                    class="ml-2"
                    @click="deleteField"
                    :class="{ 'opacity-25': deleteFieldForm.processing }"
                    :disabled="deleteFieldForm.processing"
                >
                    Delete Field
                </jet-danger-button>
            </template>
        </jet-confirmation-modal>

        <field-settings-modal
            :show="currentField"
            @close="currentField = null"
            max-width="xl"
        >
            <template #content>
                <field-settings-popup
                    @done="onDone"
                    @cancel="currentField = null"
                    :current-field="currentField"
                    :change-current-field="changeCurrentField"
                />
            </template>
        </field-settings-modal>
    </div>
</template>
<script>
import ShowFormLayout from '@/Layouts/ShowFormLayout.vue'
import AppLayout from '@/Layouts/AppLayout.vue'
export default {
    layout: [AppLayout, ShowFormLayout],
}
</script>
<script setup>
import JetDangerButton from '@/Jetstream/DangerButton.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue'
import { router, useForm, Link } from '@inertiajs/vue3'
import FieldTypeIcon from '@/Components/FieldTypeIcon.vue'
import FieldSettingsModal from '@/Components/FieldSettingsModal.vue'
import FieldSettingsPopup from '@/Components/FieldSettingsPopup.vue'
import { ref, onMounted, onUnmounted, reactive } from 'vue'
import { usePage } from '@inertiajs/vue3'
import axios from 'axios'
import Icon from '@/Components/Icon.vue'
import confetti from 'https://cdn.skypack.dev/canvas-confetti'

const props = defineProps({
    fields: Array,
})

const deleteFieldForm = useForm({})
const confirmingFieldDeletion = ref(false)
const currentField = ref(null)
const fieldsData = reactive([...props.fields])
const formData = useForm({
    fields: fieldsData,
})

const form_id = usePage().props.meta.form_uuid

async function onDone() {
    try {
        const res = await axios.post(
            route('builder.field.update', {
                form: form_id,
                field: currentField.value._id,
            }),
            currentField.value
        )

        currentField.value = null

        let fields = [...fieldsData].map((f) => {
            if (f._id === res.data.field._id) {
                return res.data.field
            }

            return f
        })
        Object.assign(formData, fields)
    } catch (err) {
        console.log('err', err.response.data)
        currentField.value = {
            ...currentField.value,
            errors: err?.response?.data?.errors,
        }
    }
}

function changeCurrentField(field) {
    currentField.value = field
}

function confirmFieldDeletion(field) {
    confirmingFieldDeletion.value = field._id
}

function deleteField() {
    deleteFieldForm.delete(
        route('fields.destroy', {
            form: form_id,
            field: confirmingFieldDeletion.value,
        }),
        {
            errorBag: 'deleteField',
            preserveState: false,
            onSuccess: () => {
                confirmingFieldDeletion.value = false
            },
        }
    )
}

const handleHotKey = (e) => {
    if (e.key === 'c' || e.key === 'C') {
        createField()
    }
}

function createField() {
    confetti({
        particleCount: 100,
        startVelocity: 30,
        spread: 360,
        origin: {
            x: 0.5,
            // since they fall down, start a bit higher than random
            y: 0.2,
        },
    })

    return router.post(
        route('fields.store', { form: form_id }),
        {},
        {
            preserveState: false,
            preserveScroll: true,
        }
    )
}

onMounted(() => {
    document.addEventListener('keyup', handleHotKey)
})

onUnmounted(() => {
    document.removeEventListener('keyup', handleHotKey)
})
</script>
