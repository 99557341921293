<template>
    <div
        class="bg-white border-blue-500 rounded-lg shadow min-h-[250px] flex flex-col justify-between border-t-4"
    >
        <header class="p-4 border-b border-gray-100">
            <h3
                class="inline px-2 py-1 font-bold leading-tight tracking-tight bg-gray-200 rounded-lg"
            >
                {{ plan.name }}
            </h3>
            <div>
                <div
                    class="flex items-start mt-6 text-5xl leading-normal tracking-tighter text-black font-regular"
                >
                    <div class="leading-none">
                        {{
                            plan.recurring === 'monthly'
                                ? plan.price
                                : plan.yearly_per_month
                        }}
                    </div>
                    <div class="ml-2 text-xl">$</div>
                </div>
                <div v-if="plan?.recurring === 'monthly'">
                    {{ plan?.recurring }}
                </div>
            </div>
        </header>

        <ul class="flex-1 p-4 space-y-2">
            <li class="flex items-center">
                <icon name="check" class="w-5 h-5 mr-1 text-green-500" /><span
                    class="text-sm"
                    >{{
                        plan.forms_and_endpoints === 100
                            ? 'Unlimited'
                            : plan.forms_and_endpoints
                    }}
                    forms & endpoints</span
                >
            </li>
            <li class="flex items-center">
                <icon name="check" class="w-5 h-5 mr-1 text-green-500" /><span
                    class="text-sm"
                    >{{
                        Intl.NumberFormat('en-US', {}).format(plan.entries)
                    }}
                    submissions</span
                >
            </li>
            <li class="flex items-center">
                <icon name="check" class="w-5 h-5 mr-1 text-green-500" /><span
                    class="text-sm"
                    >{{ plan.storage }} GB of storage</span
                >
            </li>
            <li class="flex items-center">
                <icon name="check" class="w-5 h-5 mr-1 text-green-500" /><span
                    class="text-sm"
                >
                    {{ workspaces }}
                </span>
            </li>
        </ul>

        <div class="p-4">
            <button
                type="button"
                @click="() => onCheckout(plan)"
                class="justify-center w-full button"
            >
                Subscribe
            </button>
        </div>
    </div>
</template>
<script setup>
import Icon from '@/Components/Icon.vue'
import { computed } from 'vue'

const props = defineProps({
    plan: {
        type: Object,
        required: true,
    },

    onCheckout: {
        type: Function,
        required: true,
    },
})

const workspaces = computed(() => {
    const total =
        props.plan.workspaces === 100 ? 'Unlimited' : props.plan.workspaces

    return total + ' workspaces'
})
</script>
