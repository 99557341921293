<template>
    <app-layout class="relative h-full">
        <svg
            aria-hidden="true"
            class="absolute z-0 inset-x-0 top-0 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_center,white,transparent)]"
        >
            <defs>
                <pattern id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
                    <path d="M.5 200V.5H200" fill="none"></path>
                </pattern>
            </defs>
            <svg x="50%" y="-1" class="overflow-visible fill-gray-50">
                <path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z" stroke-width="0"></path>
            </svg>
            <rect width="100%" height="100%" stroke-width="0" fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"></rect>
        </svg>
        <div class="relative z-10">
            <div class="z-10 px-3 sm:px-6 pt-6 sm:pt-10 mx-auto lg:px-8">
                <div class="max-w-xl mx-auto text-left sm:text-center">
                    <h1 class="hidden sm:block text-base font-semibold leading-7 text-blue-600">Pricing</h1>
                    <p class="mt-2 text-3xl sm:text-4xl font-bold tracking-tight text-gray-900">Choose the plan that works for you</p>
                </div>
            </div>
            <div v-if="!loaded" class="flex items-center justify-center h-screen text-xs text-center">
                <icon name="loading" class="w-6 h-6 mr-3 text-gray-400 animate-spin" />
            </div>
            <div v-else class="pb-10 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <payment-plans :plans="plans" />
            </div>
        </div>
    </app-layout>
</template>
<script setup>
import AppLayout from '@/Layouts/AppLayout.vue'

import PaymentPlans from '@/Components/billing/PaymentPlans.vue'
import { ref } from 'vue'
import Icon from '@/Components/Icon.vue'

const loaded = ref(false)
const plans = ref([])

async function onLoad() {
    try {
        const res = await axios.get(route(`billing.plans`))
        loaded.value = true
        plans.value = res.data
    } catch (err) {
        loaded.value = true
    }
}

onLoad()
</script>
