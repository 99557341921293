<template>
    <div>
        <div class="max-w-lg p-3 lg:p-6">
            <h3 class="page-title">Redirect</h3>
            <p class="page-desc">
                You can have the user redirected to a different page once the form is successfully submitted. If no redirect URL is provided, the user will see
                a default thank you message.
            </p>
            <form @submit.prevent="redirectForm.post($route('form.settings.redirect', { form: form._id }))">
                <div class="mt-4">
                    <jet-label for="name" value="URL" />
                    <jet-input id="type" type="text" class="block w-full mt-1" v-model="redirectForm.return_url" autofocus />
                    <jet-input-error :message="redirectForm.errors.return_url" class="mt-2" />
                </div>

                <div class="mt-4">
                    <jet-button class="mr-3" :class="{ 'opacity-25': redirectForm.processing }" :disabled="redirectForm.processing">Save</jet-button>
                    <jet-secondary-button @click.prevent="redirectForm.reset()" type="button">Reset</jet-secondary-button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import ShowFormLayout from '@/Layouts/ShowFormLayout.vue'
export default {
    // layout: (h, page) => h(AppLayout, [page]),
    layout: [AppLayout, ShowFormLayout],
}
</script>

<script setup>
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetInputError from '@/Jetstream/InputError.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import { useForm } from '@inertiajs/vue3'

const props = defineProps({
    form: Object,
})

const redirectForm = useForm({
    _method: 'PUT',
    return_url: props.form.return_url,
})
</script>
