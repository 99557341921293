<template>
    <div class="h-screen overflow-y-auto no-scrollbar">
        <div class="p-6 mt-[13px] w-full relative">
            <fun-bg />

            <div
                class="relative bg-white max-w-[545px] pb-4 sm:pb-14 card-shadow md:pt-[65px] pt-8 px-5 md:px-[65px] rounded-lg mx-auto mt-[120px]"
            >
                <wordmark class="mb-3" />
                <h3
                    class="mb-4 leading-relaxed font-bold tracking-tight text-2xl text-[#2e161e]"
                >
                    Verify your email address
                </h3>
                <div class="mb-4 text-xs leading-6 text-gray-600">
                    Thank you for signing up! Before getting started, could you
                    verify your email address by clicking on the link we just
                    emailed to you? If you didn't receive the email, we will
                    gladly send you another.
                </div>

                <div
                    class="mb-4 text-xs font-medium text-green-600"
                    v-if="verificationLinkSent"
                >
                    A new verification link has been sent to the email address
                    you provided during registration.
                </div>

                <form @submit.prevent="submit" class="mt-4">
                    <pf-button
                        :class="{ 'opacity-25': form.processing }"
                        :disabled="form.processing"
                    >
                        Resend verification email
                    </pf-button>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import JetButton from '@/Jetstream/Button.vue'
import { useForm } from '@inertiajs/vue3'
import FunBg from '@/Components/FunBg.vue'
import PfButton from '@/Pixelform/Button.vue'
import { computed } from 'vue'
import Wordmark from '@/Components/Wordmark.vue'

const props = defineProps({
    status: String,
})

const form = useForm({})
const submit = () => form.post(route('verification.send'))

const verificationLinkSent = computed(
    () => props.status === 'verification-link-sent'
)
</script>
