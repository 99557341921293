<template>
    <div>
        <form method="post" @submit.prevent="form.post(route('forms.store'))">
            <div class="">
                <jet-label for="name" value="Name" />
                <jet-input id="type" type="text" class="block w-full" v-model="form.name" autofocus />
                <jet-input-error :message="form.errors.name" class="mt-2" />
            </div>
            <div class="mt-2">
                <jet-label for="name" value="Form type" />
                <div class="relative z-0 border-b border-l border-r border-gray-300 rounded-lg">
                    <button
                        @click="form.type = 'ENDPOINT'"
                        class="relative z-10 inline-flex w-full px-4 py-3 border-t border-gray-200 rounded-lg rounded-b-none focus:z-10 focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200"
                    >
                        <div :class="{ 'opacity-50': form.type && form.type !== 'ENDPOINT' }">
                            <!-- Role Name -->
                            <div class="flex items-center">
                                <div class="text-sm text-gray-600" :class="{ 'font-semibold': form.type === 'ENDPOINT' }">Endpoint</div>

                                <svg
                                    v-if="form.type === 'ENDPOINT'"
                                    class="w-5 h-5 ml-2 text-green-400"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                </svg>
                            </div>

                            <!-- Role Description -->
                            <div class="mt-2 text-xs text-gray-600">I need an API endpoint to send my data through a POST request.</div>
                        </div>
                    </button>

                    <button
                        @click="form.type = 'FORM'"
                        class="relative inline-flex w-full px-4 py-3 border-t border-gray-200 rounded-lg rounded-t-none focus:z-10 focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200"
                    >
                        <div :class="{ 'opacity-50': form.type && form.type !== 'FORM' }">
                            <!-- Role Name -->
                            <div class="flex items-center">
                                <div class="text-sm text-gray-600" :class="{ 'font-semibold': form.type === 'FORM' }">Form</div>

                                <svg
                                    v-if="form.type === 'FORM'"
                                    class="w-5 h-5 ml-2 text-green-400"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                </svg>
                            </div>

                            <!-- Role Description -->
                            <div class="mt-2 text-xs text-gray-600">I need both the functionality and the form builder with all the bells.</div>
                        </div>
                    </button>
                </div>
                <jet-input-error :message="form.errors.name" class="mt-2" />
            </div>
            <div class="mt-2">
                <jet-label for="workspace" value="Workspace" />

                <jet-select name="workspace_id" v-model="form.workspace_id" id="workspace" class="block w-full mt-1">
                    <template #options>
                        <option value="" disabled selected>Pick a workspace</option>
                        <option v-for="team in $page.props.auth.user.all_teams" :value="team.id">{{ team.name }}</option>
                    </template>
                </jet-select>
                <jet-input-error :message="form.errors.workspace_id" class="mt-2" />
            </div>
            <jet-button class="mr-3">Create</jet-button>
            <jet-secondary-button :class="{ 'opacity-25': form.processing }" :disabled="form.processing" @click.prevent="$emit('close')" type="button"
                >Cancel
            </jet-secondary-button>
        </form>
    </div>
</template>

<script>
import FormSection from '@/Jetstream/FormSection.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetInputError from '@/Jetstream/InputError.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import JetSelect from '@/Jetstream/Select.vue'
import { useForm } from '@inertiajs/vue3'

export default {
    name: 'CreateForm',
    components: { FormSection, JetInput, JetLabel, JetInputError, JetButton, JetSecondaryButton, JetSelect },
    data() {
        return {
            form: useForm({
                name: '',
                type: 'ENDPOINT',
                workspace_id: null,
            }),
        }
    },
}
</script>

<style scoped></style>
