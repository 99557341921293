<template>
    <div>
        <main class="content flex">
            <div class="p-6 max-w-lg">
                <h3 class="font-bold text-2xl leading-tight">Overview</h3>
                <p class="text-sm text-gray-700">Birds eye view on how your form is doing.</p>
            </div>
        </main>
    </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import ShowFormLayout from '@/Layouts/ShowFormLayout.vue'

export default {
    layout: [AppLayout, ShowFormLayout],
    components: { ShowFormLayout, AppLayout },
    props: ['form'],
    data() {
        return {
            settingsMenu: false,
        }
    },
    computed: {
        form() {
            return this.$page.props.form
        },
    },
}
</script>

<style scoped>
.content {
    height: calc(100vh - 80px);
}

.sidebar {
    width: 150px;
}
</style>
