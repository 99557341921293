<template>
    <modal :show="show" :max-width="maxWidth" :transparent="true">
        <div class="relative p-1 bg-white rounded-lg">
            <icon name="search" class="absolute top-[20px] text-gray-300 left-2 w-8 h-8" />
            <jet-input
                class="w-full py-4 focus:ring-0 border-0 ring-0 shadow-none pl-[40px] text-md font-bold spotlight-search"
                type="text"
                v-model="search"
                @keydown.enter="selectOption"
                @keydown="handleKeydown"
            />
        </div>
        <div class="h-[350px] w-[95%] mx-auto overflow-y-auto">
            <jet-label
                class="p-3 mt-1 text-white transition bg-gray-800 rounded cursor-pointer hover:bg-gray-600"
                v-for="(field, index) in filteredList"
                :id="field.name"
                :key="index"
                :for="field.name"
                @click="add(field)"
                :class="{ 'bg-teal-500': selectedIndex === index }"
            >
                {{ field.label }}
            </jet-label>
        </div>
    </modal>
</template>

<script>
import Modal from '@/Jetstream/Modal.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetInputError from '@/Jetstream/InputError.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import JetSelect from '@/Jetstream/Select.vue'
import JetCheckbox from '@/Jetstream/Checkbox.vue'
import Icon from '@/Components/Icon.vue'
import components from '@/components'
import { startsWith } from 'lodash'

export default {
    emits: ['selected'],
    components: {
        JetLabel,
        JetInput,
        JetInputError,
        JetButton,
        JetSelect,
        JetSecondaryButton,
        Modal,
        JetCheckbox,
        Icon,
    },
    props: ['show', 'maxWidth', 'closeable', 'currentRef'],
    data() {
        return {
            search: '',
            components,
            selectedIndex: 0,
        }
    },
    methods: {
        selectOption() {
            const option = this.filteredList[this.selectedIndex]

            this.add(option)
        },

        add(field) {
            this.$emit('selected', field)
            this.$emit('close')

            this.search = ''
        },

        handleKeydown(event) {
            switch (event.keyCode) {
                case 38:
                    if (this.selectedIndex > 0) {
                        this.selectedIndex--
                    }
                    break
                case 40:
                    if (this.selectedIndex < this.filteredList.length - 1) {
                        this.selectedIndex++
                    }
                    break
            }
        },

        preventStackNesting(field) {
            this.selectedIndex = 0
            if (this.currentRef && (startsWith(this.currentRef, 'stack') || startsWith(this.currentRef, 'row'))) {
                return field.name !== 'stack'
            } else if (!this.currentRef) {
                return field
            }
        },
    },
    computed: {
        filteredList() {
            return [...components, ...this.$page.props.fields]
                .filter((field) => {
                    return field.label.toString().toLowerCase().includes(this.search.toLowerCase())
                })
                .filter(this.preventStackNesting)
        },
    },
}
</script>

<style scoped></style>
