<template>
    <div>
        <div class="flex h-screen">
            <!-- Sidebar -->
            <div
                class="fixed bg-white border-r lg:border-r-0 transition duration-300 border-gray-100 z-30 lg:relative transform lg:transform-none -translate-x-full lg:flex pt-5 lg:mt-5 flex-1 max-w-[230px] flex-col h-full flex-shrink-0 lg:pt-2 pb-32 sidebar px-3"
                :class="{
                    '-translate-x-0 flex transition-transform duration-100':
                        sidebarOpen,
                }"
            >
                <div class="space-y-2">
                    <jet-dropdown
                        align="left"
                        width="48"
                        :content-classes="['w-[250px] bg-white rounded']"
                    >
                        <template #trigger>
                            <span class="inline-flex w-[206px]">
                                <button
                                    type="button"
                                    class="inline-flex items-center text-sm leading-4 font-medium rounded-xl text-body box !px-2 !py-1 focus:outline-none active:bg-gray-100 transition !w-[206px] justify-between"
                                >
                                    <div class="flex items-center">
                                        <span
                                            class="flex items-center text-sm !rounded-xl font-bold py-1 px-2 text-blue-500 border border-gray-200 mr-2"
                                        >
                                            <img
                                                src="/images/logo.svg"
                                                class="w-2"
                                            />
                                        </span>
                                        <span class="line-clamp">{{
                                            meta.name
                                        }}</span>
                                    </div>
                                    <svg
                                        class="ml-2 -mr-0.5 h-4 w-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </span>
                        </template>
                        <template #content>
                            <div v-if="meta?.forms_dropdown?.length > 0">
                                <div class="border-t border-gray-100"></div>
                                <jet-dropdown-link
                                    :key="formId"
                                    v-for="form in meta.forms_dropdown"
                                    :href="
                                        $route('forms.inbox', { form: formId })
                                    "
                                >
                                    {{ form.name }}
                                </jet-dropdown-link>
                            </div>
                        </template>
                    </jet-dropdown>

                    <div class="space-y-2">
                        <a
                            :href="
                                meta.form_type === 'FORM'
                                    ? $route('client-form', { form: formId })
                                    : $route('form.setup', {
                                          form: formId,
                                      })
                            "
                            class="rounded py-1.5 flex items-center justify-center flex-1 hover:text-gray-500 bg-[#283540]"
                            :target="
                                meta.form_type === 'FORM' ? '_blank' : '_self'
                            "
                            :tooltip="
                                !meta?.published
                                    ? 'Your form is unpublished'
                                    : ''
                            "
                        >
                            <span class="ml-2 text-sm font-semibold text-white"
                                >{{
                                    meta.form_type === 'FORM'
                                        ? 'Preview'
                                        : 'Setup'
                                }}
                            </span>
                        </a>
                    </div>

                    <Link
                        :href="$route('forms.inbox', { form: formId })"
                        class="flex items-center text-sm !rounded font-bold py-1 px-2 btn"
                        :class="{
                            '!text-orange-500':
                                $route().current() === 'forms.inbox',
                            '!text-gray-500':
                                $route().current() !== 'forms.inbox',
                        }"
                    >
                        <svg
                            class="w-6 h-6 bg-orange-100 text-orange-600 rounded-lg p-[3px]"
                            :class="{
                                '!bg-orange-500 !text-white':
                                    $route().current() === 'forms.inbox',
                            }"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                            ></path>
                        </svg>
                        <span class="ml-3 text-sm font-semibold">Inbox</span>
                    </Link>
                    <Link
                        v-if="meta.form_type === 'ENDPOINT'"
                        :href="$route('fields.index', { form: formId })"
                        class="flex items-center text-sm !rounded font-bold py-1 px-2 btn"
                        :class="{
                            ' !text-blue-500': $route()
                                .current()
                                .includes('fields'),
                            ' !text-gray-500': !$route()
                                .current()
                                .includes('fields'),
                        }"
                    >
                        <svg
                            class="w-6 h-6 bg-blue-100 text-blue-600 rounded-lg p-[3px]"
                            :class="{
                                '!bg-blue-500 !text-white':
                                    $route().current() === 'fields.index',
                            }"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                            ></path>
                        </svg>
                        <span class="ml-3 text-sm">Fields</span>
                    </Link>
                    <Link
                        v-if="meta.form_type === 'FORM'"
                        :href="
                            $route('form.settings.builder', { form: formId })
                        "
                        class="flex items-center text-sm !rounded font-bold py-1 px-2 btn"
                        :class="{
                            ' !text-blue-500': $route()
                                .current()
                                .includes('builder'),
                            ' !text-gray-500': !$route()
                                .current()
                                .includes('builder'),
                        }"
                    >
                        <svg
                            class="w-6 h-6 bg-blue-100 text-blue-600 rounded-lg p-[3px]"
                            :class="{
                                '!bg-blue-500 !text-white': $route()
                                    .current()
                                    .includes('builder'),
                            }"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
                            />
                        </svg>
                        <span class="ml-3 text-sm">Builder</span>
                    </Link>
                    <!--                    <Link-->
                    <!--                        v-if="meta.form_type !== 'ENDPOINT'"-->
                    <!--                        :href="$route('form.code-editor', meta._id)"-->
                    <!--                        class="flex items-center text-sm !rounded font-bold py-1 px-2"-->
                    <!--                        :class="{-->
                    <!--                            ' text-blue-500 btn': route().current().includes('code-editor'),-->
                    <!--                            ' text-gray-500': !route().current().includes('code-editor'),-->
                    <!--                        }"-->
                    <!--                    >-->
                    <!--                        <svg-->
                    <!--                            class="w-6 h-6 bg-green-100 text-green-600 rounded-lg p-[3px]"-->
                    <!--                            fill="none"-->
                    <!--                            stroke="currentColor"-->
                    <!--                            viewBox="0 0 24 24"-->
                    <!--                            xmlns="http://www.w3.org/2000/svg"-->
                    <!--                        >-->
                    <!--                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"></path>-->
                    <!--                        </svg>-->
                    <!--                        <span class="ml-3 text-sm">Editor</span>-->
                    <!--                    </Link>-->
                </div>

                <h4
                    class="pl-3 mt-5 mb-2 tracking-widest uppercase border-l border-gray-300 text-tiny text-body"
                >
                    Settings
                </h4>

                <div
                    class="flex flex-col settings-nav"
                    :class="
                        $route().current().includes('settings') ? '!flex' : ''
                    "
                >
                    <Link
                        v-if="meta.form_type !== 'ENDPOINT'"
                        :href="$route('form.settings.redirect-view', formId)"
                        class="py-1 pl-[5px] text-body text-md rounded-lg font-semibold hover:text-blue-600 transition cursor-pointer flex items-center !px-2 self-start"
                        :class="
                            $route().current().includes('redirect-view')
                                ? 'text-blue-500 bg-gray-100'
                                : 'text-gray-500'
                        "
                    >
                        <span class="mr-2 bg-white btn">
                            <svg
                                viewBox="0 0 24 24"
                                class="w-4 h-4"
                                width="18"
                                height="18"
                                stroke="currentColor"
                                stroke-width="2"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <polyline points="17 1 21 5 17 9"></polyline>
                                <path d="M3 11V9a4 4 0 0 1 4-4h14"></path>
                                <polyline points="7 23 3 19 7 15"></polyline>
                                <path d="M21 13v2a4 4 0 0 1-4 4H3"></path>
                            </svg>
                        </span>
                        <span class="text-sm text-dark-blue">Redirect</span>
                    </Link>

                    <Link
                        :href="$route('form.settings.mail', formId)"
                        class="py-1 pl-[5px] text-body text-md rounded-lg font-semibold hover:text-blue-600 transition cursor-pointer flex items-center !py-[5px] !px-2 self-start"
                        :class="
                            $route().current().includes('mail')
                                ? 'text-blue-500 bg-gray-100'
                                : 'text-gray-500'
                        "
                    >
                        <span class="mr-2 bg-white btn">
                            <svg
                                class="w-4 h-4"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                ></path>
                            </svg>
                        </span>
                        <span class="text-sm text-dark-blue">Mail</span>
                    </Link>
                    <Link
                        v-if="meta.form_type !== 'ENDPOINT'"
                        :href="$route('form.settings.hero', formId)"
                        class="py-1 pl-[5px] text-body text-md rounded-lg font-semibold hover:text-blue-600 transition cursor-pointer flex items-center !py-[5px] !px-2 self-start"
                        :class="
                            $route().current().includes('hero')
                                ? 'text-blue-500 bg-gray-100'
                                : 'text-gray-500'
                        "
                    >
                        <span class="mr-2 bg-white btn">
                            <svg
                                class="w-4 h-4"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                                ></path>
                            </svg>
                        </span>
                        <span class="text-sm text-dark-blue">Hero</span>
                    </Link>
                    <Link
                        :href="$route('form.settings.webhook', formId)"
                        class="pl-[5px] text-body text-md rounded-lg font-semibold hover:text-blue-600 transition cursor-pointer flex items-center !py-[5px] !px-2 self-start"
                        :class="
                            $route().current().includes('webhook')
                                ? 'text-blue-500 bg-gray-100'
                                : 'text-gray-500'
                        "
                    >
                        <span class="mr-2 bg-white btn">
                            <svg
                                class="w-4 h-4"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <circle cx="12" cy="5" r="3"></circle>
                                <line x1="12" y1="22" x2="12" y2="8"></line>
                                <path d="M5 12H2a10 10 0 0 0 20 0h-3"></path>
                            </svg>
                        </span>
                        <span class="text-sm text-dark-blue">Webhooks</span>
                    </Link>
                    <Link
                        :href="$route('form.settings.api-token', formId)"
                        class="pl-[5px] text-body text-md rounded-lg font-semibold hover:text-blue-600 transition cursor-pointer flex items-center !py-[5px] !px-2 self-start"
                        :class="
                            $route().current().includes('api-token')
                                ? 'text-blue-500 bg-gray-100'
                                : 'text-gray-500'
                        "
                    >
                        <span class="mr-2 bg-white btn">
                            <svg
                                class="w-4 h-4"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"
                                ></path>
                            </svg>
                        </span>
                        <span class="text-sm text-dark-blue">API Token</span>
                    </Link>
                    <Link
                        v-if="meta.form_type !== 'ENDPOINT'"
                        :href="$route('form.settings.thanks', formId)"
                        class="pl-[5px] text-body text-md rounded-lg font-semibold hover:text-blue-600 transition cursor-pointer flex items-center !py-[5px] !px-2 self-start"
                        :class="
                            $route().current().includes('thanks')
                                ? 'text-blue-500 bg-gray-100'
                                : 'text-gray-500'
                        "
                    >
                        <span class="mr-2 bg-white btn">
                            <svg
                                class="w-4 h-4"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
                                ></path>
                            </svg>
                        </span>
                        <span class="text-sm text-dark-blue">Thanks</span>
                    </Link>
                    <Link
                        v-if="meta.form_type"
                        :href="$route('form.settings.timezone', formId)"
                        class="pl-[5px] text-body text-md rounded-lg font-semibold hover:text-blue-600 transition cursor-pointer flex items-center !py-[5px] !px-2 self-start"
                        :class="
                            $route().current().includes('timezone')
                                ? 'text-blue-500 bg-gray-100'
                                : 'text-gray-500'
                        "
                    >
                        <span class="mr-2 bg-white btn">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-4 h-4"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                />
                            </svg>
                        </span>
                        <span class="text-sm text-dark-blue">Timezone</span>
                    </Link>
                    <Link
                        v-if="meta.form_type !== 'ENDPOINT'"
                        :href="
                            $route('form.settings.branding', { form: formId })
                        "
                        class="py-1 pl-[5px] text-body text-md rounded-lg font-semibold hover:text-blue-600 transition cursor-pointer flex items-center !px-2 self-start"
                        :class="
                            $route().current().includes('branding')
                                ? 'text-blue-500 bg-gray-100'
                                : 'text-gray-500'
                        "
                    >
                        <span class="mr-2 bg-white btn">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-4 h-4"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M4.098 19.902a3.75 3.75 0 0 0 5.304 0l6.401-6.402M6.75 21A3.75 3.75 0 0 1 3 17.25V4.125C3 3.504 3.504 3 4.125 3h5.25c.621 0 1.125.504 1.125 1.125v4.072M6.75 21a3.75 3.75 0 0 0 3.75-3.75V8.197M6.75 21h13.125c.621 0 1.125-.504 1.125-1.125v-5.25c0-.621-.504-1.125-1.125-1.125h-4.072M10.5 8.197l2.88-2.88c.438-.439 1.15-.439 1.59 0l3.712 3.713c.44.44.44 1.152 0 1.59l-2.879 2.88M6.75 17.25h.008v.008H6.75v-.008Z"
                                />
                            </svg>
                        </span>
                        <span class="text-sm text-dark-blue">Branding</span>
                        <span
                            v-if="!meta.subscribed"
                            :tooltip="'Upgrade to unlock'"
                            class="bg-pink-50 border border-pink-200 rounded p-0.5 ml-1"
                        >
                            <icon name="bolt" class="w-3 h-3 text-pink-500" />
                        </span>
                    </Link>

                    <Link
                        v-if="meta.form_type === 'FORM'"
                        :href="$route('form.settings.domain', formId)"
                        class="pl-[5px] text-body text-md rounded-lg font-semibold hover:text-blue-600 transition cursor-pointer flex items-center !py-[5px] !px-2 self-start"
                        :class="
                            $route().current().includes('domain')
                                ? 'text-blue-500 bg-gray-100'
                                : 'text-gray-500'
                        "
                    >
                        <span
                            class="mr-2 bg-white btn-shadow rounded py-1 justify-center flex !w-8"
                        >
                            <icon name="globe" class="w-4 h-4" />
                        </span>
                        <span class="text-sm text-dark-blue">Domain</span>
                        <span
                            v-if="!meta.subscribed"
                            :tooltip="'Upgrade to unlock'"
                            class="bg-pink-50 border border-pink-200 rounded p-0.5 ml-1"
                        >
                            <icon name="bolt" class="w-3 h-3 text-pink-500" />
                        </span>
                    </Link>
                    <Link
                        v-if="meta.form_type === 'FORM'"
                        :href="$route('form.settings.code-injection', formId)"
                        class="pl-[5px] text-body text-md rounded-lg font-semibold hover:text-blue-600 transition cursor-pointer flex items-center !py-[5px] !px-2 self-start"
                        :class="
                            $route().current().includes('code-injection')
                                ? 'text-blue-500 bg-gray-100'
                                : 'text-gray-500'
                        "
                    >
                        <span class="mr-2 bg-white btn">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-4 h-4"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5"
                                />
                            </svg>
                        </span>
                        <span class="text-sm text-dark-blue"
                            >Code injection</span
                        >
                        <span
                            v-if="!meta.subscribed"
                            :tooltip="'Upgrade to unlock'"
                            class="bg-pink-50 border border-pink-200 rounded p-0.5 ml-1"
                        >
                            <icon name="bolt" class="w-3 h-3 text-pink-500" />
                        </span>
                    </Link>
                    <Link
                        :href="$route('form.settings.data-retention', formId)"
                        class="pl-[5px] text-body text-md rounded-lg font-semibold hover:text-blue-600 transition cursor-pointer flex items-center !py-[5px] !px-2 self-start"
                        :class="
                            $route().current().includes('data-retention')
                                ? 'text-blue-500 bg-gray-100'
                                : 'text-gray-500'
                        "
                    >
                        <span class="mr-2 bg-white btn">
                            <svg
                                class="w-4 h-4"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4"
                                ></path>
                            </svg>
                        </span>
                        <span class="text-sm text-dark-blue"
                            >Data retention</span
                        >
                        <span
                            v-if="!meta.subscribed"
                            :tooltip="'Upgrade to unlock'"
                            class="bg-pink-50 border border-pink-200 rounded p-0.5 ml-1"
                        >
                            <icon name="bolt" class="w-3 h-3 text-pink-500" />
                        </span>
                    </Link>
                </div>

                <integrations-menu />
            </div>
            <div
                class="fixed w-full h-full backdrop-blur-lg bg-white bg-opacity-30 z-20"
                @click="sidebarOpen = false"
                :class="{
                    hidden: !sidebarOpen,
                }"
            ></div>
            <!-- Content-->
            <div class="flex-1 overflow-y-scroll">
                <button
                    class="sidebar-btn ml-3 mt-6 btn lg:hidden z-30"
                    :class="{
                        'fixed top-12 right-4': sidebarOpen,
                        hidden: sidebarOpen,
                        fixed: $route().current('form.settings.builder'),
                    }"
                    @click="toggleSidebar"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                        />
                    </svg>
                </button>
                <slot></slot>
            </div>
        </div>

        <jet-confirmation-modal
            :show="confirmPublishForm"
            @close="confirmPublishForm = false"
        >
            <template #title>
                {{ publishFormText }} <strong>{{ meta.name }}</strong></template
            >

            <template v-if="meta.published" #content>
                Are you sure you want to unpublish this form?</template
            >
            <template #content>
                Are you sure you want to publish this form? Once published, the
                form can be viewed by everyone with a link to it.</template
            >

            <template #footer>
                <jet-secondary-button @click="confirmPublishForm = false">
                    Cancel
                </jet-secondary-button>

                <jet-button
                    class="ml-2"
                    @click="handlePublishForm"
                    :class="{ 'opacity-25': publishForm.processing }"
                    :disabled="publishForm.processing"
                >
                    {{ publishFormText }}
                </jet-button>
            </template>
        </jet-confirmation-modal>
    </div>
</template>

<script setup>
import IntegrationsMenu from '@/Components/IntegrationsMenu.vue'
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import JetDropdownLink from '@/Jetstream/DropdownLink.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetDropdown from '@/Jetstream/Dropdown.vue'
import { usePage, useForm, Link, router } from '@inertiajs/vue3'
import { computed, ref } from 'vue'
import Icon from '@/Components/Icon.vue'

const confirmPublishForm = ref(false)

const publishForm = useForm({
    _method: 'PATCH',
})

const meta = usePage().props.meta
const publishFormText = computed(
    () => `${meta?.published ? 'Unpublish' : 'Publish'} form`
)
const formId = computed(() => meta.form_uuid)
const sidebarOpen = ref(false)

const handlePublishForm = () => {
    publishForm.patch(
        route('forms.publish', {
            form: formId.value,
        }),
        {
            preserveState: false,
            preserveScroll: true,
            onSuccess: () => {
                confirmPublishForm.value = false
            },
        }
    )
}

const toggleSidebar = () => {
    sidebarOpen.value = !sidebarOpen.value
}

router.on('start', () => {
    sidebarOpen.value = false
})
</script>
<style scoped>
::-webkit-scrollbar {
    display: none;
}

.sidebar {
    background: #fff;
    scrollbar-width: none;
}
</style>
