<template>
    <div class="flex items-center">
        <label :for="id" class="flex items-center cursor-pointer">
            <!-- toggle -->
            <div class="relative">
                <!-- input -->
                <input type="checkbox" :id="id" class="sr-only" :value="value" v-model="proxyChecked" />
                <!-- line -->
                <div
                    class="block w-10 h-6 rounded-full"
                    :class="{
                        'bg-green-500': proxyChecked,
                        'bg-gray-400': !proxyChecked,
                    }"
                ></div>
                <!-- dot -->
                <div class="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
            </div>
            <!-- label -->
            <div class="ml-3 text-gray-700 text-sm" v-if="label" v-text="label" />
            <div class="ml-3 text-sm" v-else>
                <slot />
            </div>
        </label>
    </div>
</template>
<script>
import Checkbox from '@/Jetstream/Checkbox.vue'

export default {
    emits: ['update:checked'],

    props: {
        checked: {
            type: [Array, Boolean],
            default: false,
        },
        value: {
            default: null,
        },
        label: {
            type: String,
        },
        id: String,
    },

    components: {
        Checkbox,
    },

    computed: {
        proxyChecked: {
            get() {
                return this.checked
            },

            set(val) {
                console.log('value', val)
                this.$emit('update:checked', val)
            },
        },
    },
}
</script>
<style scoped>
input:checked ~ .dot {
    transform: translateX(100%);
    background-color: #fff;
}
</style>
