<template>
    <div class="flex text-sm text-gray-500">
        <icon name="calendar" class="w-5 h-5 mr-1" />
        <span v-if="value == null || !value"> No date range </span>
        <span v-else-if="typeof value === 'object'"> {{ formatDate(value?.start) }} - {{ formatDate(value?.end) }} </span>
        <span v-else-if="typeof value === 'string' && type === 'date_time'">
            {{ formatDate(value, (isDateTime = true)) }}
        </span>
        <span v-else-if="typeof value === 'string' && type === 'date'">
            {{ formatDate(value) }}
        </span>
        <span v-else>Unformattable date</span>
    </div>
</template>
<script setup>
import Icon from '@/Components/Icon.vue'
import { formatInTimeZone } from 'date-fns-tz'

const props = defineProps({
    value: [String, Object],
    type: [String, null],
    timezone: String,
})

const formatDate = (date, isDateTime) => {
    if (isDateTime) {
        return formatInTimeZone(date, props.timezone, 'dd/MM/yyyy HH:mm:s zzz')
    }

    return formatInTimeZone(date, props.timezone, 'dd/MM/yyyy')
}
</script>
