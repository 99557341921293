<template>
    <combobox :class="{ 'opacity-50 cursor-not-allowed': disabled }" v-model="selected">
        <div class="relative mt-1">
            <div
                class="relative w-full overflow-hidden text-xs text-left bg-white rounded-lg cursor-default btn-shadow focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300"
            >
                <combobox-input
                    ref="input"
                    id="fieldRulesSearch"
                    autocomplete="off"
                    :disabled="disabled"
                    class="w-full py-1 pl-2 pr-10 text-xs leading-5 text-gray-500 border-none focus:ring-0"
                    :class="{ 'cursor-not-allowed': disabled }"
                    :displayValue="displayValue"
                    @change="onChange"
                />
                <combobox-button class="absolute inset-y-0 right-0 flex items-center pr-2">
                    <icon name="search" class="w-4 h-4 text-gray-400" />
                </combobox-button>
            </div>
            <transition-root leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" @after-leave="query = ''">
                <combobox-options
                    class="absolute w-full py-1 mt-1 overflow-auto text-xs bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black/5 focus:outline-none"
                >
                    <div v-if="filteredRules.length === 0 && query !== ''" class="relative px-4 py-1 text-xs text-gray-700 cursor-default select-none">
                        Nothing found.
                    </div>

                    <combobox-option v-for="rule in filteredRules" as="template" :key="rule.name" :value="rule" v-slot="{ selected, active }">
                        <li
                            class="relative px-2 py-1 mx-1 rounded-md cursor-default select-none"
                            :class="{
                                'bg-blue-50 text-gray-900 ': active,
                                'text-gray-500': !active,
                            }"
                        >
                            <span class="block text-xs truncate" :class="{ 'font-medium': selected, 'font-normal': !selected }">
                                {{ rule?.label }}
                            </span>
                            <span
                                v-if="selected"
                                class="absolute inset-y-0 right-0 flex items-center pr-3"
                                :class="{ 'text-white': active, 'text-blue-600': !active }"
                            >
                                <CheckIcon class="w-5 h-5" aria-hidden="true" />
                            </span>
                        </li>
                    </combobox-option>
                </combobox-options>
            </transition-root>
        </div>
    </combobox>
</template>

<script setup>
import { ref, computed, watch, defineEmits, defineProps, nextTick } from 'vue'
import { Combobox, ComboboxInput, ComboboxButton, ComboboxOptions, ComboboxOption, TransitionRoot } from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/outline'
import rules from '@/field-rules'
import Icon from '@/Components/Icon.vue'

const fieldRules = rules

let selected = ref(null)
let query = ref('')
const input = ref(null)

let filteredRules = computed(() =>
    query.value === ''
        ? fieldRules
        : fieldRules.filter((rule) => rule.label.toLowerCase().replace(/\s+/g, '').includes(query.value.toLowerCase().replace(/\s+/g, '')))
)

const displayValue = (rule) => rule?.label || ''

const props = defineProps({
    modelValue: Object,
    disabled: Boolean,
})

const emit = defineEmits(['update:modelValue'])

const onChange = (event) => {
    query.value = event.target.value
}

watch(selected, (value) => {
    query.value = ''
    nextTick(() => {
        input.value.$el.value = ''
    })

    if ('min_value' in value) value.min_value = null
    if ('max_value' in value) value.max_value = null
    if ('value' in value) value.value = null
    if ('error' in value) value.error = null

    emit('update:modelValue', value)
})
</script>
