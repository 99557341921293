<template>
    <div class="relative">
        <form-builder-step-navigation
            v-if="formStepsCount > 1"
            :current-step="currentStep"
            :steps="formStepsCount"
            @next="currentStep += 1"
            @prev="currentStep -= 1"
        />

        <h3
            class="mb-4 leading-relaxed cursor-pointer hover:text-blue-500"
            @click="openEditFormNameModal = true"
        >
            {{ form.name }}
        </h3>
        <form
            v-if="fieldsCount > 0"
            class="space-y-4"
            method="post"
            @submit.prevent=""
            autocomplete="off"
        >
            <form-builder-draggable
                @change="handleLayoutChange"
                :value="layout"
            >
                <template v-slot="{ element: item, index }">
                    <div class="relative group">
                        <div
                            class="absolute w-full h-full space-x-2 transform opacity-0 hidden transition-transform group-hover:opacity-100 group-hover:flex items-center mt-2 -left-[160px]"
                        >
                            <!-- Remove button -->
                            <button
                                :data-field="item.name"
                                flow="up"
                                tooltip="Remove"
                                class="p-1 rounded btn"
                            >
                                <icon
                                    name="trash"
                                    class="w-4 h-4"
                                    @click="handleRemoveField(item, index)"
                                />
                            </button>

                            <!-- Settings button -->
                            <button
                                v-if="
                                    item.name === 'stack' ||
                                    [
                                        'checkbox',
                                        'select',
                                        'radio',
                                        'date',
                                    ].includes(item.type)
                                "
                                tooltip="Settings"
                                flow="up"
                                class="p-1 rounded btn field-settings"
                                @click="
                                    item.name === 'stack'
                                        ? editStackModal(item)
                                        : changeCurrentField(item)
                                "
                            >
                                <icon name="cog" class="w-4 h-4" />
                            </button>

                            <!-- Dropdown button (add) -->
                            <button
                                :data-field="item.name"
                                flow="up"
                                tooltip="Add"
                                class="p-1 rounded btn"
                            >
                                <icon
                                    name="plus"
                                    class="w-4 h-4"
                                    @click="displayDropdown"
                                />
                            </button>

                            <!-- Change step button -->
                            <button
                                v-if="formStepsCount > 1"
                                type="button"
                                :data-field="item.name"
                                flow="up"
                                tooltip="Change step"
                                @click="fieldChangeStepModal = { item, index }"
                                class="btn flex justify-center"
                            >
                                <icon
                                    name="chevron-up-down"
                                    class="rotate-90 w-4 h-4 fill-gray-800"
                                />
                            </button>
                        </div>

                        <div class="popper-wrapper">
                            <vue-popper placement="top">
                                <pf-field-renderer
                                    class="form-builder-field !block"
                                    :postFocused="onFocusField"
                                    :data-field="item.name"
                                    :field="item"
                                    :form="userForm"
                                    :form-builder="true"
                                />
                            </vue-popper>
                        </div>
                    </div>
                </template>
            </form-builder-draggable>
        </form>

        <form-builder-actions
            :has-fields="currentStepChildren.length > 0"
            :delete-form-step="deleteFormStep"
            :add-form-step="addFormStep"
            :addField="displayDropdown"
            :show-delete-button="showDeleteFormStepButton"
            :can-add-form-step="canAddFormStep"
        />

        <!-- Row modal -->
        <form-builder-modal :show="addRowModal" @close="addRowModal = false">
            <template #title>Add row</template>
            <template #content>
                <add-row-field-form
                    :current-step="currentStep"
                    @close="onCloseAddRowModal"
                    @save-layout="saveLayout"
                    :fields="form.fields"
                    :layout="form.layout"
                />
            </template>
        </form-builder-modal>

        <!-- Stack modal -->
        <form-builder-modal :show="stackModal" @close="stackModal = false">
            <template #content>
                <add-stack-field-form
                    :current-step="currentStep"
                    :stack="currentStack"
                    :available-fields="
                        form.fields.map((f) => ({
                            label: f.label,
                            id: f.id,
                            value: f,
                            name: f.name,
                        }))
                    "
                    @close="onCloseAddStackModal"
                    @save-layout="saveLayout"
                    :layout="form.layout"
                />
            </template>
        </form-builder-modal>

        <!-- Context menu -->
        <context-menu-modal
            v-if="contextMenuModal"
            @close="contextMenuModal = false"
            max-width="sm"
            :options="filteredOptions"
            :selected="selectedIndex"
            :add-block="addBlock"
            :remove-field="removeField"
            :layout="form.layout"
        />

        <field-settings-modal
            :show="currentField"
            @close="currentField = null"
            max-width="xl"
        >
            <template #content>
                <field-settings-popup
                    @done="onDone"
                    @cancel="currentField = null"
                    :current-field="currentField"
                    :all-field-rules="fieldRules"
                    :change-current-field="changeCurrentField"
                />
            </template>
        </field-settings-modal>

        <form-name-edit-form
            v-if="openEditFormNameModal"
            @close="openEditFormNameModal = false"
            :show="openEditFormNameModal"
            :form-name="form.name"
        />

        <change-step-for-field
            v-if="fieldChangeStepModal"
            :current-step-field="fieldChangeStepModal"
            @close="fieldChangeStepModal = null"
            :show="!!fieldChangeStepModal"
            @changeStepForField="changeStepForField"
        />
    </div>
</template>
<script>
import Spotlight from '@/Components/Spotlight.vue'
import PfFieldRenderer from '@/Pixelform/FieldRenderer.vue'
import allFieldRules from '@/field-rules.json'
import FieldSettingsPopup from '@/Components/FieldSettingsPopup.vue'
import FormBuilderModal from '@/Components/FormBuilderModal.vue'
import ContextMenuModal from '@/Components/ContextMenuModal.vue'
import AddRowFieldForm from '@/Components/AddRowFieldForm.vue'
import AddStackFieldForm from '@/Components/AddStackFieldForm.vue'
import FieldSettingsModal from '@/Components/FieldSettingsModal.vue'
import FieldIcon from '@/Components/FieldIcon.vue'
import Icon from './Icon.vue'
import VuePopper from 'vue3-popper'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronUpIcon } from '@heroicons/vue/solid'
import FormBuilderActions from '@/Components/FormBuilderActions.vue'
import FormBuilderStepNavigation from '@/Components/FormBuilderStepNavigation.vue'
import { useForm } from '@inertiajs/vue3'
import FormNameEditForm from '@/Components/Builder/FormNameEditForm.vue'
import ChangeStepForField from '@/Components/Builder/ChangeStepForField.vue'
import FormBuilderDraggable from '@/Components/FormBuilderDraggable.vue'

export default {
    components: {
        FormBuilderDraggable,
        Spotlight,
        PfFieldRenderer,
        Icon,
        FieldSettingsPopup,
        FormBuilderModal,
        AddRowFieldForm,
        AddStackFieldForm,
        FieldIcon,
        VuePopper,
        ContextMenuModal,
        FieldSettingsModal,
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        ChevronUpIcon,
        FormBuilderActions,
        FormBuilderStepNavigation,
        FormNameEditForm,
        ChangeStepForField,
    },

    props: ['form'],

    emits: ['update'],

    data() {
        return {
            allFieldRules,
            dropdownMenu: false,
            fieldsPanel: false,
            contextMenuModal: false,
            openEditFormNameModal: false,
            options: [
                {
                    type: 'text',
                    label: 'Text field',
                },
                {
                    type: 'textarea',
                    label: 'Large text',
                },
                {
                    type: 'email',
                    label: 'Email address',
                },
                {
                    type: 'file',
                    label: 'File upload',
                },
                {
                    type: 'select',
                    label: 'Select',
                },
                {
                    type: 'checkbox',
                    label: 'Checkbox',
                },
                {
                    type: 'radio',
                    label: 'Single choice',
                },
                {
                    type: 'date',
                    label: 'Date',
                },
                {
                    type: 'date_time',
                    label: 'Date & time',
                },
                {
                    type: 'date_range',
                    label: 'Date range',
                },
                {
                    type: 'payment',
                    label: 'Payment',
                },
                {
                    type: 'signature',
                    label: 'Signature',
                },
            ],

            dropdownRef: null,
            elRow: null,
            search: '',

            selectedIndex: 0,

            userForm: useForm({
                _token: this.form.token,
                _honeypot: '',
            }),

            tab: 'settings',

            currentField: null,
            addRowModal: false,
            stackModal: false,
            currentStack: null,
            fieldChangeStepModal: false,

            keyMap: {},

            currentStep: 0,
        }
    },
    mounted() {
        document.addEventListener('keydown', this.handleKeydown)
    },
    computed: {
        formStepsCount() {
            return this.form.layout.children.length || 0
        },
        canAddFormStep() {
            if (this.form.layout.type === 'SINGLE_STEP') return false

            const stepFields =
                this.form.layout.children[this.currentStep].children

            return stepFields.length !== 0
        },
        showDeleteFormStepButton() {
            return this.form.layout.children.length > 1
        },
        filteredOptions() {
            if (this.search === '' && this.$page.props.auth.user)
                return this.options
            if (this.search === '' && !this.$page.props.auth.user)
                return this.options.filter((o) => o.type !== 'payment')

            const search = this.search.toLowerCase()
            this.selectedIndex = 0

            const options = this.options.filter((o) =>
                o.label.toLowerCase().includes(search)
            )

            if (!this.$page.props.auth.user) {
                return options.filter((o) => o.type !== 'payment')
            }

            return options
        },

        layout() {
            return this.form.layout?.children[this.currentStep].children
        },

        fieldRules() {
            return allFieldRules
        },

        fieldsCount() {
            return this.form.fields.length
        },
        currentStepChildren() {
            return this.form.layout.children[this.currentStep].children
        },
    },
    methods: {
        handleLayoutChange(data) {
            // clone the current layout and update the children at the currrent step with data
            this.form.layout.children[this.currentStep].children = data
            this.saveLayout()
        },
        changeStepForField({ field, step }) {
            const stepIndex = step - 1
            if (
                stepIndex >= 0 &&
                stepIndex < this.form.layout.children.length
            ) {
                this.form.layout.children[stepIndex].children.push(field)

                const fieldIndex = this.form.layout.children[
                    this.currentStep
                ].children.findIndex((f) => f._id === field._id)
                this.form.layout.children[this.currentStep].children.splice(
                    fieldIndex,
                    1
                )
            }

            this.fieldChangeStepModal = null
            this.saveLayout()
        },
        onFocusField(field) {
            this.currentField = field
        },
        changeCurrentField(obj) {
            this.$nextTick(() => {
                this.currentField = obj
                this.$forceUpdate()
            })
        },

        editStackModal(item) {
            this.currentStack = item
            this.stackModal = true
        },

        onTabChange(name) {
            this.tab = name

            // focus on search input if we're on the rules tab
            if (name === 'rules') {
                this.$nextTick(() => {
                    document.querySelector('.search-rules-input').focus()
                })
            }
        },

        async onDone(params = { closeModal: true }) {
            try {
                this.currentField.errors = null

                const res = await axios.post(
                    route('builder.field.update', {
                        form: this.form._id,
                        field: this.currentField._id,
                    }),
                    this.currentField
                )

                this.$nextTick(() => {
                    if (!params?.closeModal === false) {
                        this.currentField = null
                    }

                    // update local fields with the fresh field
                    let fields = [...this.form.fields].map((f) => {
                        if (f._id === res.data.field._id) {
                            return res.data.field
                        }

                        return f
                    })

                    this.$emit('update', {
                        fields,
                        layout: res.data.layout,
                    })
                })
            } catch (err) {
                this.currentField.errors = err.response.data.errors
            }
        },

        async handleRemoveField({ _id, name, children }, index) {
            let layout = { ...this.form.layout }
            if (name === 'stack') {
                layout.children[this.currentStep].children = layout.children[
                    this.currentStep
                ].children.filter((stack) => stack._id !== _id)

                for (const child of Array.from(children).reverse()) {
                    if (child?.name === 'row' && child?.children) {
                        layout.children[this.currentStep].children = [
                            ...child.children,
                            ...layout.children[this.currentStep].children,
                        ]
                    } else {
                        layout.children[this.currentStep].children = [
                            child,
                            ...layout.children[this.currentStep].children,
                        ]
                    }
                }
            } else if (name === 'row') {
                // layout.children[this.currentStep].children = layout.children[this.currentStep].children.filter((row) => row._id !== _id)
                layout.children[this.currentStep].children.splice(index, 1)
                layout.children[this.currentStep].children = [
                    ...children,
                    ...(layout.children[this.currentStep].children || []),
                ]
            } else {
                for (let i = 0; i < layout.children.length; i++) {
                    let ii = layout.children[i]
                    for (let j = 0; j < ii.children.length; j++) {
                        let jj = ii.children[j]
                        if (jj._id === _id) {
                            delete ii.children[j]
                            ii.children = ii.children.filter(Boolean)
                        }
                    }
                }
            }

            if (name !== 'row' && name !== 'stack') {
                await this.removeField(_id, true)
            }

            await this.saveLayout()
        },

        handleKeydown(e) {
            this.keyMap[e.code] = true
            if (this.keyMap?.MetaLeft && e.code !== 'KeyE') {
                this.keyMap = {}
            } else if (
                this.keyMap?.MetaLeft &&
                this.keyMap?.KeyE &&
                !this.currentField &&
                !this.stackModal
            ) {
                // cmd + e
                this.displayDropdown()
                this.keyMap = {}
            } else if (e.keyCode === 191) {
                // '/'
                this.displayDropdown()
                this.keyMap = {}
            } else if (this.dropdownMenu && this.keyMap?.Escape) {
                // esc
                this.dropdownMenu = false
                this.keyMap = {}
            } else if (this.dropdownMenu && this.keyMap?.ArrowDown) {
                // arrow down
                if (this.filteredOptions.length - 1 > this.selectedIndex) {
                    this.selectedIndex += 1
                }
                this.keyMap = {}
            } else if (this.dropdownMenu && this.keyMap?.ArrowUp) {
                // arrow up
                if (this.selectedIndex > 0) {
                    this.selectedIndex -= 1
                }
                this.keyMap = {}
            } else if (this.dropdownMenu && e.keyCode === 13) {
                this.handleSelectedField()
            } else if (this.currentField && e.code === 'Escape') {
                this.changeCurrentField(null)
            }
        },

        handleSelectedField() {
            const field = this.filteredOptions[this.selectedIndex]

            if (!field) return false

            if (field.type === 'row') {
                this.addRowModal = true
            } else if (field.type === 'stack') {
                this.stackModal = true
                this.currentStack = null
            } else {
                this.addBlock(field.type)
            }
        },

        async addBlock(type) {
            if (!type) return false

            if (type === 'row') {
                this.contextMenuModal = false
                this.addRowModal = true
                return
            } else if (type === 'stack') {
                this.contextMenuModal = false
                this.stackModal = true
                return
            }

            let field = this.options.find((o) => o.type === type)

            await this.$nextTick(async () => {
                let f = {
                    classNames: null,
                    helpBlock: null,
                    inputValue: null,
                    label: field.label,
                    name: type + Math.floor(Math.random() * 100),
                    options: [],
                    rules: [],
                    type,
                }
                if (field.type === 'radio') {
                    f.options = [
                        {
                            title: 'Option 1',
                            value: 'option-1',
                            helpBlock: 'Supporting text for option 1',
                        },
                        {
                            title: 'Option 2',
                            value: 'option-2',
                            helpBlock: 'Supporting text for option 2',
                        },
                        { title: 'Option 3', value: 'option-3', helpBlock: '' },
                    ]
                }

                const fieldData =
                    type === 'payment'
                        ? {
                              name: 'payment',
                              type: 'payment',
                              label: 'Payment',
                              options: [],
                              rules: [],
                              //   rules: allFieldRules.filter((r) => r.name === 'required'),
                              inputValue: null,
                              helpBlock: null,
                              classNames: null,
                              price: 2500,
                              currency: 'GBP',
                          }
                        : f

                const res = await axios.post(
                    route('builder.fields', {
                        form: this.form._id,
                    }),
                    fieldData
                )

                if (res.status === 201) {
                    await this.$nextTick(() => {
                        this.form.fields = res.data.allFields
                        this.form?.layout?.children[
                            this.currentStep
                        ]?.children.push(res.data.field)
                        this.dropdownMenu = false
                    })
                } else if (res.status === 422) {
                    alert('Please fill in the form correctly.')
                }

                this.contextMenuModal = false

                await this.saveLayout()
            })
        },

        // update field names in form layout children
        // updateFieldProperties() {
        //   let layout = {...this.form.layout}
        //
        //   for (let j = 0; j < layout.children.length; j++) {
        //     const steps = layout.children[j]
        //
        //     for (let i = 0; i < steps.children.length; i++) {
        //       const step = steps.children[i]
        //       for (let z = 0; z < step?.children?.length || 0; z++) {
        //         const child = step.children[z]
        //         if (child.name === 'row' || child.name === 'stack') {
        //           for (let k = 0; k < child.children.length; k++) {
        //             const rowChild = child.children[k]
        //             const field = this.form.fields.find((f) => f._id === rowChild._id)
        //             rowChild.name = field.name
        //             rowChild.options = field?.options
        //           }
        //         } else {
        //           const field = this.form.fields.find((f) => f._id === child._id)
        //           child.name = field.name
        //           child.options = field?.options
        //         }
        //       }
        //     }
        //   }
        //
        //   this.form.layout = layout
        // },

        async saveLayout(data) {
            try {
                const layout = this.cleanupLayout(
                    data || this.form.layout.children
                )
                const res = await axios.post(
                    route('builder.layout', {
                        form: this.form._id,
                    }),
                    { layout }
                )
                await this.$nextTick(() => {
                    this.form.layout = res.data.layout
                    this.currentField = null
                })
            } catch (err) {
                console.log(JSON.stringify(err))
                console.log('err.response', err.response)
                const error = err.response?.data?.error

                alert(error ?? 'Could not save layout.')
            }
        },

        /**
         * Remove empty rows and stacks from layout
         * @param {Array} layout
         * @returns {Array}
         */
        cleanupLayout(layout) {
            for (let i = 0; i < layout.length; i++) {
                const step = layout[i]
                for (let j = 0; j < step.children.length; j++) {
                    const child = step.children[j]
                    if (child.name === 'row' || child.name === 'stack') {
                        if (child.children.length === 0) {
                            step.children.splice(j, 1)
                        }
                    }
                }
            }

            return layout
        },

        onCloseAddRowModal() {
            this.addRowModal = false
            this.dropdownMenu = false
        },

        onCloseAddStackModal() {
            this.stackModal = false
            this.dropdownMenu = false
            this.currentStack = null
        },

        async removeField(_id, removeFromLayout = false) {
            try {
                const res = await axios.delete(
                    route('builder.field.delete', {
                        form: this.form._id,
                        field: _id,
                    }),
                    {
                        data: {
                            removeFromLayout,
                        },
                    }
                )
                this.form.fields = res.data.fields
                if (res.data.fields.length === 0) {
                    this.form.layout.children[0].children = []
                }

                if (removeFromLayout) {
                    this.form.layout = res.data.layout
                }

                this.$emit('update', {
                    fields: res.data.fields,
                    layout: res.data.layout,
                })

                return true
            } catch (err) {
                console.log('err', err)
                alert('Could not delete field.')
                return false
            }
        },
        /**
         * Show the context menu below cursor
         * and autofocus the hidden input so user can search through menu
         */
        displayDropdown() {
            this.dropdownMenu = !this.dropdownMenu
            this.contextMenuModal = !this.contextMenuModal
        },

        addFormStep() {
            if (!this.canAddFormStep) return false

            const totalSteps = this.form?.layout?.children?.length

            const layout = [...this.form.layout.children]
            const newStep = {
                children: [],
                submit_button: 'Submit',
                title: 'Step',
            }

            // insert the new step before the last step in layout
            const hasPaymentField = this.form.fields.find(
                (f) => f.type === 'payment'
            )
            if (hasPaymentField) {
                layout.splice(totalSteps - 1, 0, newStep)
            } else {
                layout.push(newStep)
            }

            this.saveLayout(layout).then(() => {
                this.$nextTick(() => {
                    // after adding a new step when we have a payment field we need to set the current step to the second last step
                    if (hasPaymentField) {
                        this.currentStep = layout.length - 2
                    } else {
                        this.currentStep = layout.length - 1
                    }
                })
            })
        },

        getFieldsFromStepRecursively(children, fields = []) {
            for (const child of children) {
                if (['row', 'stack'].includes(child.name)) {
                    this.getFieldsFromStepRecursively(child.children, fields)
                } else {
                    fields.push(child._id)
                }
            }

            return fields
        },

        deleteFormStep() {
            if (this.form.layout.children.length === 1) {
                alert('You cannot delete the last step.')
                return
            }

            const confirmed = confirm(
                "Are you sure you want to delete this step and all it's fields?"
            )

            if (!confirmed) return false

            const children =
                this.form.layout.children[this.currentStep].children

            const fieldNames = this.getFieldsFromStepRecursively(children)

            const promises = []
            for (const fieldName of fieldNames) {
                promises.push(this.removeField(fieldName))
            }

            Promise.allSettled(promises).then(() => {
                this.form.layout.children.splice(this.currentStep, 1)
                // set the current step to one less than the current step if we're on the last step
                if (this.currentStep > 0) {
                    this.currentStep -= 1
                }

                this.saveLayout()
            })
        },
    },

    beforeUnmount() {
        document.removeEventListener('keydown', this.handleKeydown)

        document.removeEventListener('keydown', this.selectField)
    },
}
</script>
<style lang="css" scoped>
/* this is a sin */
.popper-wrapper > div.inline-block {
    display: block;
}
</style>
