<template>
    <div>
        <svg
            aria-hidden="true"
            class="absolute z-0 inset-x-0 top-0 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_center,white,transparent)]"
        >
            <defs>
                <pattern id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
                    <path d="M.5 200V.5H200" fill="none"></path>
                </pattern>
            </defs>
            <svg x="50%" y="-1" class="overflow-visible fill-gray-50">
                <path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z" stroke-width="0"></path>
            </svg>
            <rect width="100%" height="100%" stroke-width="0" fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"></rect>
        </svg>
        <div class="max-w-screen-lg px-4 py-10 mx-auto mb-4 relative">
            <div class="z-10 sm:px-6 mx-auto max-w-7xl lg:px-8">
                <div class="max-w-sm sm:max-w-lg sm:mx-auto sm:text-center flex flex-col items-start sm:items-center">
                    <h1 class="text-xs sm:text-base font-semibold leading-3 sm:leading-7 text-blue-600">Your data collection needs in one place</h1>
                    <p class="text-xl mt-2 font-bold tracking-tight text-gray-900 sm:text-3xl">Build automated forms for your needs in seconds</p>

                    <button @click="createFormModal = true" class="flex items-center px-3 py-2 bg-blue-600 rounded-full font-regular group mt-4 sm:mt-10">
                        <div class="p-1 mr-2 transition bg-blue-700 rounded-full group-hover:bg-white">
                            <svg
                                class="w-4 h-4 text-white group-hover:hidden"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                            </svg>
                            <span class="hidden w-4 h-4 text-sm leading-tight text-white group-hover:block group-hover:text-blue-600">C</span>
                        </div>
                        <span class="text-sm font-semibold text-white">Create new form</span>
                    </button>
                </div>
            </div>

            <div class="mt-10 ring-1 ring-gray-300 md:mx-0 rounded-lg" v-if="$page.props.forms.data.length">
                <table class="min-w-full divide-y divide-gray-300 rounded-lg bg-gradient-to-b from-white to-white">
                    <thead>
                        <tr>
                            <th scope="col" class="hidden lg:table-cell py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Timezone</th>
                            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Name</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                            <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Type</th>
                            <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Entries</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Created</th>
                            <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                <span class="sr-only">Edit</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="false" class="sm:hidden" v-for="(form, formIdx) in $page.props.forms.data" :key="form.id">
                            <td>
                                <div class="">
                                    <!-- Timezone -->
                                    <div>
                                        <Link
                                            :href="$route('form.settings.timezone', { form: form._id })"
                                            class="underline underline-offset-4 decoration-slate-200 hover:text-slate-700 hover:decoration-slate-600"
                                        >
                                            <span>{{ form.timezone }}</span>
                                            <div v-if="formIdx !== 0" class="absolute right-0 h-px bg-gray-200 left-6 -top-px" />
                                        </Link>
                                    </div>

                                    <!-- Name -->
                                    <div>
                                        <Link :href="$route('forms.inbox', form)" class="font-medium text-gray-900 transition hover:text-blue-600">
                                            {{ form.name }}
                                        </Link>
                                        <div v-if="formIdx !== 0" class="absolute right-0 h-px bg-gray-200 left-6 -top-px" />
                                    </div>

                                    <!-- Status -->
                                    <div>
                                        <div class="flex items-center">
                                            <icon v-if="form.published" name="eye" class="w-5 h-5 text-blue-500" />
                                            <icon v-else name="eye-off" class="w-5 h-5 text-blue-500" />
                                            <span class="ml-1">{{ form.published ? 'Published' : 'Unpublished' }}</span>
                                        </div>
                                    </div>

                                    <!-- Type -->
                                    <div>
                                        <span
                                            v-if="!form?.layout"
                                            class="items-center space-x-2 bg-pink-50 py-1 px-2 rounded-full inline-flex text-pink-600 font-semibold text-xs"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="currentColor"
                                                class="w-5 h-5"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5"
                                                />
                                            </svg>
                                            <span>Endpoint</span>
                                        </span>
                                        <span
                                            v-else
                                            class="items-center space-x-2 bg-blue-50 py-1 px-2 rounded-full inline-flex text-blue-600 font-semibold text-xs"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="currentColor"
                                                class="w-5 h-5"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z"
                                                />
                                            </svg>
                                            <span>{{ form?.layout?.type === 'MULTI_STEP' ? 'Multi-step form' : 'Single-step form' }}</span>
                                        </span>
                                    </div>

                                    <!-- Entries -->
                                    <div>{{ form.entries_count.toLocaleString() }} {{ form.entries_count === 1 ? 'entry' : 'entries' }}</div>

                                    <!-- Created -->
                                    <div>
                                        {{ format(new Date(form.created_at), 'dd MMMM yyyy') }}
                                    </div>

                                    <!-- Actions -->
                                    <div
                                        :class="[
                                            formIdx === 0 ? '' : 'border-t border-transparent',
                                            'relative py-3.5 pl-3 pr-4 sm:pr-6 text-right text-sm font-medium',
                                        ]"
                                    >
                                        <Menu as="div" class="relative inline-block text-left">
                                            <MenuButton class="p-0.5 hover:shadow-md w-8 flex items-center justify-center rounded-md mr-3 relative top-1">
                                                <DotsHorizontalIcon class="w-4 h-4 text-gray-500 hover:text-gray-800" aria-hidden="true" />
                                            </MenuButton>
                                            <transition
                                                enter-active-class="transition duration-100 ease-out"
                                                enter-from-class="transform scale-95 opacity-0"
                                                enter-to-class="transform scale-100 opacity-100"
                                                leave-active-class="transition duration-75 ease-in"
                                                leave-from-class="transform scale-100 opacity-100"
                                                leave-to-class="transform scale-95 opacity-0"
                                            >
                                                <MenuItems
                                                    class="absolute right-0 z-50 w-32 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                >
                                                    <div class="px-1 py-1">
                                                        <MenuItem v-slot="{ active }">
                                                            <Link
                                                                :href="$route('forms.inbox', form)"
                                                                :class="[
                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                                                                    'group flex w-full items-center rounded-md px-2 py-1 text-xs',
                                                                ]"
                                                            >
                                                                <icon name="pencil" class="w-4 h-4 mr-2 text-body" /> <span>View</span>
                                                            </Link>
                                                        </MenuItem>
                                                        <MenuItem v-slot="{ active }">
                                                            <button
                                                                @click.prevent="exportEntries(form)"
                                                                :class="[
                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                                                                    'group flex w-full items-center rounded-md px-2 py-1 text-xs',
                                                                ]"
                                                            >
                                                                <icon name="cloud-download" class="w-4 h-4 mr-2 text-body" />Export
                                                            </button>
                                                        </MenuItem>
                                                        <MenuItem v-slot="{ active }">
                                                            <button
                                                                @click.prevent="confirmRemoveForm(form)"
                                                                :class="[
                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                                                                    'group flex w-full items-center rounded-md px-2 py-1 text-xs',
                                                                ]"
                                                            >
                                                                <icon name="trash" class="w-4 h-4 mr-2 text-body" /><span>Remove</span>
                                                            </button>
                                                        </MenuItem>
                                                    </div>
                                                </MenuItems>
                                            </transition>
                                        </Menu>
                                        <div v-if="formIdx !== 0" class="absolute left-0 h-px bg-gray-200 right-6 -top-px" />
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr v-for="(form, formIdx) in $page.props.forms.data" :key="form.id">
                            <!-- Timezone -->
                            <td :class="[formIdx === 0 ? '' : 'border-t border-transparent', 'relative py-4 pl-4 sm:pl-6 pr-3 text-sm hidden lg:table-cell']">
                                <Link
                                    :href="$route('form.settings.timezone', { form: form._id })"
                                    class="underline underline-offset-4 decoration-slate-200 hover:text-slate-700 hover:decoration-slate-600"
                                >
                                    <span>{{ form.timezone }}</span>
                                    <div v-if="formIdx !== 0" class="absolute right-0 h-px bg-gray-200 left-6 -top-px" />
                                </Link>
                            </td>

                            <!-- Name -->
                            <td :class="[formIdx === 0 ? '' : 'border-t border-gray-200', 'px-3 py-3.5 text-sm text-gray-500']">
                                <Link :href="$route('forms.inbox', form)" class="font-medium text-gray-900 transition hover:text-blue-600">
                                    {{ form.name }}
                                </Link>
                                <div v-if="formIdx !== 0" class="absolute right-0 h-px bg-gray-200 left-6 -top-px" />
                            </td>

                            <!-- Status -->
                            <td :class="[formIdx === 0 ? '' : 'border-t border-gray-200', 'px-3 py-3.5 text-sm text-gray-500']">
                                <div class="flex items-center">
                                    <icon v-if="form.published" name="eye" class="w-5 h-5 text-blue-500" />
                                    <icon v-else name="eye-off" class="w-5 h-5 text-blue-500" />
                                    <span class="ml-1">{{ form.published ? 'Published' : 'Unpublished' }}</span>
                                </div>
                            </td>

                            <!-- Type -->
                            <td :class="[formIdx === 0 ? '' : 'border-t border-gray-200', 'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell']">
                                <span
                                    v-if="!form?.layout"
                                    class="items-center space-x-2 bg-pink-50 py-1 px-2 rounded-full inline-flex text-pink-600 font-semibold text-xs"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="w-5 h-5"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5"
                                        />
                                    </svg>
                                    <span>Endpoint</span>
                                </span>
                                <span v-else class="items-center space-x-2 bg-blue-50 py-1 px-2 rounded-full inline-flex text-blue-600 font-semibold text-xs">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="w-5 h-5"
                                    >
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                                    </svg>
                                    <span>{{ form?.layout?.type === 'MULTI_STEP' ? 'Multi-step form' : 'Single-step form' }}</span>
                                </span>
                            </td>

                            <!-- Entries -->
                            <td :class="[formIdx === 0 ? '' : 'border-t border-gray-200', 'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell']">
                                {{ form.entries_count.toLocaleString() }} {{ form.entries_count === 1 ? 'entry' : 'entries' }}
                            </td>

                            <!-- Created -->
                            <td :class="[formIdx === 0 ? '' : 'border-t border-gray-200', 'px-3 py-3.5 text-sm text-gray-500']">
                                {{ format(new Date(form.created_at), 'dd MMMM yyyy') }}
                            </td>

                            <!-- Actions -->
                            <td
                                :class="[
                                    formIdx === 0 ? '' : 'border-t border-transparent',
                                    'relative py-3.5 pl-3 pr-4 sm:pr-6 text-right text-sm font-medium',
                                ]"
                            >
                                <Menu as="div" class="relative inline-block text-left">
                                    <MenuButton class="p-0.5 hover:shadow-md w-8 flex items-center justify-center rounded-md mr-3 relative top-1">
                                        <DotsHorizontalIcon class="w-4 h-4 text-gray-500 hover:text-gray-800" aria-hidden="true" />
                                    </MenuButton>
                                    <transition
                                        enter-active-class="transition duration-100 ease-out"
                                        enter-from-class="transform scale-95 opacity-0"
                                        enter-to-class="transform scale-100 opacity-100"
                                        leave-active-class="transition duration-75 ease-in"
                                        leave-from-class="transform scale-100 opacity-100"
                                        leave-to-class="transform scale-95 opacity-0"
                                    >
                                        <MenuItems
                                            class="absolute right-0 z-50 w-32 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                        >
                                            <div class="px-1 py-1">
                                                <MenuItem v-slot="{ active }">
                                                    <Link
                                                        :href="$route('forms.inbox', form)"
                                                        :class="[
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                                                            'group flex w-full items-center rounded-md px-2 py-1 text-xs',
                                                        ]"
                                                    >
                                                        <icon name="pencil" class="w-4 h-4 mr-2 text-body" /> <span>View</span>
                                                    </Link>
                                                </MenuItem>
                                                <MenuItem v-slot="{ active }">
                                                    <button
                                                        @click.prevent="exportEntries(form)"
                                                        :class="[
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                                                            'group flex w-full items-center rounded-md px-2 py-1 text-xs',
                                                        ]"
                                                    >
                                                        <icon name="cloud-download" class="w-4 h-4 mr-2 text-body" />Export
                                                    </button>
                                                </MenuItem>
                                                <MenuItem v-slot="{ active }">
                                                    <button
                                                        @click.prevent="confirmRemoveForm(form)"
                                                        :class="[
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                                                            'group flex w-full items-center rounded-md px-2 py-1 text-xs',
                                                        ]"
                                                    >
                                                        <icon name="trash" class="w-4 h-4 mr-2 text-body" /><span>Remove</span>
                                                    </button>
                                                </MenuItem>
                                            </div>
                                        </MenuItems>
                                    </transition>
                                </Menu>
                                <div v-if="formIdx !== 0" class="absolute left-0 h-px bg-gray-200 right-6 -top-px" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="mt-5 border bg-white border-gray-200 rounded-xl" v-else>
                <img src="/images/no-data.svg" class="max-w-sm mx-auto" alt="No data illustration" />
            </div>

            <pagination :links="forms.links" class="mt-2" />
        </div>
        <create-form-modal form-type="form" :show="createFormModal" max-width="xl" @close="createFormModal = false" />
        <confirmation-modal :show="confirmRemoveFormModal" @close="confirmRemoveFormModal = false">
            <template #title>Are you sure?</template>
            <template #content
                >Remove the form <strong>{{ confirmRemoveFormModal.name }}</strong
                >. Once removed, it's pretty gone.
            </template>
            <template #footer>
                <jet-secondary-button @click="confirmRemoveFormModal = false"> Cancel</jet-secondary-button>

                <jet-danger-button class="ml-2" @click="handleRemoveForm" :class="{ 'opacity-25': removeForm.processing }" :disabled="removeForm.processing">
                    Remove form
                </jet-danger-button>
            </template>
        </confirmation-modal>
    </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import Pagination from '@/Components/Pagination.vue'
import CreateFormModal from '@/Pages/Forms/CreateFormModal.vue'
import Icon from '@/Components/Icon.vue'
import ConfirmationModal from '@/Jetstream/ConfirmationModal.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import JetDangerButton from '@/Jetstream/DangerButton.vue'
import { format } from 'date-fns'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { DotsHorizontalIcon } from '@heroicons/vue/solid'
import { useForm, Link } from '@inertiajs/vue3'

export default {
    layout: AppLayout,
    components: {
        ConfirmationModal,
        Pagination,
        AppLayout,
        CreateFormModal,
        Icon,
        JetSecondaryButton,
        JetDangerButton,
        Menu,
        MenuButton,
        MenuItems,
        MenuItem,
        DotsHorizontalIcon,
        Link,
    },
    props: {
        forms: Object,
    },
    data() {
        return {
            createFormModal: false,
            confirmRemoveFormModal: false,
            removeForm: useForm({}),
            format,
            formType: 'form',
        }
    },

    mounted() {
        document.addEventListener('keydown', this.handleModal)
    },

    methods: {
        confirmRemoveForm(form) {
            this.confirmRemoveFormModal = {
                name: form.name,
                uuid: form._id,
            }
        },
        handleRemoveForm() {
            this.removeForm.delete(route('forms.destroy', { form: this.confirmRemoveFormModal.uuid }), {
                onSuccess: () => {
                    this.confirmRemoveFormModal = false
                },
                preserveState: false,
            })
        },
        handleModal(e) {
            if (e.key === 'c' || e.key === 'C') {
                if (!this.createFormModal) {
                    this.createFormModal = true
                }
            }
        },
        exportEntries(form) {
            window.open(
                route('inbox.export', {
                    form,
                })
            )
        },
    },

    beforeUnmount() {
        document.removeEventListener('keydown', this.handleModal)
    },
}
</script>

<style scoped lang="scss">
.pf-card:hover {
    .card-btn {
        opacity: 1;
    }
}
</style>
