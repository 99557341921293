<template>
    <div>
        <div v-if="!hasFields && $route().current() !== 'form.settings.builder'" class="py-10 text-sm text-center border border-dashed rounded-lg">
            Add your first field
        </div>

        <div v-if="!hasFields && $route().current() === 'form.settings.builder'" class="py-10 text-sm text-center border border-dashed rounded-lg">
            <span>You haven't added any fields yet </span>
        </div>
        <div
            class="grid space-x-1"
            :class="{
                'grid-cols-5': showDeleteButton,
                'grid-cols-4': !showDeleteButton,
            }"
        >
            <div
                @click="addField"
                class="flex items-center justify-center col-span-2 p-3 mt-2 text-sm text-center text-blue-500 border border-blue-200 rounded-lg cursor-pointer bg-blue-50"
            >
                <icon name="plus" class="w-5 h-5 mr-2" />
                <span class="font-semibold">Add field</span>
            </div>

            <div
                @click="addFormStep"
                class="flex items-center justify-center col-span-2 p-3 mt-2 text-sm text-center text-blue-500 border border-blue-200 rounded-lg cursor-pointer bg-blue-50"
                :disabled="!canAddFormStep"
                :class="{
                    'opacity-50 cursor-not-allowed pointer-events-none': !canAddFormStep,
                }"
            >
                <icon name="plus" class="w-5 h-5 mr-2" />
                <span class="font-semibold">Add step</span>
            </div>

            <div
                v-if="showDeleteButton"
                tooltip="Delete form step"
                @click="deleteFormStep"
                class="flex items-center justify-center col-span-1 p-3 mt-2 text-sm text-center text-red-500 bg-white border border-red-200 rounded-lg cursor-pointer"
            >
                <icon name="trash" class="w-5 h-5" />
            </div>
        </div>
    </div>
</template>
<script>
import Icon from '@/Components/Icon.vue'

export default {
    props: {
        hasFields: {
            type: Boolean,
            required: true,
            default: false,
        },
        addField: {
            type: Function,
            required: true,
        },
        addFormStep: {
            type: Function,
            required: true,
        },
        deleteFormStep: {
            type: Function,
            required: true,
        },
        showDeleteButton: {
            type: Boolean,
            required: true,
            default: false,
        },
        canAddFormStep: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    components: { Icon },
}
</script>
