<template>
    <div>
        <div class="max-w-lg p-3 lg:p-6">
            <h3 class="page-title">Branding</h3>
            <p class="page-desc">You can customize the look and feel of your form by adding a logo and changing the color scheme.</p>

            <form @submit.prevent="onSubmit" class="mt-4">
                <div class="flex-1">
                    <!-- Profile Photo File Input -->
                    <input type="file" class="hidden" ref="logo" @change="updateLogoPreview" />

                    <jet-label for="photo" value="Logo" />

                    <!-- Current Profile Photo -->
                    <div class="mt-2" v-show="!logoPreview">
                        <img v-if="form.logo" :src="form.logo" alt="Form logo" class="object-cover w-20 h-20" />
                    </div>

                    <!-- New Profile Photo Preview -->
                    <div class="my-2" v-show="logoPreview">
                        <span
                            class="block"
                            :style="
                                'width: 100%; height: 30px; background-size: contain; background-repeat: no-repeat; background-image: url(\'' +
                                logoPreview +
                                '\');'
                            "
                        >
                        </span>
                    </div>

                    <jet-secondary-button class="mt-2 mr-2" type="button" @click.prevent="selectNewLogo"> Select A New Logo </jet-secondary-button>

                    <jet-secondary-button type="button" class="mt-2" @click.prevent="deleteLogo" v-if="formLogo"> Remove Logo </jet-secondary-button>

                    <jet-input-error :message="form.errors.logo" class="mt-2" />
                </div>
                <div class="mt-4">
                    <jet-button class="mr-3" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">Save</jet-button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import ShowFormLayout from '@/Layouts/ShowFormLayout.vue'
import { ref } from 'vue'

export default {
    layout: [AppLayout, ShowFormLayout],
}
</script>

<script setup>
import { ref } from 'vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import { useForm, usePage, router } from '@inertiajs/vue3'
import JetInputError from '@/Jetstream/InputError.vue'

const page = usePage().props

const props = defineProps({
    formLogo: {
        type: [String, null],
    },
    formLogoUrl: {
        type: [String, null],
    },
})

const form = useForm({
    logo: props.formLogo,
    theme: null,
})

const logoPreview = ref(JSON.parse(JSON.stringify(props.formLogoUrl)))
const logo = ref(null)

const onSubmit = () => {
    if (logo.value) {
        form.logo = logo.value.files[0]
    }

    form.post(route('form.settings.branding.update', { form: page.meta.form_uuid }))
}

const selectNewLogo = () => logo.value.click()

const updateLogoPreview = () => {
    const reader = new FileReader()

    reader.onload = (e) => {
        logoPreview.value = e.target.result
    }

    reader.readAsDataURL(logo.value.files[0])
}

const deleteLogo = () => {
    form.logo = null
    logoPreview.value = null

    router.delete(route('form.settings.branding.logo', { form: page.meta.form_uuid }), {
        preserveScroll: true,
        onSuccess: () => (logoPreview.value = null),
    })
}
</script>
