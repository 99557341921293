<template>
    <div>
        <div class="max-w-lg p-3 lg:p-6">
            <h3 class="page-title">Domain</h3>
            <p class="page-desc">Add your custom domain or subdomain below.</p>

            <!--            <div class="flex flex-col">-->
            <!--                <a v-if="form.domain" :href="'https://' + form.domain" target="_blank">-->
            <!--                    <span class="inline-flex items-center p-2 mt-3 text-xs border rounded-md cursor-pointer hover:text-dark-blue">-->
            <!--                        <icon name="globe" class="w-4 h-4 mr-2" /> {{ form.domain }}-->
            <!--                    </span>-->
            <!--                </a>-->
            <!--            </div>-->

            <form @submit.prevent="domainForm.put($route('form.settings.domain.update', form))">
                <div class="mt-4">
                    <div class="flex items-center">
                        <jet-label for="domain" class="mb-0" value="Domain URL" />
                        <info class="ml-1">
                            <p class="text-xs text-body">You must enter your domain or subdomain without the "https://" part.</p>
                            <ul class="mt-2 ml-3 list-disc">
                                <li class="text-xs">site.com</li>
                                <li class="text-xs">demo.site.com</li>
                            </ul>
                        </info>
                    </div>
                    <jet-input id="domain" class="block w-full mt-1" v-model="domainForm.domain" type="text" />
                    <jet-input-error :message="domainForm.errors.domain" class="mt-2" />
                </div>

                <div class="mt-4">
                    <jet-button class="mr-3" :class="{ 'opacity-25': domainForm.processing }" :disabled="domainForm.processing">Save</jet-button>
                </div>
            </form>

            <div v-if="vhost?.success" class="flex mt-5 border border-gray-300 rounded-lg p-3">
                <div class="flex items-center flex-1 justify-between">
                    <div class="btn flex space-x-2 items-center">
                        <div class="flex items-center space-x-1 pr-2 -my-1 py-1 border-r border-gray-200">
                            <icon name="globe" class="w-4 h-4 text-green-500" />
                            <div class="text-green-500 text-xs">Domain</div>
                        </div>
                        <span>{{ form.domain }}</span>
                    </div>

                    <div class="btn-shadow rounded px-2 text-xs">
                        <span v-if="vhost?.data?.status === 'ACTIVE_SSL'" class="inline-block mr-2">
                            <span class="relative flex h-2 w-2">
                                <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                                <span class="relative inline-flex rounded-full h-2 w-2 bg-green-500"></span>
                            </span>
                        </span>
                        <span v-else class="inline-block mr-2">
                            <span class="relative flex h-2 w-2">
                                <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-orange-400 opacity-75"></span>
                                <span class="relative inline-flex rounded-full h-2 w-2 bg-orange-500"></span>
                            </span>
                        </span>
                        <span class="text-xs">{{ vhost?.data?.status_message }}</span>
                    </div>
                </div>
            </div>
        </div>

        <!--      <pre class="text-xs">-->
        <!--        vhost: {{ vhost}}-->
        <!--      </pre>-->
    </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import ShowFormLayout from '@/Layouts/ShowFormLayout.vue'

export default {
    layout: [AppLayout, ShowFormLayout],
}
</script>
<script setup>
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetInputError from '@/Jetstream/InputError.vue'
import JetButton from '@/Jetstream/Button.vue'
import Icon from '@/Components/Icon.vue'
import Info from '@/Components/Info.vue'
import { useForm } from '@inertiajs/vue3'

const props = defineProps({
    form: Object,
    vhost: Object,
})

const domainForm = useForm({
    _method: 'PUT',
    domain: props.form.domain,
})
</script>

<style scoped></style>
