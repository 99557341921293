<template>
    <div class="h-screen overflow-y-auto no-scrollbar">
        <div class="p-6 mt-[13px] w-full relative">
            <fun-bg />

            <div
                class="relative bg-white max-w-[545px] pb-3 card-shadow md:pt-[65px] pt-8 px-5 md:px-[65px] rounded-lg mx-auto mt-[50px]"
            >
                <jet-validation-errors class="mb-4" />

                <h3
                    class="mb-4 leading-relaxed font-bold tracking-tight text-2xl text-[#2e161e]"
                >
                    Create your Pixelform account
                </h3>

                <form @submit.prevent="submit" class="space-y-4 md:space-y-8">
                    <div>
                        <jet-label for="email" value="Email" />
                        <jet-input
                            id="email"
                            type="email"
                            class="auth-field block w-full"
                            v-model="form.email"
                            required
                        />
                    </div>

                    <div>
                        <jet-label for="name" value="Full name" />
                        <jet-input
                            id="name"
                            type="text"
                            class="auth-field block w-full"
                            v-model="form.name"
                            required
                            autofocus
                            autocomplete="name"
                        />
                    </div>

                    <div>
                        <jet-label for="password" value="Password" />
                        <jet-input
                            id="password"
                            type="password"
                            class="auth-field block w-full"
                            v-model="form.password"
                            required
                            autocomplete="new-password"
                        />
                    </div>

                    <div class="flex flex-col mt-4">
                        <pf-button
                            class="!py-4 !btn-bg justify-center"
                            :class="{ 'opacity-25': form.processing }"
                            :disabled="form.processing"
                        >
                            Create account
                        </pf-button>

                        <div
                            class="mt-2 text-xs max-w-xs mx-auto text-center text-gray-500"
                        >
                            Signing up for a Pixelform account means you agree
                            to
                            <a
                                target="_blank"
                                :href="$route('terms.show')"
                                class="text-xs text-blue-500 hover:text-gray-900"
                                >Terms of Service</a
                            >
                            and
                            <a
                                target="_blank"
                                :href="$route('policy.show')"
                                class="text-xs text-blue-500 hover:text-gray-900"
                                >Privacy Policy</a
                            >
                        </div>
                        <Link
                            :href="$route('login')"
                            class="mt-2 md:mt-8 py-3 text-center rounded-md text-xs text-gray-400 hover:text-gray-900"
                        >
                            Already registered?
                        </Link>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import JetAuthenticationCard from '@/Jetstream/AuthenticationCard.vue'
import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo.vue'
import JetButton from '@/Jetstream/Button.vue'
import PfButton from '@/Pixelform/Button.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetCheckbox from '@/Jetstream/Checkbox.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetValidationErrors from '@/Jetstream/ValidationErrors.vue'
import { useForm, Link } from '@inertiajs/vue3'
import FunBg from '@/Components/FunBg.vue'

export default {
    components: {
        JetAuthenticationCard,
        JetAuthenticationCardLogo,
        JetButton,
        JetInput,
        JetCheckbox,
        JetLabel,
        JetValidationErrors,
        FunBg,
        PfButton,
        Link,
    },

    data() {
        return {
            form: useForm({
                name: '',
                email: '',
                password: '',
                terms: false,
            }),
        }
    },

    methods: {
        submit() {
            this.form.post(route('register'), {
                onFinish: () => this.form.reset('password'),
            })
        },
    },
}
</script>
<style scoped>
.auth-field {
    height: 40px !important;
}
</style>
