<template>
    <input
        class="border border-stackborder focus:placeholder-gray-300 focus:ring-4 focus:border-blue-400 focus:ring-blue-200 focus:ring-opacity-50 text-input text-sm py-3 px-4"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        ref="input"
    />
</template>

<script>
export default {
    props: ['modelValue'],

    emits: ['update:modelValue'],

    methods: {
        focus() {
            this.$refs.input.focus()
        },
    },
}
</script>
