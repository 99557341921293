<template>
    <modal :show="show" :max-width="maxWidth" :closeable="closeable" @close="close">
        <div class="bg-gray-50 flex-1">
            <slot name="content"></slot>
        </div>
    </modal>
</template>

<script>
import Modal from '@/Jetstream/Modal.vue'

export default {
    emits: ['close'],

    components: {
        Modal,
    },

    props: {
        show: {
            default: false,
        },
        maxWidth: {
            default: 'xl',
        },
        closeable: {
            default: true,
        },
    },

    methods: {
        close() {
            this.$emit('close')
        },
    },
}
</script>
