<template>
    <jet-form-section @submitted="createTeam">
        <template #title> Workspace Details </template>

        <template #description> Create a new workspace to collaborate with others on projects. </template>

        <template #form>
            <div class="col-span-6">
                <jet-label value="Workspace Owner" />

                <div class="flex items-center mt-2">
                    <img class="object-cover w-12 h-12 rounded-full" :src="$page.props.auth.user.profile_photo_url" :alt="$page.props.auth.user.name" />

                    <div class="ml-4 leading-tight">
                        <div>{{ $page.props.auth.user.name }}</div>
                        <div class="text-sm text-gray-700">{{ $page.props.auth.user.email }}</div>
                    </div>
                </div>
            </div>

            <div class="col-span-6 sm:col-span-4">
                <jet-label for="name" value="Workspace Name" />
                <jet-input id="name" type="text" class="block w-full" v-model="form.name" autofocus />
                <jet-input-error :message="form.errors.name" class="mt-2" />
            </div>
        </template>

        <template #actions>
            <jet-button :class="{ 'opacity-25': form.processing }" :disabled="form.processing"> Create </jet-button>
        </template>
    </jet-form-section>
</template>

<script>
import JetButton from '@/Jetstream/Button.vue'
import JetFormSection from '@/Jetstream/FormSection.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetInputError from '@/Jetstream/InputError.vue'
import JetLabel from '@/Jetstream/Label.vue'
import { useForm } from '@inertiajs/vue3'

export default {
    components: {
        JetButton,
        JetFormSection,
        JetInput,
        JetInputError,
        JetLabel,
    },

    data() {
        return {
            form: useForm({
                name: '',
            }),
        }
    },

    methods: {
        createTeam() {
            this.form.post(route('teams.store'), {
                errorBag: 'createTeam',
                preserveScroll: true,
            })
        },
    },
}
</script>
