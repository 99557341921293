<template>
    <div class="flex-1 h-full bg-white">
        <toaster />
        <jet-banner v-if="jetstream?.flash?.banner" />
        <nav
            class="fixed top-0 z-40 flex-none w-full duration-500 bg-white lg:z-50 border-b border-gray-900/10 backdrop-filter md:backdrop-blur-sm"
            :class="{
                'bg-opacity-50': !showingNavigationDropdown,
            }"
        >
            <!-- Primary Navigation Menu -->
            <div class="px-3 mx-auto max-w-7xl">
                <div class="flex justify-between h-12">
                    <div class="flex">
                        <!-- Logo -->
                        <div class="flex-shrink-0 flex items-center max-w-[230px] w-full">
                            <Link :href="$route('forms.index')">
                                <h1 class="text-3xl font-bold leading-tight tracking-tight text-black">pixelform.</h1>
                            </Link>
                            <div v-if="$page.props.current_plan_name" class="px-1 mb-1 text-white bg-gray-700 rounded text-tiny">
                                {{ $page.props.current_plan_name }}
                            </div>
                            <div v-else class="px-1 mb-1 text-white rounded bg-slate-500 text-tiny">sandbox</div>
                        </div>

                        <!-- Navigation Links -->
                        <div class="hidden space-x-8 sm:-my-px sm:flex">
                            <!-- <jet-nav-link
                                :href="$route('endpoints.index')"
                                :active="$route().current('endpoints.index') || $page?.props?.meta?.form_type === 'ENDPOINT'"
                            >
                                Endpoints
                            </jet-nav-link>
                            <jet-nav-link :href="$route('forms.index')" :active="$route().current('forms.index') || $page?.props?.meta?.form_type === 'FORM'">
                                Forms
                            </jet-nav-link> -->
                        </div>
                    </div>

                    <div class="hidden sm:flex sm:items-center sm:ml-6">
                        <div class="relative">
                            <div class="text-sm flex items-center space-x-2 !font-regular text-gray-500">
                                <Link v-if="!$page.props.current_plan_name" as="button" :href="$route('billing.index')" class="flex items-center ml-2 btn">
                                    <svg fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-1">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
                                        />
                                    </svg>
                                    <span>Upgrade</span>
                                </Link>
                                <jet-dropdown align="right" width="60">
                                    <template #trigger>
                                        <span class="inline-flex rounded-md">
                                            <button
                                                type="button"
                                                class="inline-flex items-center px-3 py-[5px] text-sm leading-4 font-medium rounded-xl text-body btn focus:outline-none active:bg-gray-100 transition"
                                            >
                                                <icon name="bolt" class="w-4 h-4 mr-1" />
                                                Plan quota
                                            </button>
                                        </span>
                                    </template>

                                    <template #content>
                                        <div class="w-60 px-3">
                                            <div class="flex items-center justify-between">
                                                <span class="uppercase text-[10px]">Used</span>
                                                <span class="uppercase text-[10px]">Available</span>
                                            </div>
                                            <div class="-mx-3 divide-y border-t border-gray-300 divide-gray-300 -mb-1">
                                                <div class="flex items-center justify-between py-1 px-3 bg-gradient-to-l from-green-50 to-white">
                                                    <span class="text-xs">{{ entriesCounter.used }}</span>
                                                    <span class="font-semibold">{{ entriesCounter.limit }}</span>
                                                </div>
                                                <div class="flex items-center justify-between py-1 px-3 bg-gradient-to-l from-orange-50 to-white">
                                                    <span class="text-xs">{{ storagePlan.used }}</span>
                                                    <span class="font-semibold">{{ storagePlan.limit }}</span>
                                                </div>
                                                <div class="flex items-center justify-between py-1 px-3 bg-gradient-to-l from-blue-50 to-white rounded-b-lg">
                                                    <span class="text-xs">{{ formsPlan.used }}</span>
                                                    <span class="font-semibold">{{ formsPlan.limit }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </jet-dropdown>
                            </div>
                        </div>
                        <div class="relative ml-5">
                            <!-- Select Workspace Dropdown -->
                            <jet-dropdown align="right" width="60" v-if="$page.props.jetstream.hasTeamFeatures">
                                <template #trigger>
                                    <span class="inline-flex rounded-md">
                                        <button
                                            type="button"
                                            class="inline-flex items-center px-3 py-[5px] text-sm leading-4 font-medium rounded-xl text-body btn focus:outline-none active:bg-gray-100 transition"
                                        >
                                            {{ user?.current_team.name }}

                                            <svg class="ml-2 -mr-0.5 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                                    clip-rule="evenodd"
                                                />
                                            </svg>
                                        </button>
                                    </span>
                                </template>

                                <template #content>
                                    <div class="w-60">
                                        <!-- Team Management -->
                                        <div v-if="$page.props.jetstream.hasTeamFeatures">
                                            <!-- <div class="block px-4 py-2 text-xs text-body">Manage Workspaces</div>
                                            <div class="border-b border-gray-100"></div> -->

                                            <!-- Team Settings -->
                                            <jet-dropdown-link :href="$route('teams.show', user?.current_team)" class="text-xs">
                                                Workspace settings
                                            </jet-dropdown-link>

                                            <form @submit.prevent="goBillingPortal">
                                                <jet-dropdown-link as="button" class="text-xs" type="submit" tooltip="Please upgrade to create more workspaces">
                                                    Create workspace
                                                </jet-dropdown-link>
                                            </form>

                                            <div class="border-t border-gray-100"></div>

                                            <!-- Team Switcher -->
                                            <!-- <div class="block px-4 py-2 text-xs text-body">Switch Workspaces</div> -->

                                            <template v-for="team in user?.all_teams" :key="team.id">
                                                <form @submit.prevent="switchToTeam(team)">
                                                    <jet-dropdown-link as="button">
                                                        <div class="flex items-center">
                                                            <svg
                                                                v-if="team.id == user?.current_team_id"
                                                                class="w-5 h-5 mr-2 text-green-400"
                                                                fill="none"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="2"
                                                                stroke="currentColor"
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                            </svg>
                                                            <div>
                                                                {{ team.name }}
                                                            </div>
                                                        </div>
                                                    </jet-dropdown-link>
                                                </form>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                            </jet-dropdown>
                        </div>
                        <!-- Settings Dropdown -->
                        <div class="relative ml-3">
                            <jet-dropdown align="right" width="48" :trigger-index="'z-[70]'">
                                <template #trigger>
                                    <button
                                        v-if="$page.props.jetstream.managesProfilePhotos"
                                        class="flex text-sm transition border-2 border-blue-400 rounded-3xl focus:outline-none focus:border-blue-500"
                                    >
                                        <img class="object-cover w-8 h-8 rounded-3xl" :alt="user?.name" :src="$page?.props.auth?.user?.profile_photo_url" />
                                    </button>

                                    <span v-else class="inline-flex rounded-md">
                                        <button
                                            type="button"
                                            class="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 text-gray-500 transition bg-white border border-transparent rounded-md hover:text-gray-700 focus:outline-none"
                                        >
                                            {{ user?.name }}

                                            <svg class="ml-2 -mr-0.5 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                    clip-rule="evenodd"
                                                />
                                            </svg>
                                        </button>
                                    </span>
                                </template>

                                <template #content>
                                    <!-- Account Management -->
                                    <div class="block px-4 py-2 text-xs text-gray-400">Manage Account</div>

                                    <jet-dropdown-link :href="$route('profile.show')"> Profile</jet-dropdown-link>
                                    <form @submit.prevent="goBillingPortal">
                                        <jet-dropdown-link as="button"> Billing </jet-dropdown-link>
                                    </form>

                                    <jet-dropdown-link :href="$route('api-tokens.index')" v-if="$page.props.jetstream.hasApiFeatures">
                                        API Tokens
                                    </jet-dropdown-link>

                                    <div class="border-t border-gray-100"></div>

                                    <!-- Authentication -->
                                    <form @submit.prevent="logout">
                                        <jet-dropdown-link as="button"> Log out</jet-dropdown-link>
                                    </form>
                                </template>
                            </jet-dropdown>
                        </div>
                    </div>

                    <!-- Hamburger -->
                    <div class="flex items-center -mr-2 sm:hidden">
                        <button
                            @click="showingNavigationDropdown = !showingNavigationDropdown"
                            class="inline-flex items-center justify-center p-2 text-gray-400 transition rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500"
                        >
                            <svg class="w-6 h-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                <path
                                    :class="{
                                        hidden: showingNavigationDropdown,
                                        'inline-flex': !showingNavigationDropdown,
                                    }"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                                <path
                                    :class="{
                                        hidden: !showingNavigationDropdown,
                                        'inline-flex': showingNavigationDropdown,
                                    }"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <!-- Responsive Navigation Menu -->
            <div
                :class="{
                    block: showingNavigationDropdown,
                    hidden: !showingNavigationDropdown,
                }"
                class="sm:hidden"
            >
                <!-- Responsive Settings Options -->
                <div class="pb-1 border-gray-200">
                    <div class="flex items-center px-4 border-b border-t py-4">
                        <div v-if="$page.props.jetstream.managesProfilePhotos" class="flex-shrink-0 mr-3">
                            <img class="object-cover w-10 h-10 rounded-sm" :src="user?.profile_photo_url" :alt="user?.name" />
                        </div>

                        <div>
                            <div class="text-base font-medium text-gray-800">
                                {{ user?.name }}
                            </div>
                            <div class="text-sm font-medium text-gray-500">
                                {{ user?.email }}
                            </div>
                        </div>
                    </div>

                    <div class="mt-3 space-y-1">
                        <jet-responsive-nav-link :href="$route('profile.show')" :active="$route().current('profile.show')"> Profile </jet-responsive-nav-link>

                        <jet-responsive-nav-link
                            :href="$route('api-tokens.index')"
                            :active="$route().current('api-tokens.index')"
                            v-if="$page.props.jetstream.hasApiFeatures"
                        >
                            API Tokens
                        </jet-responsive-nav-link>

                        <!-- Authentication -->
                        <form method="POST" @submit.prevent="logout">
                            <jet-responsive-nav-link as="button"> Log out</jet-responsive-nav-link>
                        </form>

                        <!-- Team Management -->
                        <template v-if="$page.props.jetstream.hasTeamFeatures">
                            <div class="border-t border-gray-200"></div>

                            <div class="block px-4 py-2 text-xs text-gray-400">Manage workspace</div>

                            <!-- Team Settings -->
                            <!-- <jet-responsive-nav-link :href="$route('teams.show', user?.current_team)" :active="$route().current('teams.show')">
                                Team Settings
                            </jet-responsive-nav-link> -->

                            <jet-responsive-nav-link :href="$route('teams.create')" :active="$route().current('teams.create')">
                                <div class="flex items-center space-x-2">
                                    <span class="bg-blue-500 rounded">
                                        <icon name="plus" class="text-white w-4 h-4" />
                                    </span>
                                    <span>Create new workspace</span>
                                </div>
                            </jet-responsive-nav-link>

                            <div class="border-t border-gray-200"></div>

                            <!-- Workspace Switcher -->
                            <div class="block px-4 py-2 text-xs text-gray-400">Switch workspace</div>

                            <template v-for="team in user?.all_teams" :key="team.id">
                                <form @submit.prevent="switchToTeam(team)">
                                    <jet-responsive-nav-link as="button">
                                        <div class="flex items-center">
                                            <svg
                                                v-if="team.id == user?.current_team_id"
                                                class="w-5 h-5 mr-2 text-green-400"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                            </svg>
                                            <div>{{ team.name }}</div>
                                        </div>
                                    </jet-responsive-nav-link>
                                </form>
                            </template>
                        </template>
                    </div>
                </div>
                <div class="pt-2 pb-3 space-y-1">
                    <jet-responsive-nav-link :href="$route('forms.index')" :active="$route().current('forms.index')"> Forms </jet-responsive-nav-link>
                </div>
            </div>
        </nav>

        <!-- Page Content -->
        <main class="z-50 mx-auto mt-12 max-w-7xl">
            <slot></slot>
        </main>
    </div>
</template>

<script setup>
import { Link, router, usePage } from '@inertiajs/vue3'
import JetBanner from '@/Jetstream/Banner.vue'
import JetDropdown from '@/Jetstream/Dropdown.vue'
import JetDropdownLink from '@/Jetstream/DropdownLink.vue'
import JetResponsiveNavLink from '@/Jetstream/ResponsiveNavLink.vue'
import Toaster from '@/Components/Toaster.vue'
import Icon from '@/Components/Icon.vue'

import { ref } from 'vue'

const showingNavigationDropdown = ref(false)

const user = usePage().props.auth.user
const jetstream = usePage().props.jetstream
const $page = usePage()

const switchToTeam = (team) =>
    router.put(
        route('current-team.update'),
        {
            team_id: team.id,
        },
        {
            preserveState: false,
        }
    )

const logout = () => router.post(route('logout'))

const entriesCounter = $page.props.entries_counter
const storagePlan = $page.props.storage_used
const formsPlan = $page.props.forms_used

const goBillingPortal = () => router.visit(route('billing.portal'), { method: 'post' })
</script>
