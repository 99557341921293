<template>
    <form @submit.prevent="saveLayout">
        <div class="px-5 py-3 border-t border-gray-200 bg-gray-50">
            <div class="min-h-[350px] space-y-3">
                <div class="flex flex-col p-2 mb-3 border border-dashed rounded-lg border-slate-300">
                    <jet-label :small="true" for="label">Title</jet-label>
                    <input
                        type="text"
                        id="stackTitle"
                        v-model="stackTitle"
                        class="border rounded fe-input border-stackborder focus:placeholder-gray-300 focus:ring-4 focus:border-blue-400 focus:ring-blue-200 focus:ring-opacity-50 text-input"
                    />
                </div>
                <div v-for="(row, index) in rows" :key="index" class="flex items-center justify-center space-x-2">
                    <div class="flex items-center justify-center p-2 border border-dashed rounded-lg border-slate-300">
                        <span class="btn !py-2 flex items-center flex-1 justify-center w-9 h-9"> {{ row?.fields?.length || 0 }}/3 </span>
                    </div>
                    <Multiselect
                        :classes="multiselectClasses"
                        v-model="row.fields"
                        mode="tags"
                        :object="true"
                        :max="3"
                        :searchable="true"
                        :placeholder="'#' + (index + 1)"
                        :options="options"
                    />

                    <div class="flex items-center justify-center p-2 border border-dashed rounded-lg border-slate-300">
                        <button type="button" @click="removeRow(index)" class="btn !py-2 flex items-center flex-1 justify-center">
                            <span class="flex items-center">
                                <icon name="trash" class="w-4 h-4" />
                            </span>
                        </button>
                    </div>
                </div>
                <button type="button" @click="addRow" class="btn !py-2 flex items-center flex-1 justify-center">
                    <span class="flex items-center"> <icon name="plus" class="w-4 h-4 mr-2" /> Add row in stack </span>
                </button>
            </div>
        </div>
        <div class="flex flex-col justify-between flex-1 px-3 py-3 bg-white border-t border-gray-200">
            <div class="flex items-center flex-1 space-x-2">
                <button type="button" @click="onClose" class="btn !py-2 flex flex-1 justify-center">Cancel</button>
                <button type="submit" class="btn-cta !py-2 flex flex-1 justify-center" :class="{ 'opacity-25': disabled }" :disabled="disabled">Save</button>
            </div>
        </div>
    </form>
</template>
<script>
import JetButtonSecondary from '@/Jetstream/SecondaryButton.vue'
import JetLabel from '@/Jetstream/Label.vue'
import Multiselect from '@vueform/multiselect'
import Icon from '@/Components/Icon.vue'
import Info from '@/Components/Info.vue'
import Input from '@/Components/Input.vue'
import { useForm } from '@inertiajs/vue3'

export default {
    components: {
        Input,
        Info,
        Icon,
        JetButtonSecondary,
        JetLabel,
        Multiselect,
    },
    props: ['layout', 'stack', 'currentStep', 'availableFields'],
    data() {
        return {
            layoutForm: useForm({
                _method: 'POST',
                ...Object.assign({}, this.layout),
            }),
            rows: [
                {
                    fields: null,
                },
            ],
            stackTitle: this.stack?.title || '',
        }
    },
    mounted() {
        if (this.stack) {
            this.$nextTick(() => {
                this.rows = this.getRowsFromStack(this.stack)
            })
        }
    },
    methods: {
        getRowsFromStack(stack) {
            const rows = []

            for (const child of stack.children) {
                if (child.name === 'row') {
                    const fields = child.children.map((field) => this.getSelectOption(field))
                    rows.push({
                        fields,
                    })
                } else {
                    const field = this.getSelectOption(child)
                    rows.push({
                        fields: [field],
                    })
                }
            }

            return rows
        },
        addRow() {
            this.rows.push({
                fields: [],
            })
        },
        removeRow(index) {
            this.rows = this.rows.filter((row, i) => i !== index)
        },
        onClose() {
            this.layoutForm.reset()
            this.$emit('close')
        },
        saveLayout() {
            let stack = {
                name: 'stack',
                title: this.stackTitle,
                _id: 'stack_' + Math.random().toString(36).substring(2, 7),
                children: [],
            }

            for (const row of this.rows) {
                if (row.fields?.length > 1) {
                    stack.children.push({
                        label: 'Row',
                        name: 'row',
                        _id: 'row_' + Math.random().toString(36).substring(2, 7),
                        children: row.fields.map((f) => f.value),
                    })
                } else {
                    stack.children.push(row.fields[0]?.value)
                }
            }
            const valid = this.layoutIsValid()

            if (!valid) {
                return false
            }

            let formLayout = this.layout

            if (!this.stack) {
                // add the stack to the layout
                for (const row of this.rows) {
                    for (const field of row.fields) {
                        const index = formLayout.children[this.currentStep].children.findIndex((child) => child?.id === field?.id)
                        formLayout.children[this.currentStep].children.splice(index, 1)
                    }
                }

                formLayout.children[this.currentStep].children.push(stack)
            } else {
                const index = formLayout.children[this.currentStep].children.findIndex((child) => child?._id === this.stack?._id)
                formLayout.children[this.currentStep].children.splice(index, 1, stack)
            }

            this.$emit('saveLayout', formLayout.children)
            this.$emit('close')
        },
        layoutIsValid() {
            const step = this.layoutForm.children[this.currentStep].children

            if (step.length === 0) return true

            const hasEmptyStacks = step.some((field) => field.name === 'stack' && field?.children?.length === 0)
            const hasEmptyRows = step.some((field) => field.name === 'row' && field?.children?.length === 0)

            if (hasEmptyStacks || hasEmptyRows) {
                alert("Sorry! No empty stacks or rows are allowed. If you don't need them, please remove them from your layout.")
                return false
            }

            return true
        },

        getSelectOption(field) {
            return {
                label: field.label,
                id: field.id,
                value: field,
                name: field.name,
            }
        },
    },
    computed: {
        disabled() {
            return this.layoutForm.processing || this.invalidStack
        },
        invalidStack() {
            for (const f of this.rows) {
                if (!f?.fields || f?.fields?.length === 0) {
                    return true
                }
            }

            return false
        },
        options() {
            return this.availableFields
        },
        multiselectClasses() {
            return {
                container:
                    'relative mx-auto w-full flex flex-1 items-center justify-end box-border border rounded-lg cursor-pointer text-base leading-snug outline-none border-dashed border-neutral-300 py-1 px-2',
                containerDisabled: 'cursor-default bg-gray-100',
                // containerOpen: 'rounded-b-none',
                containerOpenTop: 'rounded-t-lg',
                // containerActive: 'ring ring-blue-200 ring-opacity-30',
                singleLabel:
                    'flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 pr-16 box-border rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
                singleLabelText: 'overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full',
                multipleLabel:
                    'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
                search: ' bg-transparent absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-base font-sans pl-3.5 rtl:pl-0 rtl:pr-3.5',
                tags: 'flex flex-1 items-center mt-1 rtl:pl-0 rtl:pr-2',
                tag: 'text-white text-sm border font-semibold py-1.5 pl-4 rounded-full mr-3 mb-1 flex flex-1 items-center justify-between whitespace-nowrap rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1 btn-bg',
                // tag: 'text-white text-sm border font-semibold py-1.5 pl-3 rounded mr-1 mb-1 flex flex-1 items-center justify-between whitespace-nowrap rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1 btn-bg',
                wrapper: 'flex-1',
                tagDisabled: 'pr-2 opacity-50 rtl:pl-2',
                tagRemove: 'flex items-center justify-center p-1 mx-0.5 bg-slate-300 hover:bg-white group rounded-full mr-2',
                tagRemoveIcon: 'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60 text-white',
                tagsSearchWrapper: `inline-block relative mx-1 mb-1 border-b border-dashed border-slate-300  min-w-30px`,
                tagsSearch:
                    'absolute inset-0 border-0 outline-none focus:ring-0 appearance-none p-0 text-xs text-center font-sans box-border w-full bg-transparent',
                tagsSearchCopy: 'invisible whitespace-pre-wrap inline-block h-px',
                placeholder:
                    'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-gray-400 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5 text-center',
                caret: 'bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none rtl:mr-0 rtl:ml-3.5 hidden',
                caretOpen: 'rotate-180 pointer-events-auto',
                clear: 'pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80 rtl:pr-0 rtl:pl-3.5 hidden',
                clearIcon: 'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
                spinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0 rtl:mr-0 rtl:ml-3.5',
                infinite: 'flex items-center justify-center w-full',
                infiniteSpinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 animate-spin flex-shrink-0 flex-grow-0 m-3.5',
                dropdown:
                    'max-h-60 absolute -left-px -right-px -bottom-1 transform translate-y-full border border-gray-300 -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-t-lg rounded-b-lg',
                dropdownTop: '-translate-y-full top-px bottom-auto rounded-b-none rounded-t-lg',
                dropdownHidden: 'hidden',
                options: 'flex flex-col p-0 m-0 list-none',
                optionsTop: '',
                group: 'p-0 m-0',
                groupLabel: 'flex text-sm box-border items-center justify-start text-left py-1 px-3 font-semibold bg-gray-200 cursor-default leading-normal',
                groupLabelPointable: 'cursor-pointer',
                groupLabelPointed: 'bg-gray-300 text-gray-700',
                groupLabelSelected: 'bg-green-600 text-white',
                groupLabelDisabled: 'bg-gray-100 text-gray-300 cursor-not-allowed',
                groupLabelSelectedPointed: 'bg-green-600 text-white opacity-90',
                groupLabelSelectedDisabled: 'text-green-100 bg-green-600 bg-opacity-50 cursor-not-allowed',
                groupOptions: 'p-0 m-0',
                option: 'flex items-center justify-start box-border text-left cursor-pointer text-sm leading-snug py-1 px-3',
                optionPointed: 'text-gray-800 bg-gray-100',
                optionSelected: 'text-white bg-green-500',
                optionDisabled: 'text-gray-300 cursor-not-allowed',
                optionSelectedPointed: 'text-white bg-green-500 opacity-90',
                optionSelectedDisabled: 'text-green-100 bg-green-500 bg-opacity-50 cursor-not-allowed',
                noOptions: 'py-2 px-3 text-gray-600 bg-white text-left',
                noResults: 'py-2 px-3 text-gray-600 bg-white text-left',
                fakeInput: 'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
                spacer: 'h-9 py-px box-content',
                assist: 'hidden',
            }
        },
    },
}
</script>
