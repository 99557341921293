<template>
    <confirmation-modal :icon-color="'blue'" :icon-name="'pencil'" :max-width="'md'" :show="show" @close="emit('close')">
        <template #title>
            <h3 class="text-lg">Edit your form name</h3>
        </template>
        <template #content>
            <p class="mb-4 text-base text-md">Your changes will be reflected immediately</p>
            <jet-label for="formName" value="Name" />
            <jet-input v-model="form.name" type="text" class="w-[345px]" />
        </template>
        <template #footer>
            <div>
                <jet-secondary-button class="mr-2 btn" @click="onCancel">Cancel</jet-secondary-button>
                <jet-button :class="{ 'opacity-25': form.processing }" :disabled="form.processing" @click="onSubmit">Save form name</jet-button>
            </div>
        </template>
    </confirmation-modal>
</template>
<script setup>
import ConfirmationModal from '@/Jetstream/ConfirmationModal.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import { ref, defineEmits, onMounted } from 'vue'
import { useForm, usePage } from '@inertiajs/vue3'
import { onKeyUp } from '@vueuse/core'

const emit = defineEmits(['close'])

const props = defineProps({
    formName: [String],
    show: Boolean,
})

const formName = ref(JSON.parse(JSON.stringify(props.formName)))
const formId = usePage().props.form._id

const form = useForm({
    name: formName.value,
})

const onCancel = () => {
    form.reset()
    emit('close')
}

const onSubmit = () => {
    form.patch(route('forms.name.update', { form: formId }), {
        preserveScroll: true,
        preserveState: false,
        onSuccess: () => {
            emit('close')
        },
    })
}

onMounted(() => {
    onKeyUp('Enter', onSubmit)
})
</script>
