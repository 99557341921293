<template>
    <button class="btn !rounded-full flex items-center" @click="toggle">
        <icon :name="published ? 'eye-off' : 'eye'" class="w-4 h-4 mr-2" />
        <span>{{ published ? 'Un-publish' : 'Publish' }}</span>
    </button>
</template>
<script setup>
import Icon from '@/Components/Icon.vue'
import { ref } from 'vue'
import { useForm, usePage } from '@inertiajs/vue3'

const { form_uuid: _id } = usePage().props?.meta
const published = ref(usePage().props?.meta?.published)

function toggle() {
    const publishForm = useForm({
        published: !published.value,
    })

    publishForm.patch(route('forms.publish', { form: _id }), {
        only: ['meta'],
        onSuccess: () => {
            published.value = usePage().props.meta?.published
        },
    })
}
</script>
