<template>
    <modal :show="true" :max-width="maxWidth" :closeable="closeable" @close="close">
        <div class="flex-1 bg-gray-50">
            <div class="bg-white shadow-lg rounded overflow-y-scroll no-scrollbar border border-gray-300 h-[500px]">
                <div class="divide-y divide-gray-100">
                    <Disclosure v-if="fields.length > 0" v-slot="{ open }">
                        <DisclosureButton
                            class="flex w-full px-4 py-2 text-sm font-medium text-left focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
                        >
                            <ChevronUpIcon :class="open ? 'rotate-180 transform' : ''" class="w-5 h-5 mr-2 text-slate-800" />
                            <h3 class="text-xs font-semibold text-gray-600">My form fields</h3>
                        </DisclosureButton>
                        <DisclosurePanel as="div" class="divide-y divide-gray-200">
                            <div
                                v-if="!loadingFields"
                                v-for="field in fields"
                                :key="field._id"
                                class="group flex items-center text-xs py-1 pl-[25px] pr-4 hover:bg-gray-50 transition-colors hover:text-blue-600 cursor-pointer"
                            >
                                <button type="button" class="mr-2 opacity-0 btn group-hover:opacity-100" @click="handleRemoveField(field._id, true)">
                                    <icon name="trash" class="w-4 h-4" />
                                </button>

                                <div
                                    class="flex justify-between items-start"
                                    :tooltip="field.name"
                                    :class="{
                                        'text-red-500': !isUsed(field._id),
                                    }"
                                >
                                    <span>{{ field.label }} </span>
                                </div>
                            </div>
                            <div v-else class="text-xs">Loading fields...</div>
                        </DisclosurePanel>
                    </Disclosure>
                    <Disclosure :default-open="true" v-slot="{ open }">
                        <DisclosureButton
                            class="flex w-full px-4 py-2 text-sm font-medium text-left focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
                        >
                            <ChevronUpIcon :class="open ? 'rotate-180 transform' : ''" class="w-5 h-5 mr-2 text-slate-800" />
                            <h3 class="text-xs font-semibold text-gray-600">Layout</h3>
                        </DisclosureButton>
                        <DisclosurePanel class="px-2 pt-2 pb-2 text-sm text-gray-500" as="div">
                            <div class="grid grid-cols-2 gap-2 p-2">
                                <div
                                    v-for="(f, k) in layoutOptions"
                                    :key="k"
                                    class="flex items-center p-2 text-xs border border-gray-200 rounded cursor-pointer hover:bg-gray-100 hover:text-blue-500"
                                    @click="addBlock(f.type)"
                                >
                                    <field-icon :type="f.type" class="w-4 h-4 mr-2" />
                                    <span>{{ f.label }}</span>
                                </div>
                            </div>
                        </DisclosurePanel>
                    </Disclosure>
                    <Disclosure :default-open="true" v-slot="{ open }">
                        <DisclosureButton
                            class="flex w-full px-4 py-2 text-sm font-medium text-left focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
                        >
                            <ChevronUpIcon :class="open ? 'rotate-180 transform' : ''" class="w-5 h-5 mr-2 text-slate-800" />
                            <h3 class="text-xs font-semibold text-gray-600">Field types</h3>
                        </DisclosureButton>
                        <DisclosurePanel class="px-2 pt-2 pb-2 text-sm text-gray-500" as="div">
                            <div class="grid grid-cols-2 gap-2 p-2">
                                <div
                                    v-for="(f, k) in options"
                                    :key="k"
                                    class="flex items-center p-2 text-xs border border-gray-200 rounded cursor-pointer hover:bg-gray-100 hover:text-blue-500"
                                    :class="{ 'bg-blue-50': selected === k }"
                                    @click="addBlock(f.type)"
                                >
                                    <field-icon :type="f.type" class="mr-2" />
                                    <span>{{ f.label }}</span>
                                </div>
                            </div>
                        </DisclosurePanel>
                    </Disclosure>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '@/Jetstream/Modal.vue'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronUpIcon } from '@heroicons/vue/solid'
import FieldIcon from '@/Components/FieldIcon.vue'
import Icon from './Icon.vue'
import { doesFieldExist } from '@/utils'

export default {
    emits: ['close'],

    components: {
        Modal,
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        ChevronUpIcon,
        FieldIcon,
        Icon,
    },

    props: {
        show: {
            default: false,
        },
        maxWidth: {
            default: 'xl',
        },
        closeable: {
            default: true,
        },
        options: {},
        selected: {},
        addBlock: {},
        removeField: {},
        layout: {},
    },

    data() {
        return {
            fields: [],
            loadingFields: true,
        }
    },

    methods: {
        doesFieldExist,
        close() {
            this.$emit('close')
        },
        isUsed(id) {
            return doesFieldExist(this.layout.children, id)
        },
        restoreField(name) {
            // this.$page.props.form.layout = this.$page.props.form.layout.filter((field) => field._id !== name)
        },
        async handleRemoveField(id, removeFromLayout = false) {
            const confirmed = confirm('Are you sure you want to remove this field?')
            if (!confirmed) return false

            const removed = await this.removeField(id, removeFromLayout)
            if (removed) {
                this.fields = this.fields.filter((field) => field._id !== id)
            }
        },
    },
    mounted() {
        axios
            .get(route('builder.fields.get', { form: this.$page.props.form._id }))
            .then((response) => {
                this.fields = response.data.fields
            })
            .finally(() => {
                this.loadingFields = false
            })
    },

    computed: {
        layoutOptions() {
            return [
                {
                    type: 'row',
                    label: 'Row',
                },
                {
                    type: 'stack',
                    label: 'Stack',
                },
            ]
        },
    },
}
</script>
