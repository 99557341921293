<template>
    <div>
        <div class="max-w-lg p-3 lg:p-6">
            <h3 class="page-title">Webhook</h3>
            <p class="page-desc">Whenever a form entry is stored we'll POST it to your favorite URL. The payload will contain the entry data.</p>

            <form
                @submit.prevent="
                    webhookForm.post($route('form.settings.webhook.update', form), {
                        preserveState: false,
                    })
                "
            >
                <div class="mt-4">
                    <jet-label for="webhookUrl" value="Webhook URL" />
                    <jet-input id="webhookUrl" class="block w-full mt-1" v-model="webhookForm.webhook_url" type="text" />
                    <jet-input-error :message="webhookForm.errors.webhook_url" class="mt-2" />
                </div>

                <div class="mt-4">
                    <jet-label for="method" value="HTTP verb" />
                    <jet-select id="method" v-model="webhookForm.webhook_http_verb">
                        <template #options>
                            <option value="GET">GET</option>
                            <option value="POST">POST</option>
                            <option value="PUT">PUT</option>
                            <option value="PATCH">PATCH</option>
                            <option value="DELETE">DELETE</option>
                        </template>
                    </jet-select>
                    <jet-input-error :message="webhookForm.errors.webhook_http_verb" class="mt-2" />
                </div>

                <div class="mt-4">
                    <jet-button class="mr-3" :class="{ 'opacity-25': webhookForm.processing }" :disabled="webhookForm.processing">Save</jet-button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import ShowFormLayout from '@/Layouts/ShowFormLayout.vue'

export default {
    layout: [AppLayout, ShowFormLayout],
}
</script>

<script setup>
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetSelect from '@/Jetstream/Select.vue'
import JetInputError from '@/Jetstream/InputError.vue'
import JetButton from '@/Jetstream/Button.vue'
import { useForm } from '@inertiajs/vue3'

const props = defineProps({
    form: Object,
})

const webhookForm = useForm({
    _method: 'PUT',
    webhook_url: props.form.webhook_url,
    webhook_http_verb: props.form.webhook_http_verb,
})
</script>
