<template>
    <app-layout>
        <template #header>
            <h2 class="text-sm font-semibold leading-tight text-white">Workspace Settings</h2>
        </template>

        <div class="py-10 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <update-team-name-form :team="team" :permissions="permissions" />

            <payments />

            <team-member-manager class="mt-10 sm:mt-0" :team="team" :available-roles="availableRoles" :user-permissions="permissions" />

            <template v-if="permissions.canDeleteTeam && !team.personal_team">
                <jet-section-border />

                <delete-team-form class="mt-10 sm:mt-0" :team="team" />
            </template>
        </div>
    </app-layout>
</template>

<script>
import TeamMemberManager from './TeamMemberManager.vue'
import AppLayout from '@/Layouts/AppLayout.vue'
import DeleteTeamForm from './DeleteTeamForm.vue'
import JetSectionBorder from '@/Jetstream/SectionBorder.vue'
import UpdateTeamNameForm from './UpdateTeamNameForm.vue'
import Payments from '@/Components/Payments.vue'

export default {
    props: ['team', 'availableRoles', 'permissions'],

    components: {
        AppLayout,
        DeleteTeamForm,
        JetSectionBorder,
        TeamMemberManager,
        UpdateTeamNameForm,
        Payments,
    },
}
</script>
