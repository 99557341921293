<template>
    <div>
        <jet-label for="content" value="Hero content" class="mb-3" />
        <div ref="contentWrapper" id="content" class="p-10 rounded-lg btn-shadow"></div>
        <slot />
    </div>
</template>
<script setup>
import JetLabel from '@/Jetstream/Label.vue'
import EditorJS from '@editorjs/editorjs'
import Header from '@editorjs/header'
import ImageTool from '@editorjs/image'
import List from '@editorjs/list'
import { usePage } from '@inertiajs/vue3'
import { ref } from 'vue'

const contentWrapper = ref(null)
const formId = usePage().props.form._id

const editor = new EditorJS({
    /**
     * Id of Element that should contain Editor instance
     */
    holder: 'content',
    tools: {
        header: {
            class: Header,
            inlineToolbar: true,
            config: {
                placeholder: 'Enter a header',
                levels: [1, 2, 3],
                defaultLevel: 1,
            },
        },
        list: {
            class: List,
            inlineToolbar: true,
            config: {
                defaultStyle: 'unordered',
            },
        },
        image: {
            class: ImageTool,
            inlineToolbar: true,
            config: {
                endpoints: {
                    byFile: route('form.settings.hero.content-image', { form: formId }),
                },
                additionalRequestHeaders: {
                    'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content,
                },
            },
        },
    },
    data: usePage().props.form.hero_content,
    minHeight: 50,
})
const emits = defineEmits(['update:modelValue'])

const save = async () => {
    try {
        const content = await editor.save()
        if (content.blocks.length === 0) return null

        return content
    } catch (err) {
        return null
    }
}

defineExpose({ save })
</script>
