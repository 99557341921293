<template>
    <div class="md:grid md:grid-cols-3 md:gap-6 mt-6">
        <jet-section-title>
            <template #title>Payments history</template>
            <template #description>Complete list of all payments ever made and invoices.</template>
        </jet-section-title>
        <div class="md:mt-0 md:col-span-2 py-5 sm:p-6">
            <div class="flex flex-col">
                <div class="-my-2 sm:-mx-6">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6">
                        <div class="border overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead class="bg-white">
                                    <tr>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment Date</th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Invoice</th>
                                    </tr>
                                </thead>
                                <tbody v-if="payments.length" class="bg-white divide-y divide-gray-200">
                                    <tr v-for="payment in payments" :key="payment.id">
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <span
                                                class="text-xs font-semibold px-2 py-1 rounded-full bg-gray-100 text-gray-600"
                                                :class="{
                                                    '!bg-green-100 text-green-600': payment.status === 'paid',
                                                }"
                                            >
                                                {{ payment.status }}
                                            </span>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="text-sm text-gray-900">{{ payment.date }}</div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="text-sm text-gray-600">{{ payment.total }}</div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <a :href="payment.download_url">
                                                <span class="p-2 inline-flex rounded-full hover:bg-blue-100 cursor-pointer">
                                                    <icon name="cloud-download" class="w-5 h-5 text-blue-500" />
                                                </span>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else class="bg-white divide-y divide-gray-200">
                                    <tr>
                                        <td colspan="3" class="p-3">
                                            <div class="text-xs text-center p-3 text-gray-400">There are no payment records.</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import JetSectionTitle from '@/Jetstream/SectionTitle.vue'
import Icon from '../../../Components/Icon.vue'

export default {
    props: ['payments'],
    components: {
        JetSectionTitle,
        Icon,
    },
}
</script>
