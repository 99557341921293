<template>
    <button
        :type="type"
        class="
            inline-flex
            items-center
            px-4
            py-2
            text-sm
            leading-4
            bg-blue-600
            rounded-md
            font-bold
            text-xs text-white
            hover:bg-blue-700
            active:bg-blue-900
            focus:outline-none
            focus:border-blue-900
            focus:ring focus:ring-blue-300
            disabled:opacity-25
            transition
        "
    >
        <slot></slot>
    </button>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'submit',
        },
    },
}
</script>
