<template>
    <div>
        <div class="max-w-xl p-3: lg:p-6">
            <h3 class="page-title">{{ integration.title }}</h3>
            <p class="page-desc">The Google Calendar app helps you spend less time managing your schedule and more time enjoying it.</p>

            <div
                v-if="integration.on && integration.app_key"
                class="my-4 bg-white max-w-[520px] shadow rounded-lg p-2 font-rounded flex items-center justify-between"
            >
                <div class="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 text-green-600 fill-current" viewBox="0 0 24 24" width="24" height="24">
                        <path
                            d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-2.3-8.7l1.3 1.29 3.3-3.3a1 1 0 0 1 1.4 1.42l-4 4a1 1 0 0 1-1.4 0l-2-2a1 1 0 0 1 1.4-1.42z"
                        />
                    </svg>
                    <span class="text-sm">Yay! {{ integration.title }} is enabled.</span>
                </div>
                <button @click="update = !update" type="button" class="p-2">
                    <icon name="pencil" class="w-4 text-black" />
                </button>
            </div>

            <form
                v-if="!integration.on || update"
                class="mt-4"
                @submit.prevent="
                    formData.post(
                        route(
                            'form.integrations.update',
                            {
                                form,
                                integration: 'google-calendar',
                            },
                            {
                                onSuccess: () => {
                                    this.update = false
                                },
                            }
                        )
                    )
                "
            >
                <div class="w-full mb-2" v-if="integration.meta">
                    <p>Pick the calendar you want to create new events in.</p>
                    <jet-label class="mt-2" for="calendarId" value="Calendar" />
                    <jet-select class="max-w-[300px] w-full" v-model="formData.app_key">
                        <template #options>
                            <option value="" disabled selected>Pick a calendar</option>
                            <option v-for="(c, k) in calendars" :value="c.id" :key="k">
                                {{ c.summary }}
                            </option>
                        </template>
                    </jet-select>
                    <jet-input-error class="mt-1" :message="formData.errors.app_key" />

                    <jet-label class="w-full mt-2" for="message" value="Message" />
                    <auto-resize>
                        <template v-slot:default="{ resize }">
                            <jet-textarea class="w-full" v-model="formData.app_secret" @input="resize" />
                        </template>
                    </auto-resize>
                    <jet-input-error class="mt-1" :message="formData.errors.app_secret" />

                    <div class="flex inline-flex">
                        <div v-for="field in form.fields" class="p-1 mb-1 mr-1 bg-white rounded shadow" :ref="field.name" @click="add(field)">
                            <div class="p-1 text-white transition bg-blue-500 rounded cursor-pointer hover:bg-blue-600 text-tiny">
                                {{ field.label }}
                            </div>
                        </div>
                    </div>
                    <div class="mt-2 space-y-2">
                        <p>Examples of calendar events you can automatically create for yourself with each form entry received.</p>
                        <div class="flex items-center p-3 bg-white rounded shadow">
                            <div class="w-10 h-10 p-2 mr-3 bg-green-100 rounded-full">
                                <icon name="calendar" class="w-6 h-6 text-green-500" />
                            </div>
                            <div>
                                <jet-label class="mb-1 text-xs font-bold text-blue-500">Upcoming event</jet-label>
                                <div class="text-xl">
                                    <p>Call appointment with [name] on [date].</p>
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center p-3 bg-white rounded shadow">
                            <div class="w-10 h-10 p-2 mr-3 bg-orange-100 rounded-full">
                                <icon name="calendar" class="w-6 h-6 text-orange-500" />
                            </div>
                            <div>
                                <jet-label class="mb-1 text-xs font-bold text-blue-500">Upcoming event</jet-label>
                                <div class="text-xl">
                                    <p>Client appointment about [subject] on [date].</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex items-center justify-between mt-4" v-if="integration.meta">
                    <jet-button class="mr-3" :class="{ 'opacity-25': formData.processing }" :disabled="formData.processing">Save </jet-button>
                    <button
                        type="button"
                        class="px-2 font-semibold text-red-500 underline text-tiny hover:bg-none hover:text-red-600"
                        @click="confirmIntegrationDeletion = true"
                    >
                        Remove
                    </button>
                </div>
            </form>

            <div class="flex items-center mt-4" v-if="!update && !integration.on && !integration.meta">
                <a class="flex items-center mr-2 btn btn-md" :href="$route('oauth2.google-calendar', form)" v-if="!integration.on">
                    <icon name="link" class="w-4 h-4" />
                    <span class="ml-2">Connect</span></a
                >
                <jet-secondary-button v-if="!update && !integration.on" @click="confirmIntegrationDeletion = true">Remove</jet-secondary-button>
            </div>

            <jet-confirmation-modal :show="confirmIntegrationDeletion" @close="confirmIntegrationDeletion = false">
                <template #title> Delete integration</template>

                <template #content>
                    Are you sure you want to remove <strong>{{ integration.title }}</strong> integration?
                </template>

                <template #footer>
                    <jet-danger-button
                        class="mr-2"
                        @click="removeIntegration"
                        :class="{ 'opacity-25': removeIntegrationForm.processing }"
                        :disabled="removeIntegrationForm.processing"
                    >
                        Yes, remove it
                    </jet-danger-button>
                    <jet-secondary-button @click="confirmIntegrationDeletion = false"> No, back out </jet-secondary-button>
                </template>
            </jet-confirmation-modal>
        </div>
    </div>
</template>
<script>
import ShowFormLayout from '@/Layouts/ShowFormLayout.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetDangerButton from '@/Jetstream/DangerButton.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue'
import JetInputError from '@/Jetstream/InputError.vue'
import JetSelect from '@/Jetstream/Select.vue'
import JetTextarea from '@/Jetstream/Textarea.vue'
import AppLayout from '@/Layouts/AppLayout.vue'
import Icon from '@/Components/Icon.vue'
import AutoResize from '@/Components/AutoResize'
import { useForm } from '@inertiajs/vue3'

export default {
    components: {
        AutoResize,
        Icon,
        ShowFormLayout,
        JetLabel,
        JetInput,
        JetInputError,
        JetDangerButton,
        JetSecondaryButton,
        JetConfirmationModal,
        JetButton,
        JetSelect,
        JetTextarea,
    },
    props: ['form', 'integration'],
    layout: [AppLayout, ShowFormLayout],
    data() {
        return {
            formData: useForm({
                _method: 'POST',
                app_key: this.integration.app_key,
                app_secret: this.integration.app_secret,
            }),
            confirmIntegrationDeletion: false,
            removeIntegrationForm: useForm({}),
            update: false,
            calendars: null,
        }
    },
    async mounted() {
        if (this.integration.meta) {
            await this.getCalendars()
        }
    },
    methods: {
        async getCalendars() {
            try {
                const res = await axios.get(
                    route('form.google-calendar.list', {
                        form: this.form,
                    })
                )
                this.calendars = res.data
            } catch (e) {
                this.calendars = null
            }
        },

        add(field) {
            let text = this.formData.app_secret

            this.formData.app_secret = `${text} [${field.name}]`
            this.$forceUpdate()
        },

        removeIntegration() {
            this.removeIntegrationForm.delete(
                route('form.integrations.destroy', {
                    form: this.$page.props.form._id,
                    integration: this.integration.name,
                }),
                {
                    errorBag: 'deleteIntegration',
                    preserveState: false,
                }
            )
        },
    },
}
</script>
