<template>
    <div class="h-screen overflow-y-auto no-scrollbar">
        <div class="p-6 mt-[13px] w-full relative">
            <fun-bg />

            <div
                class="relative bg-white max-w-[545px] pb-3 card-shadow md:pt-[65px] pt-8 px-5 md:px-[65px] rounded-lg mx-auto mt-[50px]"
            >
                <jet-validation-errors class="mb-4" />

                <div
                    v-if="status"
                    class="mb-4 text-xs md:text-sm font-medium text-green-600"
                >
                    {{ status }}
                </div>

                <h3
                    class="mb-4 leading-relaxed font-bold tracking-tight text-2xl text-[#2e161e]"
                >
                    Sign in to your account
                </h3>

                <form @submit.prevent="submit" class="space-y-4 md:space-y-8">
                    <div>
                        <jet-label for="email" value="Email" />
                        <jet-input
                            id="email"
                            type="email"
                            class="!h-[40px] block w-full"
                            v-model="form.email"
                            required
                            autofocus
                        />
                    </div>

                    <div class="mt-4">
                        <jet-label for="password" value="Password" />
                        <jet-input
                            id="password"
                            type="password"
                            class="!h-[40px] block w-full"
                            v-model="form.password"
                            required
                            autocomplete="current-password"
                        />
                    </div>

                    <div class="block mt-4">
                        <label class="flex items-center">
                            <jet-checkbox
                                name="remember"
                                v-model:checked="form.remember"
                            />
                            <span class="ml-2 text-sm text-gray-600"
                                >Remember me</span
                            >
                        </label>
                    </div>

                    <div class="flex items-stretch flex-col flex-1 mt-4">
                        <pf-button
                            class="!py-4 !btn-bg justify-center"
                            :class="{ 'opacity-25': form.processing }"
                            :disabled="form.processing"
                        >
                            Log in
                        </pf-button>
                        <div
                            class="mt-2 pb-4 md:mt-8 flex items-center space-x-2 mx-auto"
                        >
                            <Link
                                :href="$route('register')"
                                class="text-center rounded-md text-xs text-gray-400 hover:text-gray-900"
                            >
                                Sign up
                            </Link>
                            <Link
                                v-if="canResetPassword"
                                :href="$route('password.request')"
                                class="text-center rounded-md text-xs pl-2 text-gray-400 hover:text-gray-900"
                            >
                                Forgot your password?
                            </Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import JetAuthenticationCard from '@/Jetstream/AuthenticationCard.vue'
import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo.vue'
import PfButton from '@/Pixelform/Button.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetCheckbox from '@/Jetstream/Checkbox.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetValidationErrors from '@/Jetstream/ValidationErrors.vue'
import { useForm, Link } from '@inertiajs/vue3'
import FunBg from '@/Components/FunBg.vue'

export default {
    components: {
        JetAuthenticationCard,
        JetAuthenticationCardLogo,
        JetButton,
        FunBg,
        JetInput,
        JetCheckbox,
        PfButton,
        JetLabel,
        JetValidationErrors,
        Link,
    },

    props: {
        canResetPassword: Boolean,
        status: String,
    },

    data() {
        return {
            form: useForm({
                email: '',
                password: '',
                remember: false,
            }),
        }
    },

    methods: {
        submit() {
            this.form
                .transform((data) => ({
                    ...data,
                    remember: this.form.remember ? 'on' : '',
                }))
                .post(route('login'), {
                    onFinish: () => this.form.reset('password'),
                })
        },
    },
}
</script>
