<template>
    <label class="block mb-2 text-sm font-medium text-gray-800" :class="{ 'text-xs mb-1': !!small, 'opacity-50 cursor-not-allowed': disabled }">
        <span v-if="value">{{ value }}</span>
        <span v-else><slot></slot></span>
    </label>
</template>

<script>
export default {
    props: ['value', 'small', 'disabled'],
}
</script>
