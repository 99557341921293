<template>
    <div>
        <div class="flex items-center justify-between p-6">
            <div>
                <h3 class="text-3xl font-black leading-tight tracking-tight">Layouts</h3>
                <p class="max-w-xl text-sm text-lg text-gray-700">
                    Manage your forms appearance through layouts. You can make pretty sweet stuff. Give it a try.
                </p>
            </div>
            <Link as="button" :href="$route('layouts.create', { form })" class="flex items-center px-4 py-2 -my-2 bg-blue-600 rounded font-regular group">
                <div class="p-1 mr-2 transition bg-blue-700 rounded group-hover:bg-white">
                    <svg class="w-4 h-4 text-white group-hover:hidden" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                    </svg>
                    <span class="hidden w-4 h-4 text-sm leading-tight text-white group-hover:block group-hover:text-blue-600">C</span>
                </div>
                <span class="text-sm font-semibold text-white">Create new layout</span>
            </Link>
        </div>

        <div class="w-full p-6" v-if="formData.layouts.length">
            <Link
                :href="$route('layouts.edit', { layout, form })"
                v-for="(layout, index) in formData.layouts.filter((l) => l.type === 'SINGLE_STEP')"
                :key="index"
                class="flex items-center px-4 pb-2 mb-2 transition bg-white rounded-lg shadow"
            >
                <div class="grid w-full grid-cols-4 gap-12 py-2 place-items-center place-content-start">
                    <div class="w-full col-span-1">
                        <jet-label class="self-start mb-1 tracking-wide tracking-widest text-gray-700 uppercase text-tiny" for="label" name="label">
                            Layout Name
                        </jet-label>
                        <div class="inline px-2 py-1 text-xs text-white bg-black rounded">
                            {{ layout.name }}
                        </div>
                    </div>
                    <div class="w-full col-span-1">
                        <jet-label class="self-start mb-1 tracking-wide tracking-widest text-gray-700 uppercase text-tiny" for="name" name="name">
                            Layout Type
                        </jet-label>
                        <div class="inline px-2 py-1 text-xs text-white bg-blue-500 rounded">
                            {{ layout.type }}
                        </div>
                    </div>
                    <div class="col-span-1 w-full mt-[12px]">
                        <jet-label class="self-start text-xs font-semibold tracking-wide tracking-widest text-blue-500" for="selected" name="selected">
                            <div class="flex items-center" v-if="layout.selected">
                                <icon name="check" class="w-6 h-6 mr-2 text-blue-500" />
                                <span>Currently selected</span>
                            </div>
                            <Link
                                v-else
                                :href="$route('layouts.select', { layout: layout._id, form: this.$page.props.form })"
                                method="patch"
                                class="p-1 px-2 text-gray-700 border border-gray-200 rounded"
                                :data="{ selected: true }"
                                >Select</Link
                            >
                        </jet-label>
                    </div>
                    <div class="w-full col-span-1 pt-4 pr-4 text-right">
                        <button tooltip="Remove layout" @click.prevent="confirmLayoutDeletion(layout)" type="button">
                            <svg
                                class="w-6 text-gray-400 transition mb-1-400 hover:text-red-500"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                ></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </Link>
        </div>
        <div class="w-full p-6" v-else>
            <div class="bg-white rounded shadow">
                <img src="/images/no-data.svg" class="max-w-sm mx-auto" alt="No data illustration" />
            </div>
        </div>

        <jet-confirmation-modal :show="confirmingLayoutDeletion" @close="confirmingLayoutDeletion = false">
            <template #title> Delete Layout </template>

            <template #content> Are you sure you want to delete this layout? Once a layout is deleted, it's pretty gone... </template>

            <template #footer>
                <jet-secondary-button @click="confirmingLayoutDeletion = false"> Cancel </jet-secondary-button>

                <jet-danger-button
                    class="ml-2"
                    @click="deleteLayout"
                    :class="{ 'opacity-25': deleteLayoutForm.processing }"
                    :disabled="deleteLayoutForm.processing"
                >
                    Delete layout
                </jet-danger-button>
            </template>
        </jet-confirmation-modal>
    </div>
</template>

<script>
import ShowFormLayout from '@/Layouts/ShowFormLayout.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetSelect from '@/Jetstream/Select.vue'
import JetDangerButton from '@/Jetstream/DangerButton.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import AppLayout from '@/Layouts/AppLayout.vue'
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue'
import Icon from '@/Components/Icon.vue'
import { router, useForm, Link } from '@inertiajs/vue3'

export default {
    layout: [AppLayout, ShowFormLayout],
    components: {
        JetSecondaryButton,
        JetButton,
        JetDangerButton,
        JetSelect,
        JetInput,
        JetLabel,
        ShowFormLayout,
        JetConfirmationModal,
        Icon,
        Link,
    },
    props: ['form', 'layouts'],
    data() {
        return {
            confirmingLayoutDeletion: false,
            formData: useForm({
                layouts: this.layouts,
            }),
            deleteLayoutForm: useForm({}),
        }
    },
    watch: {
        layouts(val) {
            // update $inertia Form data - ugh
            this.formData.layouts = val
        },
    },
    methods: {
        async save() {
            await router.post(route('forms.layouts', this.form), this.formData)
        },
        confirmLayoutDeletion(layout) {
            this.confirmingLayoutDeletion = true
            this.layout = layout
        },
        deleteLayout() {
            this.deleteLayoutForm.delete(
                route('layouts.destroy', {
                    form: this.form._id,
                    layout: this.layout._id,
                }),
                {
                    errorBag: 'deleteLayout',
                    preserveState: false,
                }
            )
        },

        handleHotKey(e) {
            if (e.key === 'c' || e.key === 'C') {
                router.visit(route('layouts.create', { form: this.form }))
            }
        },
    },
    mounted() {
        document.addEventListener('keydown', this.handleHotKey)
    },

    beforeUnmount() {
        document.removeEventListener('keydown', this.handleHotKey)
    },
}
</script>

<style scoped></style>
