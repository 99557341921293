<template>
    <div class="relative flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:items-end justify-between mt-4 mb-2">
        <div class="flex flex-col sm:flex-row space-y-2">
            <button class="self-start order-2 sm:order-none mt-2 w-full sm:w-auto sm:mr-3 group" @click="selectColumns = !selectColumns" type="button">
                <div class="btn-shadow flex space-x-2 text-sm items-center rounded-lg w-full p-0.5">
                    <div class="p-1 bg-blue-100 rounded-lg group-hover:bg-blue-500 group-hover:shadow">
                        <icon name="adjustments" class="w-4 h-4 text-blue-500 transition group-hover:text-white" />
                    </div>
                    <span class="sm:hidden">viewable columns</span>
                </div>
            </button>
            <div class="relative group box !p-0 h-7 !leading-none">
                <div class="p-1 group-focus-within:bg-blue-500 absolute top-0.5 left-1 group-focus-within:shadow transition rounded-lg">
                    <icon name="search" class="w-4 h-4 text-gray-400 transition group-focus-within:text-white" />
                </div>
                <form
                    @submit.prevent="
                        searchForm.get(route('forms.inbox', $page.props.form), {
                            preserveState: true,
                        })
                    "
                >
                    <jet-input v-model="searchForm.q" class="p-1 pl-10 outline-none ring-0 focus:ring-0" />
                </form>
            </div>

            <button
                @click="removeSelectedRows"
                type="button"
                class="flex items-center sm:ml-2 btn !py-0 !px-0 text-white bg-white !rounded-lg group focus:ring-red-300"
                v-if="selected.length"
            >
                <div class="p-1 ml-1 bg-red-100 rounded-lg group-hover:bg-red-500 group-hover:shadow">
                    <icon name="trash" class="w-4 h-4 text-red-400 transition group-hover:text-white" />
                </div>
                <div class="py-1 pl-1 pr-2 text-sm text-gray-500">remove {{ selected.length }} row<span v-if="selected.length > 1">s</span></div>
            </button>
        </div>
        <div class="flex">
            <button
                @click="wipeTable"
                type="button"
                class="flex w-full sm:w-auto items-center sm:mr-2 text-white bg-white rounded-lg btn-shadow group focus:ring-red-300 hover:text-gray-500"
            >
                <div class="p-1 ml-1 bg-orange-100 rounded-lg group-hover:bg-orange-500 group-hover:shadow">
                    <icon name="hand" class="w-4 h-4 text-orange-500 transition group-hover:text-white" />
                </div>
                <span class="py-1 pl-1 pr-2 text-sm text-gray-500">wipe table</span>
            </button>
            <div v-if="selectColumns" class="w-[210px] absolute rounded-lg border shadow-sm top-9 left-0 bg-white z-10 flex flex-col">
                <form @submit.prevent="saveColumns">
                    <label
                        class="flex items-center justify-between h-10 px-2 transition border-b border-gray-200 rounded cursor-pointer hover:bg-gray-50"
                        v-for="(field, key) in columns.active"
                        v-if="columns.active.length"
                        :class="{ 'rounded-t-lg': key === 0 }"
                        :key="key"
                    >
                        <span class="ml-2 text-sm text-gray-600">{{ field.label }}</span>
                        <jet-checkbox v-model:checked="tableForm.cols" :value="field.name" />
                    </label>

                    <div v-if="columns.trashed?.length" class="flex items-center justify-between px-3 py-1 text-xs border-b border-gray-200">
                        <div class="flex items-center space-x-1">
                            <div>Trashed</div>

                            <info>
                                <p class="text-xs text-gray-500">These fields were once part of the form but have been trashed.</p>
                            </info>
                        </div>
                    </div>

                    <label
                        class="flex items-center justify-between h-10 px-2 transition border-b border-gray-200 rounded cursor-pointer hover:bg-gray-50"
                        v-for="(field, key) in columns.trashed"
                        v-if="columns.trashed.length"
                        :class="{ 'rounded-t-lg': key === 0 }"
                        :key="key"
                    >
                        <span class="ml-2 text-sm text-gray-600">{{ field.label }}</span>
                        <jet-checkbox v-model:checked="tableForm.cols" :value="field.name" />
                    </label>

                    <div v-if="columns.active.length === 0" class="p-4">
                        <p class="text-sm">Define what fields your form allows. Then you can select which columns to show in the table.</p>
                    </div>
                    <div class="transition bg-blue-600 rounded-b-lg hover:bg-blue-700 active:bg-blue-900">
                        <jet-button type="submit" class="w-full h-8 px-2 text-white" v-if="columns.active.length">
                            <icon name="check" class="w-6 h-6 mx-auto text-white" />
                        </jet-button>
                        <jet-button type="button" class="w-full h-8 py-1 text-white" v-else @click="selectColumns = false">Okay, got it. </jet-button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import Icon from '@/Components/Icon.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetCheckbox from '@/Jetstream/Checkbox.vue'
import JetDangerButton from '@/Jetstream/DangerButton.vue'
import { useForm, router } from '@inertiajs/vue3'
import Info from '@/Components/Info.vue'

export default {
    name: 'table-meta',
    components: { Icon, JetButton, JetInput, JetLabel, JetCheckbox, JetDangerButton, Info },
    props: {
        selected: {},
    },
    data() {
        return {
            selectColumns: false,
            tableForm: useForm({
                cols: this.$page.props.form?.table_columns || [],
            }),
            searchForm: useForm({
                q: this.filters?.q,
            }),
        }
    },
    methods: {
        removeSelectedRows() {
            const confirmed = confirm('Are you sure you want to remove the selected rows?')

            if (!confirmed) {
                return false
            }

            router.post(
                route('inbox.remove-entries', {
                    form: this.$page.props.form?._id,
                }),
                {
                    ids: this.selected,
                },
                {
                    preserveScroll: true,
                    preserveState: false,
                }
            )
        },
        wipeTable() {
            const confirmed = confirm('Are you sure you want to wipe the table?')

            if (!confirmed) {
                return false
            }

            router.visit(
                route('inbox.wipe', {
                    form: this.$page.props.form?._id,
                }),
                {
                    method: 'post',
                    preserveScroll: true,
                    preserveState: false,
                }
            )
        },
        saveColumns() {
            this.tableForm
                .transform((data) => {
                    let cols = []

                    for (const col of data.cols) {
                        if (this.$page.props.form?.columns.filter((c) => c.name === col).length > 0) {
                            cols.push(col)
                        }
                    }

                    return {
                        ...data,
                        cols,
                    }
                })
                .post(
                    route('forms.inbox.table-columns', {
                        form: this.$page.props.form?._id,
                    }),
                    {
                        preserveScroll: true,
                        preserveState: false,
                        onSuccess: () => {
                            this.selectColumns = false
                        },
                    }
                )
        },
    },
    computed: {
        columns() {
            const allColumns = this.$page.props.form?.columns

            return {
                active: allColumns.filter((col) => !col.trashed),
                trashed: allColumns.filter((col) => col.trashed),
            }
        },
        tableColumns() {
            return this.$page.props.form?.table_columns
        },
    },
}
</script>
