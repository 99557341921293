<template>
    <input type="file" class="hidden" ref="photo" @change="updatePhotoPreview" />
    <jet-label for="photo" value="Image" />
    <div
        @click.prevent="selectNewPhoto"
        v-if="!photoPreview"
        class="mt-2 border hover:bg-gray-50 transition border-dashed flex items-center justify-center border-stackborder rounded-lg h-[230px] w-full hover:border-slate-300 cursor-pointer"
        tooltip="Select a new image"
    >
        <img :alt="field.label + ' product image'" v-if="localImageUrl" :src="localImageUrl" class="rounded-lg h-[230px] object-cover" />
        <icon v-else name="picture" class="w-12 h-12" />
    </div>

    <div @click.prevent="selectNewPhoto" class="mt-2" v-show="photoPreview">
        <span
            tooltip="Select A New Photo"
            class="block w-full rounded-lg cursor-pointer object-cover h-[230px]"
            :style="
                'background-size: cover; background-repeat: no-repeat; background-position: center center; background-image: url(\'' + photoPreview + '\');'
            "
        >
        </span>
    </div>

    <div class="mt-2 space-y-2">
        <button type="button" class="btn !py-3 w-full" :disabled="processing" @click.prevent="upload" v-if="photoPreview">Upload</button>
        <button type="button" class="btn !py-3 w-full" :disabled="processing" @click.prevent="remove" v-if="localImageUrl || photoPreview">Remove</button>
    </div>

    <div v-if="error" class="mt-2 text-error" v-text="error" />
</template>
<script setup>
import { ref } from 'vue'
import JetLabel from '@/Jetstream/Label.vue'
import Icon from '@/Components/Icon.vue'
import { usePage } from '@inertiajs/vue3'

const props = defineProps({
    field: {
        type: Object,
        required: true,
    },
})

const localImageUrl = ref(JSON.parse(JSON.stringify(props.field.img_url)))

const photo = ref(null)
const photoPreview = ref(null)
const error = ref(null)
const processing = ref(false)

const emit = defineEmits(['done'])

const selectNewPhoto = () => photo.value.click()

const upload = async () => {
    try {
        processing.value = true

        const formData = new FormData()
        formData.append('img_file', photo.value.files[0])

        const { data } = await axios.post(
            route('builder.field.update-img', {
                form: usePage().props.form._id,
                field: props.field._id,
            }),
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        )
        error.value = null

        emit('done', {
            img: data.field.img,
            img_url: data.field.img_url,
        })
    } catch (err) {
        error.value = err.response.data.message
    } finally {
        processing.value = false
    }
}

const remove = async () => {
    const confirmed = confirm('Are you sure you want to remove the image?')
    if (!confirmed) return false

    try {
        processing.value = true
        await axios.delete(
            route('builder.field.remove-img', {
                form: usePage().props.form._id,
                field: props.field._id,
            })
        )

        photoPreview.value = null
        localImageUrl.value = null
        error.value = null
        emit('done', {
            img: null,
            img_url: null,
        })
    } catch (err) {
        error.value = 'Oops! Failed removing image. Please try again afte reloading the page.'
    } finally {
        processing.value = false
    }
}

const updatePhotoPreview = () => {
    photoPreview.value = null
    const reader = new FileReader()

    reader.onload = (e) => {
        photoPreview.value = e.target.result
    }

    reader.readAsDataURL(photo.value.files[0])
}
</script>
