import './bootstrap'

import { createApp, h } from 'vue'
import { Chart, registerables } from 'chart.js'
import { createInertiaApp, usePage } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'

Chart.register(...registerables)

createInertiaApp({
    id: 'app',
    resolve: (name) => {
        const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
        return resolvePageComponent(`./Pages/${name}.vue`, pages)
    },

    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
        const page = usePage()

        app.config.globalProperties.$route = route
        app.config.globalProperties.$page = page

        app.use(plugin).mount(el)
    },
})
