<template>
    <div>
        <jet-section-border />

        <jet-action-section>
            <template #title> Payments integration </template>

            <template #description> We partner with Stripe to provide a secure and reliable platform for making payments. </template>

            <template #content>
                <loading-section v-if="loading" />
                <div class="w-[65%]" v-else>
                    <div class="space-y-3" v-if="accounts.length > 0">
                        <connect-account
                            v-for="account in accounts"
                            :key="account.id"
                            :account="account"
                            @remove="removeConnectedAccount(account.stripe_account_id)"
                        />
                    </div>

                    <setup-connect-account />
                </div>
            </template>
        </jet-action-section>
    </div>
</template>
<script setup>
import { usePage } from '@inertiajs/vue3'
import LoadingSection from '@/Components/LoadingSection.vue'
import JetActionSection from '@/Jetstream/ActionSection.vue'
import JetSectionBorder from '@/Jetstream/SectionBorder.vue'
import ConnectAccount from '@/Components/Connect/ConnectAccount.vue'
import SetupConnectAccount from '@/Components/Connect/SetupConnectAccount.vue'

import axios from 'axios'
import { ref } from 'vue'

const accounts = ref([])
const props = usePage().props
const loading = ref(true)

axios
    .get(
        route('connect-accounts.index', {
            team: props.team.id,
        })
    )
    .then((response) => {
        accounts.value = response.data.accounts
        loading.value = false
    })

const removeConnectedAccount = async (accountId) => {
    const confirmed = confirm('Are you sure you want to remove this stripe integration?')

    if (!confirmed) return false

    try {
        const res = await axios.delete(
            route('connect-accounts.destroy', {
                team: props.team.id,
                connectAccount: accountId,
            })
        )

        if (res.status === 200) {
            accounts.value = accounts.value.filter((account) => account.stripe_account_id !== accountId)
        }
    } catch (error) {
        alert('An error occurred while removing the stripe integration. Please try again after refreshing the page.')
    }
}
</script>
