<template>
    <div class="min-h-screen flex flex-row sm:justify-center items-stretch pt-6 sm:pt-0">
        <div class="flex-1 flex flex-col justify-center items-center">
            <div>
                <slot name="logo" />
                <slot name="message" />
            </div>

            <div class="w-full sm:max-w-md mt-6 px-6 py-4 bg-white overflow-hidden sm:rounded-lg">
                <slot />
            </div>
        </div>
    </div>
</template>
