<template>
    <div class="absolute top-0 left-0 bg-white w-full h-screen overflow-y-scroll flex justify-center z-50">
        <div class="max-w-2xl px-6 pt-5 w-full">
            <Link :href="$route('form.settings.mail', { form: formId })"><icon name="arrow-right" class="btn mb-4 h-6 !py-0 px-1 mr-2 -scale-x-100" /></Link>
            <div class="flex flex-col">
                <h3 class="text-3xl font-bold leading-relaxed">New entry</h3>
                <p>Email template that will be sent to you once a successful form entry is made.</p>
            </div>

            <form
                class="pb-10 w-full"
                @submit.prevent="mailForm.post($route('form.settings.mail.template.update', { form: formId, mailTemplate: template.id }))"
            >
                <!-- <div v-if="smtpEnabled" class="mt-4">
                    <jet-toggle
                        class="mt-1"
                        name="default_server"
                        :value="mailForm.mail_settings.default_server"
                        v-model:checked="mailForm.mail_settings.default_server"
                        label="Default server"
                    />
                    <jet-input-error :message="mailForm.errors['mail_settings.default_server']" class="mt-2" />
                </div> -->

                <div class="my-4">
                    <label for="enabled" class="flex items-center">
                        <jet-checkbox id="enabled" :value="mailForm.enabled" v-model:checked="mailForm.enabled" />
                        <span class="ml-2 text-sm text-gray-600">Enable this template</span>
                    </label>
                </div>

                <div class="grid grid-cols-2 gap-5 mt-4">
                    <div>
                        <div class="flex items-center">
                            <jet-label for="subject" value="Receiver email address" class="mb-0" />
                            <info class="ml-2">
                                <p class="text-xs text-body">
                                    When a new form entry is created the email address below will receive a notification. If left blank no mail notification
                                    will be sent.
                                </p>
                            </info>
                        </div>
                        <jet-input id="notifys" type="email" class="block w-full mt-2" v-model="mailForm.notify_emails" autofocus />
                        <jet-input-error :message="mailForm.errors['notify_emails']" class="mt-2" />
                    </div>

                    <div>
                        <jet-label for="subject" value="Subject" />
                        <jet-input id="type" type="text" class="block w-full mt-1" v-model="mailForm.subject" />
                        <jet-input-error :message="mailForm.errors['subject']" class="mt-2" />
                    </div>
                </div>

                <div class="mt-4">
                    <toolbar element="body" />
                    <jet-textarea id="body" rows="12" class="w-full" v-model="mailForm.body" />
                    <jet-input-error :message="mailForm.errors['body']" class="mt-2" />
                </div>

                <!-- <div v-if="!mailForm.mail_settings.default_server">
                    <h3 class="mt-4 font-bold">Custom mail server</h3>
                    <p>You can set up your own custom mail server below. Make sure the credentials entered are correct and we will take care of the rest.</p>

                    <div class="mt-4">
                        <jet-label for="host" value="Host" />
                        <jet-input id="host" type="text" class="block w-full mt-1" v-model="mailForm.mail_settings.server.host" />
                        <jet-input-error :message="mailForm.errors['mail_settings.server.host']" class="mt-2" />
                    </div>

                    <div class="grid grid-cols-2 gap-5 mt-4">
                        <div>
                            <jet-label for="port" value="Port" />
                            <jet-input id="port" type="text" class="block w-full mt-1" v-model="mailForm.mail_settings.server.port" />
                            <jet-input-error :message="mailForm.errors['mail_settings.server.port']" class="mt-2" />
                        </div>

                        <div>
                            <jet-label for="encryption" value="Encryption" />
                            <jet-input id="encryption" type="text" class="block w-full mt-1" v-model="mailForm.mail_settings.server.encryption" />
                            <jet-input-error :message="mailForm.errors['mail_settings.server.encryption']" class="mt-2" />
                        </div>
                        <div>
                            <jet-label for="username" value="Username" />
                            <jet-input id="username" type="text" class="block w-full mt-1" v-model="mailForm.mail_settings.server.username" />
                            <jet-input-error :message="mailForm.errors['mail_settings.server.username']" class="mt-2" />
                        </div>

                        <div>
                            <jet-label for="password" value="Password" />
                            <jet-input id="password" type="password" class="block w-full mt-1" v-model="mailForm.mail_settings.server.password" />
                            <jet-input-error :message="mailForm.errors['mail_settings.server.password']" class="mt-2" />
                        </div>
                    </div>

                    <h3 class="mt-4 font-bold">Sender details</h3>
                    <p>
                        Make your emails friendly. Specify sender name and email address. This will also help your emails to be recognised and not be marked as
                        spam.
                    </p>

                    <div class="mt-4">
                        <jet-label for="senderName" value="Name" />
                        <jet-input id="text" type="text" class="block w-full mt-1" v-model="mailForm.mail_settings.server.sender_name" />
                        <jet-input-error :message="mailForm.errors['mail_settings.server.sender_name']" class="mt-2" />
                    </div>

                    <div class="mt-4">
                        <jet-label for="senderEmail" value="Email" />
                        <jet-input id="text" type="text" class="block w-full mt-1" v-model="mailForm.mail_settings.server.sender_email" />
                        <jet-input-error :message="mailForm.errors['mail_settings.server.sender_email']" class="mt-2" />
                    </div>
                </div> -->

                <div class="mt-4 flex flex-col space-y-3">
                    <jet-button class="justify-center !py-3" :class="{ 'opacity-25': mailForm.processing }" :disabled="mailForm.processing">Save</jet-button>
                    <jet-secondary-button class="justify-center !py-3" @click.prevent="mailForm.reset()" type="button">Reset</jet-secondary-button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import ShowFormLayout from '@/Layouts/ShowFormLayout.vue'
export default {
    layout: [AppLayout, ShowFormLayout],
}
</script>
<script setup>
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetTextarea from '@/Jetstream/Textarea.vue'
import JetInputError from '@/Jetstream/InputError.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import Info from '@/Components/Info.vue'
import '@github/markdown-toolbar-element'
import Toolbar from '@/Components/Toolbar.vue'
import { useForm, usePage, Link } from '@inertiajs/vue3'
import Icon from '@/Components/Icon.vue'
import JetCheckbox from '@/Jetstream/Checkbox.vue'

const props = defineProps({
    template: Object,
})

const mailForm = useForm({
    _method: 'POST',
    ...Object.assign({}, props.template),
})

const formId = usePage().props.meta.form_uuid
</script>

<style scoped></style>
