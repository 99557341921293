<template>
    <div class="p-3 lg:p-6">
        <div class="max-w-2xl flex-1">
            <h3 class="page-title">Mail</h3>
            <p class="page-desc">
                Tailor your email templates from here so your end users have the
                best experience.
            </p>
            <div class="grid grid-cols-2 gap-5 mt-4 mb-8">
                <Link
                    v-for="template in templates"
                    :key="template.id"
                    class="text-xs p-3 bg-white rounded-lg border border-gray-100 text-slate-700"
                    :href="
                        $route('form.settings.mail.template', {
                            form: formId,
                            mailTemplate: template.id,
                        })
                    "
                >
                    <div
                        class="text-xs tracking-wider py-0.5 px-2 rounded-lg inline-block mb-2"
                        :class="{
                            'bg-green-100 text-green-500': template.enabled,
                            'bg-red-100 text-red-500': !template.enabled,
                        }"
                    >
                        <div v-if="template.type === 'payment_confirmation'">
                            {{ template.enabled ? 'Enabled' : 'Needs action' }}
                        </div>
                        <div v-else>
                            {{ template.enabled ? 'Enabled' : 'Disabled' }}
                        </div>
                    </div>
                    <div class="text-xl font-semibold leading-tight">
                        {{ template.label }}
                    </div>
                </Link>
            </div>
        </div>
    </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import ShowFormLayout from '@/Layouts/ShowFormLayout.vue'
export default {
    layout: [AppLayout, ShowFormLayout],
}
</script>
<script setup>
import { usePage, Link } from '@inertiajs/vue3'

const props = defineProps({
    templates: Array,
    form_requires_payment: Boolean,
})

const formId = usePage().props.meta.form_uuid
</script>
