<template>
    <div>
        <div class="max-w-xl p-3 lg:p-6">
            <h3 class="page-title">Slack</h3>
            <p class="page-desc">Slack is a new way to communicate with your team. It’s faster, better organised and more secure than email.</p>

            <div v-if="integration.on" class="my-4 bg-white max-w-[520px] border rounded-lg p-2 font-rounded flex items-center justify-between">
                <div class="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 text-green-600 fill-current" viewBox="0 0 24 24" width="24" height="24">
                        <path
                            d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-2.3-8.7l1.3 1.29 3.3-3.3a1 1 0 0 1 1.4 1.42l-4 4a1 1 0 0 1-1.4 0l-2-2a1 1 0 0 1 1.4-1.42z"
                        />
                    </svg>
                    <span class="text-sm">Yay! {{ integration.title }} is enabled.</span>
                </div>
                <jet-secondary-button @click="confirmIntegrationDeletion = true" type="button" class="!p-2">Remove</jet-secondary-button>
            </div>

            <div class="flex items-center mt-4">
                <a class="flex items-center btn btn-md" :href="$route('oauth2.slack', form)" v-if="!integration.on">
                    <icon name="link" class="w-4 h-4" />
                    <span class="ml-2">Connect</span></a
                >
                <jet-secondary-button class="ml-2" @click="confirmIntegrationDeletion = true" type="button">Remove</jet-secondary-button>
            </div>

            <jet-confirmation-modal :show="confirmIntegrationDeletion" @close="confirmIntegrationDeletion = false">
                <template #title> Delete integration</template>

                <template #content>
                    Are you sure you want to remove <strong>{{ integration.title }}</strong> integration?</template
                >

                <template #footer>
                    <jet-danger-button
                        class="mr-2"
                        @click="removeIntegration"
                        :class="{ 'opacity-25': removeIntegrationForm.processing }"
                        :disabled="removeIntegrationForm.processing"
                    >
                        Yes, remove it
                    </jet-danger-button>
                    <jet-secondary-button @click="confirmIntegrationDeletion = false"> No, back out </jet-secondary-button>
                </template>
            </jet-confirmation-modal>
        </div>
    </div>
</template>
<script>
import ShowFormLayout from '@/Layouts/ShowFormLayout.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetTextarea from '@/Jetstream/Textarea.vue'
import JetButton from '@/Jetstream/Button.vue'
import JetDangerButton from '@/Jetstream/DangerButton.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue'
import JetInputError from '@/Jetstream/InputError.vue'
import AppLayout from '@/Layouts/AppLayout.vue'
import Icon from '@/Components/Icon.vue'
import { useForm } from '@inertiajs/vue3'

export default {
    components: {
        Icon,
        ShowFormLayout,
        JetLabel,
        JetTextarea,
        JetInputError,
        JetDangerButton,
        JetSecondaryButton,
        JetConfirmationModal,
        JetButton,
    },
    props: ['form', 'integration'],
    layout: [AppLayout, ShowFormLayout],
    data() {
        return {
            formData: useForm({
                _method: 'POST',
                app_key: this.integration.app_key,
            }),
            confirmIntegrationDeletion: false,
            removeIntegrationForm: useForm({}),
            update: false,
        }
    },
    methods: {
        removeIntegration() {
            this.removeIntegrationForm.delete(
                route('form.integrations.destroy', {
                    form: this.$page.props.form._id,
                    integration: this.integration.name,
                }),
                {
                    errorBag: 'deleteIntegration',
                    preserveState: false,
                }
            )
        },
    },
}
</script>
