<template>
    <div>
        <div class="flex-1 text-sm">
            <div class="flex items-center pb-2 mb-4">
                <jet-label class="mb-0 text-lg text-body">Validation rules</jet-label>
                <info class="ml-1">
                    <p class="text-sm text-body">
                        Specify as many as you need. We encourage you to use them because forms without validation <em>are bad</em>. Not in the cool way.
                    </p>
                </info>
            </div>
            <p v-if="formField.rules.length === 0">Looks like you haven't added any field rules.</p>
        </div>
        <div class="max-w-[450px]">
            <field-settings-modal :show="spotlight" @close="closeSpotlight" max-width="xl">
                <template #content>
                    <field-settings-popup
                        @done="onDone"
                        :current-field="formField"
                        :all-field-rules="fieldRules"
                        :error-bag="errorBag"
                        :change-current-field="changeCurrentField"
                    />
                </template>
            </field-settings-modal>

            <div v-if="formField.rules.length" class="mt-5 space-y-4">
                <Disclosure as="div" v-slot="{ open }" v-for="(rule, index) in formField.rules" :key="index" class="!mt-0 group">
                    <jet-label
                        :for="'rule_' + rule.name"
                        class="border bg-white border-gray-200 py-2 px-2 mb-0 !-mt-[1px]"
                        :class="{
                            'rounded-t-md': index === 0,
                            'rounded-md': formField.rules.length === 1,
                            'rounded-b-none': open,
                        }"
                    >
                        <div class="flex items-center justify-between">
                            <div class="flex items-center">
                                <DisclosureButton class="flex items-center">
                                    <ChevronUpIcon class="w-4 h-4" :class="open ? 'rotate-180 transform' : ''" />
                                    <span class="ml-1 text-sm font-semibold text-body">{{ rule.label }}</span>
                                </DisclosureButton>
                                <info v-if="rule?.description" class="ml-1">
                                    <div v-html="rule.description" />
                                </info>
                            </div>
                            <button @click="remove(index)" class="p-1 opacity-0 group-hover:opacity-100">
                                <icon name="trash" class="w-4 h-4 text-body" />
                            </button>
                        </div>
                    </jet-label>
                    <DisclosurePanel
                        class="!mt-0 bg-gray-50 !p-3"
                        :class="{
                            'border-l border-r border-b border-gray-200': open,
                            'rounded-b-md': formField.rules.length === 1 || formField.rules.length === index + 1,
                        }"
                    >
                        <div v-if="rule.hasOwnProperty('error') && formField.rules.filter((r) => r.name === rule.name).length > 0">
                            <div class="flex items-center mb-2">
                                <jet-label :for="'rule_error_' + rule.name" value="Field error" class="mb-0 text-xs" />
                                <info class="ml-1">
                                    <p class="mb-2 text-sm text-gray-600">
                                        This is for the user to see. It's shown in your form or returned by the API only if the validation rule criteria is not
                                        met.
                                    </p>
                                </info>
                            </div>
                            <jet-input :id="'rule_error_' + rule.name" type="text" class="w-full" v-if="formField" v-model="rule.error" />
                        </div>
                        <div
                            class="mt-2"
                            v-if="rule.hasOwnProperty('value') && rule.name !== 'mimetypes' && formField.rules.filter((r) => r.name === rule.name).length > 0"
                        >
                            <div class="flex items-center mb-2">
                                <jet-label :for="'rule_value_' + rule.name" value="Rule value" class="mb-0 text-xs text-body" />
                                <info class="ml-1">
                                    <p class="mb-2 text-sm text-gray-600">This value will be used to determine if the field in question is valid or not.</p>
                                </info>
                            </div>

                            <jet-input :id="'rule_value_' + rule.name" type="text" class="w-full" v-model="rule.value" />
                        </div>
                        <div v-if="rule?.examples?.length" class="mt-2">
                            <h3 class="mb-2 font-bold leading-4 tracking-tight tracking-wide uppercase text-tiny">Examples</h3>
                            <div class="flex flex-wrap items-start gap-2">
                                <div
                                    class="flex justify-self-start box cursor-pointer !py-0"
                                    :key="k"
                                    v-for="(item, k) in rule.examples"
                                    @click="rule.value = item.code"
                                >
                                    <div class="py-1 pr-1 text-xs font-semibold border-r border-gray-200">
                                        <span class="text-body">{{ rule.name }}</span>
                                    </div>
                                    <div class="py-1 pl-1 text-xs">{{ item.code }}</div>
                                </div>
                            </div>
                        </div>
                        <!-- Min val - Max val -->
                        <div
                            class="p-3 mt-2 rounded"
                            v-if="
                                rule.hasOwnProperty('min_value') &&
                                rule.hasOwnProperty('max_value') &&
                                formField.rules.filter((r) => r.name === rule.name).length > 0
                            "
                        >
                            <jet-label :for="'rule_between_' + rule.name" value="Rule value" class="text-xs text-body" />
                            <p class="mb-2 text-sm text-gray-600">This value will be used to determine if the field in question is valid or not.</p>

                            <div class="grid grid-cols-2 gap-4">
                                <div>
                                    <jet-label class="text-sm font-bold text-md" value="Minimum" />
                                    <jet-input type="text" class="block w-full col-span-1 p-1 px-2 text-xs" v-model="rule.min_value" />
                                </div>
                                <div>
                                    <jet-label class="text-sm font-bold text-md" value="Maximum" />
                                    <jet-input type="text" class="block w-full col-span-1 p-1 px-2 text-xs" v-model="rule.max_value" />
                                </div>
                            </div>
                        </div>

                        <!-- File type -->
                        <div class="mt-2" v-if="rule.name === 'mimetypes' && formField.rules.filter((r) => r.name === rule.name).length > 0">
                            <jet-label :for="'rule_value_' + rule.name" value="Allowed file types" class="text-xs text-body" />
                            <Multiselect v-model="rule.value" label="extension" mode="tags" :searchable="true" trackBy="extension" :options="mimetypes" />
                        </div>
                    </DisclosurePanel>
                </Disclosure>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from '@vueform/multiselect'
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetCheckbox from '@/Jetstream/Checkbox.vue'
import Icon from '@/Components/Icon.vue'
import JetDangerButton from '@/Jetstream/DangerButton.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import mimetypes from '@/mimetypes'
import AddValidationRuleModal from '@/Components/AddValidationRuleModal.vue'
import Spotlight from '@/Components/Spotlight.vue'
import FieldSettingsModal from './FieldSettingsModal.vue'
import FieldSettingsPopup from './FieldSettingsPopup.vue'
import fieldRules from '@/field-rules.json'
import JetFieldInfo from '@/Pixelform/FieldInfo.vue'
import Info from '@/Components/Info.vue'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronUpIcon } from '@heroicons/vue/solid'
import axios from 'axios'

export default {
    props: {
        formField: {
            type: Object,
            required: true,
        },
        spotlight: {
            type: Boolean,
            default: false,
        },
        closeSpotlight: {
            type: Function,
            required: true,
        },
        handleSelection: {
            type: Function,
            required: true,
        },
        mode: {
            type: String,
            default: '',
            required: false,
        },
    },
    components: {
        DisclosureButton,
        DisclosurePanel,
        Disclosure,
        Info,
        Multiselect,
        JetInput,
        JetCheckbox,
        JetLabel,
        Icon,
        JetDangerButton,
        JetSecondaryButton,
        AddValidationRuleModal,
        Spotlight,
        JetFieldInfo,
        ChevronUpIcon,
        FieldSettingsModal,
        FieldSettingsPopup,
    },
    data() {
        return {
            mimetypes,
            showingAddRuleModal: false,
            fieldRules: [],
            tab: 'rules',
            errorBag: [],
        }
    },
    mounted() {
        this.fieldRules = fieldRules

        axios.get(route('validation-messages')).then((response) => {
            this.errorBag = response.data
        })
    },
    methods: {
        onDone(e) {},
        remove(index) {
            this.$nextTick(() => {
                this.selectedIndex = index
                this.formField.rules.splice(index, 1)
            })
        },
        onTabChange(tab) {
            this.tab = tab
        },

        changeCurrentField(field) {
            this.$nextTick(() => {
                this.formField = obj
                this.$forceUpdate()
            })
        },
    },
}
</script>
