<template>
    <button
        :type="type"
        class="inline-flex items-center btn btn-md"
    >
        <slot></slot>
    </button>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'button',
        },
    },
}
</script>
