<template>
    <div class="px-4 mt-4 md:mt-0 md:col-span-2 sm:px-6">
        <payment-frequency v-model="displayingPlanInterval" />

        <div class="grid gap-6 md:grid-cols-3">
            <payment-plan v-for="plan in displayablePlans" :on-checkout="onCheckout" :plan="plan" :key="plan.id" />
        </div>
    </div>
</template>
<script setup>
import PaymentPlan from '@/Components/billing/PaymentPlan.vue'
import PaymentFrequency from '@/Components/billing/PaymentFrequency.vue'
import { router } from '@inertiajs/vue3'
import { ref, computed } from 'vue'

const props = defineProps({
    plans: {
        type: Object,
        required: true,
    },
})

const displayingPlanInterval = ref('monthly')

const displayablePlans = computed(() => {
    if (props.plans?.length === 0) return []

    return props.plans[displayingPlanInterval.value]
})

const onCheckout = (plan) => {
    router.visit(route('billing.checkout', { plan: plan.id }))
}
</script>
