<template>
    <div>
        <div class="p-3 lg:p-6 max-w-xl">
            <h3 class="page-title">Hero</h3>
            <p class="page-desc">
                Make your form more professional by adding a hero section. You
                can add a background image, a solid colour, or even a split
                layout.
            </p>
        </div>
        <div class="max-w-4xl px-6 pb-[200px]">
            <div class="flex flex-col space-y-6 mt-2">
                <hero-type />
                <hero-bg-color v-if="form.hero_type !== 'NONE'" />

                <!-- Needs more cowbell? -->
                <div
                    v-show="
                        form.hero_type !== 'NONE' && form.hero_bg_solid_color
                    "
                >
                    <Disclosure v-slot="{ open }">
                        <DisclosureButton class="flex items-center space-x-1">
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="rotate-90 w-4 h-4 text-slate-800 transition duration-300"
                            />
                            <span class="text-xs font-semibold text-gray-600"
                                >Needs more cowbell?</span
                            >
                        </DisclosureButton>
                        <smooth>
                            <DisclosurePanel as="div" class="pl-8 py-4">
                                <form
                                    @submit.prevent="addCowbellHandler"
                                    method="post"
                                >
                                    <div class="flex space-x-2">
                                        <div
                                            class="w-[110px] flex items-end space-x-1"
                                        >
                                            <div>
                                                <jet-label
                                                    for="cowbell"
                                                    value="Cowbell"
                                                />
                                                <jet-select
                                                    id="cowbell"
                                                    v-model="
                                                        formData.hero_overlay
                                                    "
                                                >
                                                    <template #options>
                                                        <option
                                                            selected
                                                            value=""
                                                        >
                                                            nope
                                                        </option>
                                                        <option value="radial">
                                                            radial
                                                        </option>
                                                        <option value="boxes">
                                                            boxes
                                                        </option>
                                                        <option value="crosses">
                                                            crosses
                                                        </option>
                                                        <option value="dots">
                                                            dots
                                                        </option>
                                                        <option
                                                            value="circle-wave"
                                                        >
                                                            circle wave
                                                        </option>
                                                    </template>
                                                </jet-select>
                                            </div>
                                            <button
                                                type="submit"
                                                class="btn mt-4 h-[28px]"
                                            >
                                                👍
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </DisclosurePanel>
                        </smooth>
                    </Disclosure>
                </div>

                <!-- Hero background image -->
                <smooth v-if="form.hero_type !== 'NONE'">
                    <div>
                        <button
                            type="button"
                            @click="
                                !form.hero_bg_solid_color
                                    ? (bgSettingsOpen = !bgSettingsOpen)
                                    : false
                            "
                            class="flex items-center space-x-1"
                            :class="{
                                'opacity-25 cursor-not-allowed':
                                    form.hero_bg_solid_color,
                            }"
                        >
                            <ChevronRightIcon
                                class="w-4 h-4 text-slate-800 transition duration-300"
                                :class="{
                                    'rotate-90 transform': bgSettingsOpen,
                                }"
                            />
                            <jet-label
                                :value="
                                    'Background photo ' +
                                    (form.hero_bg_solid_color
                                        ? ' (remove background colour to enable)'
                                        : '')
                                "
                                class="mb-0"
                                :class="{
                                    'cursor-not-allowed':
                                        form.hero_bg_solid_color,
                                }"
                            />
                        </button>
                        <div class="pl-8 pt-5" v-if="bgSettingsOpen">
                            <div>
                                <div class="flex items-center space-x-1">
                                    <jet-label
                                        for="photo"
                                        value="1.0 Photo"
                                        class="mb-0"
                                    />
                                    <info>
                                        <p class="text-xs text-base leading-5">
                                            Upload
                                            <b>small and optimised</b> photos,
                                            your page will load faster. That
                                            results in a better experience for
                                            your end users.
                                        </p>
                                    </info>
                                </div>
                                <input
                                    accept="image/png, image/jpeg"
                                    max="1"
                                    type="file"
                                    class="hidden"
                                    @change="updatePhotoPreview"
                                    ref="photoInputRef"
                                    id="file"
                                />

                                <!-- Photo Preview -->
                                <div
                                    class="mt-2 relative w-[300px]"
                                    v-show="photoPreview"
                                >
                                    <div
                                        class="absolute w-full h-[300px] rounded"
                                        v-if="
                                            photoPreview &&
                                            formData.hero_overlay
                                        "
                                    />
                                    <div
                                        class="block aspect-square h-[300px] rounded"
                                        :style="{
                                            backgroundImage: `url(${photoPreview})`,
                                            backgroundSize: 'contain',
                                            backgroundRepeat: 'no-repeat',
                                        }"
                                    ></div>
                                    <button
                                        type="button"
                                        class="mt-2 justify-center flex items-center space-x-2 btn !py-2 w-full"
                                        @click.prevent="selectNewPhoto"
                                    >
                                        <icon
                                            name="arrow-path"
                                            class="w-4 h-4"
                                        />
                                        <span>Change photo</span>
                                    </button>
                                    <button
                                        type="button"
                                        class="mt-2 justify-center flex items-center space-x-2 btn !py-2 w-full disabled:opacity-25 disabled:cursor-not-allowed"
                                        @click.prevent="
                                            removeImage($event, 'hero_image')
                                        "
                                    >
                                        <icon name="trash" class="w-4 h-4" />
                                        <span>Remove photo</span>
                                    </button>
                                    <button
                                        type="button"
                                        class="mt-2 justify-center flex items-center space-x-2 btn !py-2 w-full disabled:opacity-25 disabled:cursor-not-allowed"
                                        @click.prevent="
                                            uploadImage($event, 'hero_image')
                                        "
                                    >
                                        <icon name="cloud-up" class="w-4 h-4" />
                                        <span>Upload photo</span>
                                    </button>
                                </div>

                                <div
                                    v-if="!photoPreview"
                                    @click.prevent="selectNewPhoto"
                                    class="cursor-pointer flex-col hover:bg-slate-50 transition mt-2 w-[500px] min-h-[150px] flex justify-center items-center border rounded"
                                >
                                    <icon name="picture" class="w-8 h-8" />
                                </div>

                                <jet-input-error
                                    class="mt-1"
                                    :message="heroImageError"
                                />
                            </div>
                            <!-- 1.1 Settings -->
                            <div
                                class="mt-6"
                                v-show="form.hero_type !== 'NONE'"
                            >
                                <Disclosure v-slot="{ open }">
                                    <DisclosureButton
                                        class="flex items-center space-x-1"
                                    >
                                        <ChevronUpIcon
                                            :class="
                                                open
                                                    ? 'rotate-180 transform'
                                                    : ''
                                            "
                                            class="rotate-90 w-4 h-4 text-slate-800 transition duration-300"
                                        />
                                        <span
                                            class="text-xs font-semibold text-gray-600"
                                            >1.1 Settings</span
                                        >
                                    </DisclosureButton>
                                    <smooth>
                                        <DisclosurePanel
                                            as="div"
                                            class="pl-8 py-4"
                                        >
                                            <!-- Background properties -->
                                            <form
                                                @submit.prevent="saveBgSettings"
                                                method="post"
                                            >
                                                <div class="flex space-x-2">
                                                    <div class="w-[110px]">
                                                        <jet-label
                                                            for="bgSize"
                                                            value="Size"
                                                        />
                                                        <jet-select
                                                            id="bgSize"
                                                            v-model="
                                                                formData.hero_size
                                                            "
                                                        >
                                                            <template #options>
                                                                <option
                                                                    value="contain"
                                                                >
                                                                    Contain
                                                                </option>
                                                                <option
                                                                    value="cover"
                                                                >
                                                                    Cover
                                                                </option>
                                                            </template>
                                                        </jet-select>
                                                    </div>
                                                    <div class="w-[80px]">
                                                        <jet-label
                                                            for="bgRepeat"
                                                            value="Repeat"
                                                        />
                                                        <jet-select
                                                            id="bgRepeat"
                                                            v-model="
                                                                formData.hero_repeat
                                                            "
                                                        >
                                                            <template #options>
                                                                <option
                                                                    value="repeat"
                                                                >
                                                                    Yes
                                                                </option>
                                                                <option
                                                                    value="no-repeat"
                                                                >
                                                                    No
                                                                </option>
                                                            </template>
                                                        </jet-select>
                                                    </div>
                                                    <div class="w-[101px]">
                                                        <jet-label
                                                            for="bgPosition"
                                                            value="Position"
                                                        />
                                                        <jet-select
                                                            v-model="
                                                                formData.hero_position
                                                            "
                                                        >
                                                            <template #options>
                                                                <option
                                                                    value="top"
                                                                >
                                                                    Top
                                                                </option>
                                                                <option
                                                                    value="left"
                                                                >
                                                                    Left
                                                                </option>
                                                                <option
                                                                    value="right"
                                                                >
                                                                    Right
                                                                </option>
                                                                <option
                                                                    value="bottom"
                                                                >
                                                                    Bottom
                                                                </option>
                                                                <option
                                                                    value="center"
                                                                >
                                                                    Center
                                                                </option>
                                                            </template>
                                                        </jet-select>
                                                    </div>
                                                    <div>
                                                        <div
                                                            class="flex items-center mb-2 space-x-1"
                                                        >
                                                            <jet-label
                                                                for="heroWidth"
                                                                value="Width"
                                                                class="mb-0"
                                                            />
                                                            <info>
                                                                <p
                                                                    class="text-xs text-base"
                                                                >
                                                                    Use
                                                                    percentages
                                                                    <span
                                                                        class="text-blue-500"
                                                                        >(50%)</span
                                                                    >
                                                                    or pixels
                                                                    <span
                                                                        class="text-blue-500"
                                                                        >(500px)</span
                                                                    >
                                                                </p>
                                                            </info>
                                                        </div>
                                                        <jet-input
                                                            type="text"
                                                            class="w-[70px]"
                                                            id="heroWidth"
                                                            v-model="
                                                                formData.hero_width
                                                            "
                                                            @keyup=""
                                                        />
                                                        <jet-input-error
                                                            class="mt-1"
                                                            :message="
                                                                formData.errors
                                                                    .hero_width
                                                            "
                                                        />
                                                    </div>
                                                </div>

                                                <!-- Overlay, cowbell -->
                                                <!-- <div v-if="form.hero_bg || photoPreview" class="flex">
                                                    <div class="mt-2">
                                                        <jet-label for="color" value="Overlay" class="mb-0" />
                                                        <jet-input type="color" class="w-12 h-12 shadow-none bg-white" v-model="formData.hero_overlay" />
                                                    </div>

                                                    <div class="mt-4 ml-12">
                                                        <jet-label for="cowbell" value="Cowbell" />
                                                        <jet-input id="cowbell" class="shadow-none" type="range" min="0" max="99" v-model="formData.cowbell" />
                                                    </div>
                                                </div> -->
                                                <button class="btn mt-4">
                                                    Save
                                                </button>
                                            </form>
                                        </DisclosurePanel>
                                    </smooth>
                                </Disclosure>
                            </div>
                        </div>
                    </div>
                </smooth>

                <!-- Logo & Link -->
                <smooth
                    v-if="form.hero_type !== 'NONE' && heroStyle !== 'none'"
                >
                    <div class="space-y-6">
                        <!-- Branding -->
                        <Disclosure
                            :default-open="logoSettingsOpen"
                            v-slot="{ open }"
                        >
                            <DisclosureButton
                                class="flex items-center space-x-1"
                            >
                                <ChevronUpIcon
                                    :class="open ? 'rotate-180 transform' : ''"
                                    class="rotate-90 w-4 h-4 text-slate-800 transition duration-300"
                                />
                                <span
                                    class="text-xs font-semibold text-gray-600"
                                    >Branding</span
                                >
                            </DisclosureButton>
                            <smooth>
                                <DisclosurePanel
                                    as="div"
                                    class="pl-8 py-4 !mt-0"
                                >
                                    <div>
                                        <div
                                            class="flex items-center space-x-1"
                                        >
                                            <jet-label
                                                for="logo"
                                                value="Logo"
                                                class="mb-0"
                                            />
                                            <info>
                                                <p
                                                    class="text-xs text-left text-base"
                                                >
                                                    Logo should be up to 512 KB.
                                                    It's recommended to use a
                                                    PNG, JPG or JPEG. The image
                                                    can be between 128x128px to
                                                    512x512px in size.
                                                </p>
                                            </info>
                                        </div>
                                        <input
                                            accept="image/png, image/jpeg, image/jpg"
                                            max="1"
                                            type="file"
                                            class="hidden"
                                            @change="updateLogoPreview"
                                            ref="logoInputRef"
                                            id="logo"
                                        />

                                        <!-- New Profile Photo Preview -->
                                        <div
                                            class="mt-2 relative w-[150px]"
                                            v-show="logoPreview"
                                        >
                                            <div
                                                class="absolute w-full h-[75px] rounded object-contain"
                                                v-if="logoPreview"
                                            />
                                            <div
                                                class="block h-[75px] rounded"
                                                :style="{
                                                    backgroundImage: `url(${logoPreview})`,
                                                    backgroundSize: 'contain',
                                                    backgroundRepeat:
                                                        'no-repeat',
                                                }"
                                            ></div>
                                            <button
                                                type="button"
                                                class="mt-2 justify-center flex items-center space-x-2 btn !py-2 w-full"
                                                @click.prevent="selectNewLogo"
                                            >
                                                <icon
                                                    name="arrow-path"
                                                    class="w-4 h-4"
                                                />
                                                <span>Change logo</span>
                                            </button>
                                            <button
                                                type="button"
                                                class="mt-2 justify-center flex items-center space-x-2 btn !py-2 w-full disabled:opacity-25 disabled:cursor-not-allowed"
                                                ref="removeLogoBtn"
                                                @click.prevent.stop="
                                                    removeImage(
                                                        $event,
                                                        'hero_logo'
                                                    )
                                                "
                                            >
                                                <icon
                                                    name="trash"
                                                    class="w-4 h-4"
                                                />
                                                <span>Remove logo</span>
                                            </button>
                                            <button
                                                type="button"
                                                class="mt-2 justify-center flex items-center space-x-2 btn !py-2 w-full disabled:opacity-25 disabled:cursor-not-allowed"
                                                ref="uploadLogoBtn"
                                                @click.prevent="
                                                    uploadImage(
                                                        $event,
                                                        'hero_logo'
                                                    )
                                                "
                                            >
                                                <icon
                                                    name="cloud-up"
                                                    class="w-4 h-4"
                                                />
                                                <span>Upload logo</span>
                                            </button>
                                        </div>

                                        <div
                                            v-if="!logoPreview"
                                            @click.prevent="selectNewLogo"
                                            class="cursor-pointer flex-col hover:bg-slate-50 transition mt-2 w-[300px] min-h-[150px] flex justify-center items-center border rounded"
                                        >
                                            <icon
                                                name="picture"
                                                class="w-8 h-8"
                                            />
                                        </div>

                                        <jet-input-error
                                            class="mt-1"
                                            :message="logoImageError"
                                        />
                                    </div>
                                </DisclosurePanel>
                            </smooth>
                        </Disclosure>

                        <!-- Link -->
                        <!-- <Disclosure :default-open="formData.hero_link_label" v-slot="{ open }">
                            <DisclosureButton class="flex items-center space-x-1">
                                <ChevronUpIcon :class="open ? 'rotate-180 transform' : ''" class="rotate-90 w-4 h-4 text-slate-800 transition duration-300" />
                                <div class="flex items-center space-x-1">
                                    <span class="text-xs font-semibold text-gray-600">Link</span>
                                    <info>
                                        <p class="text-xs text-left">
                                            You can add a link for users to return to your website. This is optional, but it can be useful for branding.
                                        </p>
                                    </info>
                                </div>
                            </DisclosureButton>
                            <smooth>
                                <DisclosurePanel as="div" class="pl-8 py-4 !mt-0">
                                    <div class="space-y-4">
                                        <div class="grid grid-cols-2 gap-4 max-w-md">
                                            <div>
                                                <jet-label for="linkLabel" value="Label" />
                                                <jet-input
                                                    type="text"
                                                    placeholder="return to website"
                                                    autocomplete="false"
                                                    class="w-full"
                                                    id="linkLabel"
                                                    v-model="formData.hero_link_label"
                                                />
                                                <jet-input-error class="mt-1" :message="formData.errors.hero_link_label" />
                                            </div>
                                            <div>
                                                <jet-label for="link" value="Link" />
                                                <jet-input
                                                    type="text"
                                                    placeholder="https://my-website.com"
                                                    class="w-full"
                                                    autocomplete="false"
                                                    id="link"
                                                    v-model="formData.hero_link_url"
                                                />
                                                <jet-input-error class="mt-1" :message="formData.errors.hero_link_url" />
                                            </div>
                                        </div>

                                        <jet-input-error class="mt-1" :message="formData.errors.hero_link" />
                                    </div>
                                </DisclosurePanel>
                            </smooth>
                        </Disclosure> -->

                        <form-hero-content-editor ref="contentEditor">
                            <jet-input-error
                                class="mt-1"
                                :message="contentError"
                            />

                            <button
                                type="button"
                                class="btn !py-2 !px-4 mt-2 disabled:opacity disabled:cursor-not-allowed"
                                @click="saveHeroContent($event)"
                            >
                                Save
                            </button>
                        </form-hero-content-editor>
                    </div>
                </smooth>
            </div>
        </div>
    </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import ShowFormLayout from '@/Layouts/ShowFormLayout.vue'
export default {
    layout: [AppLayout, ShowFormLayout],
}
</script>
<script setup>
import JetLabel from '@/Jetstream/Label.vue'
import JetInputError from '@/Jetstream/InputError.vue'
import JetInput from '@/Jetstream/Input.vue'
import JetSelect from '@/Jetstream/Select.vue'
import Smooth from '@/Components/Smooth.vue'
import HeroType from '@/Components/HeroType.vue'
import HeroBgColor from '@/Components/HeroBgColor.vue'
import Info from '@/Components/Info.vue'
import Icon from '@/Components/Icon.vue'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronUpIcon, ChevronRightIcon } from '@heroicons/vue/solid'
import FormHeroContentEditor from '@/Components/FormHeroContentEditor.vue'
import { router, useForm } from '@inertiajs/vue3'
import { ref } from 'vue'

const props = defineProps({
    form: Object,
    hero_bg_url: String,
    hero_logo_url: String,
})

const bgSettingsOpen = ref(!!props.hero_bg_url)
const logoSettingsOpen = ref(!!props.hero_logo_url)
const formId = props.form._id

const formData = useForm({
    hero_size: props.form.hero_size,
    hero_repeat: props.form.hero_repeat,
    hero_position: props.form.hero_position || 'center',
    hero_width: props.form.hero_width || '50%',
    hero_overlay: props.form.hero_overlay,
})

const heroStyle = ref(
    props.form.hero_bg_solid_color
        ? 'Solid'
        : props.form.hero_bg
        ? 'Image'
        : props.form.hero_logo && !props.form.hero_bg
        ? 'Solid'
        : 'Solid'
)
const photoPreview = ref(JSON.parse(JSON.stringify(props.hero_bg_url)))
const photoInputRef = ref(null)
const logoPreview = ref(JSON.parse(JSON.stringify(props.hero_logo_url)))
const logoInputRef = ref(null)
const contentEditor = ref(null)

const removeLogoBtn = ref(null)
const uploadLogoBtn = ref(null)
const removeHeroImageBtn = ref(null)
const contentError = ref(null)

const saveBgSettings = async ($event) => {
    await router.post(
        route('form.settings.hero.update', { form: formId }),
        {
            action: 'hero_bg_settings',
            hero_size: formData.hero_size,
            hero_repeat: formData.hero_repeat,
            hero_position: formData.hero_position,
            hero_width: formData.hero_width,
        },
        {
            preserveState: true,
            onStart: () => {
                $event.target.disabled = true
            },
            onFinish: () => {
                $event.target.disabled = false
            },
        }
    )
}

const addCowbellHandler = async ($event) => {
    await router.post(
        route('form.settings.hero.update', { form: formId }),
        {
            action: 'cowbell',
            option: formData.hero_overlay === '' ? null : formData.hero_overlay,
        },
        {
            preserveState: true,
            onStart: () => {
                $event.target.disabled = true
            },
            onFinish: () => {
                $event.target.disabled = false
            },
        }
    )
}

const saveHeroContent = async ($event) => {
    const content = await contentEditor.value.save()
    console.log('saving the content', content)

    await router.post(
        route('form.settings.hero.update', { form: formId }),
        { action: 'hero_content', content },
        {
            preserveState: true,
            onStart: () => {
                $event.target.disabled = true
            },
            onError: (data) => {
                contentError.value = data?.content
            },
            onFinish: () => {
                $event.target.disabled = false
            },
        }
    )
}

const selectNewPhoto = () => {
    heroImageError.value = null
    photoInputRef.value.click()
}

const selectNewLogo = () => logoInputRef.value.click()
const heroImageError = ref(null)
const logoImageError = ref(null)

const uploadImage = async ($event, name) => {
    let image = null,
        input = null,
        error = name === 'hero_image' ? heroImageError : logoImageError

    name === 'hero_image' ? (input = photoInputRef) : (input = logoInputRef)

    if (input.value?.files?.length > 0) image = input.value.files[0]

    const formData = new FormData()
    formData.append('image', image)

    await router.post(
        route('form.settings.hero.update', { form: formId, action: name }),
        formData,
        {
            preserveState: true,
            onStart: () => {
                console.log('ev target', $event.target)
                $event.target.disabled = true
            },
            onError: (data) => {
                error.value = data?.image
            },
            onFinish: () => {
                $event.target.disabled = false
                error.value = null
            },
        }
    )
}

const removeImage = async ($event, action) => {
    await router.post(
        route('form.settings.hero.update', { form: formId, action }),
        {
            image: null,
        },
        {
            preserveState: true,
            onStart: () => {
                if (action === 'hero_logo') {
                    removeLogoBtn.value.disabled = true
                }
                if (action === 'hero_image') {
                    removeHeroImageBtn.value.disabled = true
                }
            },
            onSuccess: () => {
                if (action === 'hero_image') photoPreview.value = null
                if (action === 'hero_logo') logoPreview.value = null
            },
            onError: (data) => {
                heroImageError.value = data?.image
            },
            onFinish: () => {
                if (action === 'hero_logo') {
                    removeLogoBtn.value.disabled = true
                }
                if (action === 'hero_image') {
                    removeHeroImageBtn.value.disabled = true
                }
            },
        }
    )
}

const updatePhotoPreview = () => {
    const reader = new FileReader()

    reader.onload = (e) => (photoPreview.value = e.target.result)
    reader.readAsDataURL(photoInputRef.value.files[0])
}

const updateLogoPreview = () => {
    logoImageError.value = null
    const reader = new FileReader()

    reader.onload = (e) => (logoPreview.value = e.target.result)
    reader.readAsDataURL(logoInputRef.value.files[0])
}
</script>

<style scoped></style>
