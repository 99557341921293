<template>
    <div v-if="visible">
        <transition name="slide-fade">
            <div
                class="absolute top-0 flex-1 sm:flex-auto sm:w-auto w-full sm:top-10 z-[9999] left-1/2 -translate-x-1/2 text-white flex items-center justify-center bg-black sm:rounded-full text-xs py-2 px-4"
            >
                <div v-if="$page.props.flash.success" class="flex items-center justify-center space-x-2">
                    <div class="p-1 bg-gray-700 rounded-full">
                        <icon name="check" class="h-4 w-4 text-green-400" />
                    </div>
                    <div class="text-xs">{{ $page.props.flash.success }}</div>
                </div>
                <div v-if="$page.props.flash.error" class="flex items-center justify-center space-x-2">
                    <div class="p-1 bg-gray-700 rounded-full">
                        <icon name="error" class="h-4 w-4 text-red-400" />
                    </div>
                    <div class="text-xs">{{ $page.props.flash.error }}</div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import Icon from '@/Components/Icon.vue'
export default {
    components: {
        Icon,
    },
    data() {
        return {
            visible: false,
            timeout: null,
        }
    },

    watch: {
        '$page.props.flash': {
            deep: true,
            handler: function () {
                this.visible = this.$page.props.flash.success || this.$page.props.flash.error
                if (this.timeout) {
                    clearTimeout(this.timeout)
                }
                this.timeout = setTimeout(() => (this.visible = false), 3500)
            },
        },
    },
}
</script>

<style scoped>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
    transition: all 0.3s ease;
}

.slide-fade-leave-active {
    transition: all 0.35s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(100px);
    opacity: 0;
}
</style>
