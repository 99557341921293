<template>
    <div v-if="isSupported">
        <button class="btn !rounded-full flex items-center" :tooltip="`${copied ? 'You got it!' : 'Copy'}`" @click="copy(source)">
            <icon name="clipboard" class="w-4 h-4 mr-2" />
            <span>Share</span>
        </button>
    </div>
</template>

<script setup>
import { useClipboard } from '@vueuse/core'
import Icon from '@/Components/Icon.vue'
import { computed } from 'vue'

const props = defineProps({
    data: {
        type: String,
        default: null,
    },
})

const source = computed(() => {
    const formUrl = window.location.href.replace('/inbox', '')
    return props.data ? props.data : formUrl
})

const { copy, copied, isSupported } = useClipboard({ source })
</script>
