<template>
    <div>
        <button
            type="submit"
            class="block w-full px-4 py-2 text-sm leading-5 text-left text-gray-700 transition hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
            v-if="as == 'button'"
        >
            <slot></slot>
        </button>

        <Link :href="href" class="block px-4 py-2 text-sm leading-5 text-gray-700 transition hover:bg-gray-100 focus:outline-none focus:bg-gray-100" v-else>
            <slot></slot>
        </Link>
    </div>
</template>

<script>
import { Link } from '@inertiajs/vue3'
export default {
    props: ['href', 'as'],
    components: {
        Link,
    },
}
</script>
