<template>
    <input
        :placeholder="placeholder"
        class="border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md py-1 text-sm text-body"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        ref="input"
    />
</template>

<script>
export default {
    props: ['modelValue', 'placeholder'],

    emits: ['update:modelValue'],

    methods: {
        focus() {
            this.$refs.input.focus()
        },
    },
}
</script>
