<template>
    <app-layout>
        <template #header>
            <div class="flex justify-between">
                <h2 class="text-lg font-extrabold leading-tight text-gray-800">Overview</h2>
                <button
                    @click="createFormModal = true"
                    class="flex items-center px-3 -my-3 -mr-8 transition border-l border-gray-200 font-regular hover:bg-gray-100"
                >
                    <!--                    <svg class="w-6 h-6 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>-->
                    <span class="text-sm font-bold">Create new form</span>
                </button>
            </div>
        </template>

        <div class="py-12">
            <div class="mx-auto max-w-7xl sm:px-6 lg:px-8"></div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import Welcome from '@/Jetstream/Welcome.vue'
import CreateFormModal from '@/Pages/Forms/CreateFormModal.vue'

export default {
    components: {
        CreateFormModal,
        AppLayout,
        Welcome,
    },
    data() {
        return {
            createFormModal: false,
        }
    },
}
</script>
