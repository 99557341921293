<template>
    <jet-action-section>
        <template #title> Delete Workspace </template>

        <template #description> Permanently delete this workspace. </template>

        <template #content>
            <div class="max-w-xl text-sm text-gray-600">
                Once a workspace is deleted, all of its resources and data will be permanently deleted. Before deleting this workspace, please download any data
                or information regarding this workspace that you wish to retain.
            </div>

            <div class="mt-5">
                <jet-danger-button @click="confirmTeamDeletion"> Delete Workspace </jet-danger-button>
            </div>

            <!-- Delete Workspace Confirmation Modal -->
            <jet-confirmation-modal :show="confirmingTeamDeletion" @close="confirmingTeamDeletion = false">
                <template #title> Delete Workspace </template>

                <template #content>
                    Are you sure you want to delete this workspace? Once a workspace is deleted, all of its resources and data will be permanently deleted.
                </template>

                <template #footer>
                    <jet-secondary-button @click="confirmingTeamDeletion = false"> Cancel </jet-secondary-button>

                    <jet-danger-button class="ml-2" @click="deleteTeam" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                        Delete Workspace
                    </jet-danger-button>
                </template>
            </jet-confirmation-modal>
        </template>
    </jet-action-section>
</template>

<script>
import JetActionSection from '@/Jetstream/ActionSection.vue'
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue'
import JetDangerButton from '@/Jetstream/DangerButton.vue'
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue'
import { useForm } from '@inertiajs/vue3'

export default {
    props: ['team'],

    components: {
        JetActionSection,
        JetConfirmationModal,
        JetDangerButton,
        JetSecondaryButton,
    },

    data() {
        return {
            confirmingTeamDeletion: false,
            deleting: false,

            form: useForm({}),
        }
    },

    methods: {
        confirmTeamDeletion() {
            this.confirmingTeamDeletion = true
        },

        deleteTeam() {
            this.form.delete(route('teams.destroy', this.team), {
                errorBag: 'deleteTeam',
            })
        },
    },
}
</script>
