<template>
    <div>
        <div class="max-w-lg p-3 lg:p-6">
            <h3 class="page-title">Data retention</h3>
            <p class="page-desc">
                Disabling data retention will assure you that the none of the form entries will be stored on our servers. If desired, we can directly send the
                form entries to your favourite endpoint via
                <Link :href="$route('form.settings.webhook', { form: form._id })" class="underline">Webhooks</Link>.
            </p>

            <form @submit.prevent="formData.post($route('form.settings.data-retention.update', { form: form._id }))">
                <div class="mt-4">
                    <jet-toggle class="mt-1" v-model:checked="formData.data_retention" label="Data retention" />
                    <jet-input-error :message="formData.errors.return_url" class="mt-2" />
                </div>

                <div class="mt-4">
                    <jet-button class="mr-3" :class="{ 'opacity-25': formData.processing }" :disabled="formData.processing">Save</jet-button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue'
import ShowFormLayout from '@/Layouts/ShowFormLayout.vue'

export default {
    layout: [AppLayout, ShowFormLayout],
}
</script>

<script setup>
import JetToggle from '@/Jetstream/Toggle.vue'
import JetInputError from '@/Jetstream/InputError.vue'
import JetButton from '@/Jetstream/Button.vue'
import { useForm, Link } from '@inertiajs/vue3'

const props = defineProps({
    form: Object,
})

const formData = useForm({
    _method: 'PUT',
    data_retention: props.form.data_retention,
})
</script>
