<template>
    <div class="mt-6 md:grid md:grid-cols-3 md:gap-6">
        <jet-section-title>
            <template #title>Payment methods</template>
            <template #description>Manage all your payment methods here.</template>
        </jet-section-title>
        <div class="py-5 md:mt-0 md:col-span-2 sm:p-6">
            <div class="flex flex-col">
                <div class="-my-2 sm:-mx-6">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6">
                        <div class="overflow-hidden border border-b border-gray-200 sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Brand</th>
                                        <th scope="col" class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Expires at</th>
                                        <th scope="col" class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Added</th>
                                        <th scope="col" class="px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                                            <Link
                                                as="button"
                                                :href="$route('payment-method.create')"
                                                v-if="paymentMethods && paymentMethods.length"
                                                class="flex items-center pr-2 transition bg-blue-600 rounded font-regular"
                                            >
                                                <div class="p-1 mr-1 bg-blue-700 rounded-l">
                                                    <icon name="plus" class="w-4 h-4 text-white" />
                                                </div>
                                                <span class="font-semibold text-white text-x">Add payment method</span>
                                            </Link>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="paymentMethods && paymentMethods.length" class="bg-white divide-y divide-gray-200">
                                    <tr v-for="pm in paymentMethods" :key="pm.id">
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="flex items-center text-sm text-gray-900">
                                                <span class="mr-1 text-2xl">&middot;&middot;&middot;</span>{{ pm.card.last4 }} &nbsp;
                                                <span class="text-gray-500">({{ pm.card.brand }})</span>
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="text-sm text-gray-600">{{ pm.card.exp_month }}/{{ pm.card.exp_year }}</div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="text-sm text-gray-600">
                                                {{ formatDistance(new Date(pm.created * 1000), new Date(), { addSuffix: true }) }}
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <span
                                                v-if="defaultPaymentMethod?.id == pm.id"
                                                class="inline-flex px-2 mr-2 text-xs font-semibold leading-5 text-gray-400 border border-gray-300 rounded-full"
                                            >
                                                default
                                            </span>
                                            <Link
                                                as="button"
                                                :href="$route('payment-method.update', { id: pm.id })"
                                                method="post"
                                                type="button"
                                                v-else
                                                class="button-secondary !p-1 !px-[8px]"
                                                >Make default</Link
                                            >
                                            <Link
                                                v-if="defaultPaymentMethod?.id !== pm.id"
                                                as="button"
                                                :href="$route('payment-method.destroy', { id: pm })"
                                                method="delete"
                                                type="button"
                                                class="button-warning !p-1 !px-[8px] ml-2"
                                                >Remove</Link
                                            >
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else class="bg-white divide-y divide-gray-200">
                                    <tr>
                                        <td colspan="3" class="p-3">
                                            <div class="p-3 text-xs text-center text-gray-400">There are no payment methods.</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import JetSectionTitle from '@/Jetstream/SectionTitle.vue'
import Icon from '../../../Components/Icon.vue'
import { formatDistance } from 'date-fns'
import { Link } from '@inertiajs/vue3'

export default {
    props: ['paymentMethods', 'defaultPaymentMethod'],
    components: {
        JetSectionTitle,
        Link,
        Icon,
    },
    data() {
        return {
            formatDistance,
        }
    },
}
</script>
