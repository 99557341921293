<template>
    <modal :show="show" :max-width="maxWidth" :closeable="closeable" @close="close">
        <div class="px-6 py-8">
            <div v-if="$slots.title" class="text-lg">
                <slot name="title"> </slot>
            </div>

            <div v-if="$slots.content" class="mt-4">
                <slot name="content"> </slot>
            </div>
        </div>

        <!-- render if the slot footer exists -->
        <div v-if="$slots.footer" class="px-6 pt-4 pb-4">
            <slot name="footer"> </slot>
        </div>
    </modal>
</template>

<script>
import Modal from './Modal.vue'

export default {
    emits: ['close'],

    components: {
        Modal,
    },

    props: {
        show: {
            default: false,
        },
        maxWidth: {
            default: '2xl',
        },
        closeable: {
            default: true,
        },
    },

    methods: {
        close() {
            this.$emit('close')
        },
    },
}
</script>
